<script setup>

import Loader from "../../Components/Loader.vue";
import {onBeforeMount, onBeforeUnmount, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import {useToast} from "vue-toast-notification";
import Modal from "../../Components/Modal.vue";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');
const sortingField = ref('created_at');
const sortingFieldsOrder = reactive({
    id: 'asc',
    name: 'asc',
    created_at: 'asc',
});
watch(url, (newValue) => {
    localStorage.setItem('ticketCategoriesListUrl', url.value);
    localStorage.setItem('ticketCategoriesSortingField', sortingField.value);
    localStorage.setItem('ticketCategoriesSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('ticketCategoriesSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('ticketCategoriesQuery', query.value);
});

onBeforeMount(() => {
    if (localStorage.getItem('ticketCategoriesListUrl')) {
        url.value = localStorage.getItem('ticketCategoriesListUrl');
        sortingField.value = localStorage.getItem('ticketCategoriesSortingField');
        sortingFieldsOrder[sortingField.value] = localStorage.getItem('ticketCategoriesSortingFieldsOrder');
        searchLoaded.value = localStorage.getItem('ticketCategoriesSearchLoaded')  === 'true';
        query.value = localStorage.getItem('ticketCategoriesQuery');
    }else{
        url.value = '/api/categories?category_type=App\\Models\\Ticket&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    }
    loadData(url.value);
})

//Get data
const loading = ref(true);
const categories = ref([]);
function loadData(urlToFetch) {
    categories.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if (searchLoaded.value) {
                return performSearch(urlToFetch);
            }
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const searchLoaded = ref(false);
const savedResponse = ref(null);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response, mustSave = true) {
    categories.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
    if (mustSave === true && searchLoaded.value === false) {
        savedResponse.value = response;
    }
}

//Ordering table
function changeOrdering(sortingValue) {
    sortingField.value = sortingValue;
    sortingFieldsOrder[sortingField.value] = sortingFieldsOrder[sortingField.value] === 'asc' ? 'desc' : 'asc';

    url.value = searchLoaded.value === true
        ? '/api/search/categories?category_type=ticket&query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        : '/api/categories?category_type=App\\Models\\Ticket&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    loadData(url.value);
}
function activeSorting(sortingValue){
    return sortingField.value === sortingValue ? 'text-secondary' : 'text-dark';
}

//Searching
const query = ref('');
const search = _.debounce(async () => {
    if (query.value.length > 2) {
        await performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('ticketCategoriesQuery', query.value);
}, 250);
const performSearch = async (urlPerformSearch = null) => {
    categories.value = [];
    loading.value = true;
    let searchUrl = '/api/search/categories?category_type=ticket&query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }
    axios.get(searchUrl)
        .then(response => {
            paginationAndDataMapping(response.data, false);
            url.value = searchUrl
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
};
const clearSearch = () => {
    if (savedResponse.value === null) {
        categories.value = [];
        url.value = '/api/categories?category_type=App\\Models\\Ticket&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        loadData(url.value);
    } else {
        url.value = '/api/categories?category_type=App\\Models\\Ticket&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value] + '&page=' + savedResponse.value.meta.current_page;
        paginationAndDataMapping(savedResponse.value);
    }
    searchLoaded.value = false;
};

const modaleDeleteShown = ref(false);
const idToDelete = ref(null);
const indexToDelete = ref(null);
function showModalDelete(id, index) {
    idToDelete.value = id;
    indexToDelete.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDelete'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function deleteCategory() {
    axios.delete('/api/categories/' + idToDelete.value)
        .then(response => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    if (modaleDeleteShown.value) {
        const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
        modaleDelete.hide()
    }
})

</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card" id="contactList">
                <div class="card-header">
                    <div class="row g-3">
                        <div class="col-md-4">
                            <div class="search-box search-box-list">
                                <input type="text" class="form-control search" placeholder="Rechercher..." v-model="query" @input="search" id="searchTicketCategories">
                                <i class="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        <div class="col-md-auto ms-auto">
                            <router-link :to="{ name:'tickets-categories.create' }" class="btn btn-secondary">
                                <i class="ri-add-fill me-1 align-bottom"></i> Ajouter
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive table-card mb-3">
                        <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                            <thead class="table-light">
                                <tr>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('id')" :class="activeSorting('id')" class="fw-bold pe-4">#ID</a>
                                    </th>
                                    <th class="col-8 sort">
                                        <a href="#" @click.prevent="changeOrdering('name')" :class="activeSorting('name')" class="pe-4">Nom</a>
                                    </th>
                                    <th class="col-1 text-center" scope="col">Articles</th>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('created_at')" :class="activeSorting('created_at')" class="pe-4">Date création</a>
                                    </th>
                                    <th class="col-1 text-center" scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody class="list form-check-all">
                            <tr v-for="(category, index) in categories" :key="index">
                                <td class="fw-medium text-dark">#{{ category.data.id }}</td>
                                <td>{{ category.data.name }}</td>
                                <td class="text-center">
                                    <span class="badge fs-11 rounded-pill bg-danger-subtle text-danger" v-if="category.data.ticket_count === 0">{{ category.data.ticket_count }}</span>
                                    <span class="badge fs-11 rounded-pill bg-secondary-subtle text-secondary" v-else>{{ category.data.ticket_count }}</span>
                                </td>
                                <td>{{ category.data.created_at_fr }}</td>
                                <td class="text-center">
                                    <!--UPDATE-->
                                    <router-link :to="{ name: 'tickets-categories.edit', params:{ id: category.data.id } }" class="text-info" title="Modifier l'utilisateur" v-if="category.can.update">
                                        <i class="ri-pencil-fill fs-17 px-1"></i>
                                    </router-link>
                                    <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                    <!--Delete-->
                                    <a href="#" class="text-danger" title="Supprimer la catégorie" @click.prevent="showModalDelete(category.data.id, index)" v-if="category.can.delete">
                                        <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                    </a>
                                    <i class="ri-delete-bin-3-fill fs-17 px-1 text-muted" v-else></i>
                                </td>
                            </tr>
                            <tr class="text-center font-italic text-muted" v-if="categories.length === 0 && !loading">
                                <td colspan="9" v-if="searchLoaded">
                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                    <p class="mt-2 h5">Aucun résultat</p>
                                    <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                </td>
                                <td colspan="9" v-else>Aucune catégorie...</td>
                            </tr>
                            <tr v-if="loading">
                                <td colspan="9">
                                    <loader></loader>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination
                        @change-page:clicked="loadData($event)"
                        :visible="!loading"
                        :links="links"
                        :meta="meta"
                    ></pagination>

                    <modal id="modalDelete" title="Supprimer la catégorie ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteCategory">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer une catégorie d'article.</p>
                        </template>
                    </modal>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
