    <script setup>

import Loader from "../../Components/Loader.vue";
import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import {useToast} from "vue-toast-notification";
import PaginationCentered from "../../Components/PaginationCentered.vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');

watch(url, (newValue) => {
    localStorage.setItem('socialNetworkMediaListUrl', url.value);
});

onBeforeMount(() => {
    if (localStorage.getItem('socialNetworkMediaListUrl')) {
        url.value = localStorage.getItem('socialNetworkMediaListUrl');
    }else{
        url.value = '/api/social-network-media?sortingField=created_at&sortingOrder=desc';
    }
    loadData(url.value);
})

//Get data
const loading = ref(true);
const socialNetworkMedia = ref([]);
function loadData(urlToFetch) {
    socialNetworkMedia.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response, mustSave = true) {
    socialNetworkMedia.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
}

const modaleDeleteShown = ref(false);
const idToDelete = ref(null);
const indexToDelete = ref(null);
function showModalDelete(id, index) {
    idToDelete.value = id;
    indexToDelete.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDelete'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function deleteMedia() {
    axios.delete('/api/social-network-media/' + idToDelete.value)
        .then(response => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    if (modaleDeleteShown.value) {
        const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
        modaleDelete.hide()
    }
})

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
</script>

<template>
    <div class="row">
        <div class="col-12 text-end mb-3" v-if="authUser.can.create_social_network_media">
            <router-link :to="{name: 'social-network-media.create'}" class="btn btn-secondary">
                <i class="ri-add-fill me-1 align-bottom"></i> Ajouter
            </router-link>
        </div>
        <!-- Main Content -->
        <div class="col-12">
            <!-- News -->
            <div class="row">
                <div class="col-xl-3 col-sm-12 pb-4" v-for="(socialNetworkMediaItem, index) in socialNetworkMedia" :key="index">
                    <div class="card h-100">
                        <router-link :to="{ name: 'social-network-media.show', params: { id: socialNetworkMediaItem.data.id } }">
                            <img class="card-img-top img-fluid" :src="'/format-image/'+socialNetworkMediaItem.data.thumbnail+'?w=800&h=470&fm=webp&fit=crop'" :alt="'Thumbnail actualité' + socialNetworkMediaItem.data.id" width="800" height="470">
                        </router-link>
                        <div class="card-body pb-0">
                            <router-link :to="{ name: 'social-network-media.show', params: { id: socialNetworkMediaItem.data.id } }">
                                <h4 class="card-title mb-0">{{ socialNetworkMediaItem.data.title }}</h4>
                            </router-link>
                        </div>
                        <div class="card-footer d-flex justify-content-between align-items-center">
                            <p class="card-text mb-0">
                                <small class="text-muted">{{ socialNetworkMediaItem.data.created_at_fr }}</small>
                            </p>
                            <div>
                                <!--UPDATE-->
                                <router-link :to="{ name: 'social-network-media.edit', params:{ id: socialNetworkMediaItem.data.id } }" class="text-info" title="Modifier l'actualité" v-if="socialNetworkMediaItem.can.update">
                                    <i class="ri-pencil-fill fs-17 px-1"></i>
                                </router-link>
                                <!--Delete-->
                                <a href="#" class="text-danger" title="Supprimer l'actualité"  data-bs-toggle="modal" data-bs-target="#modalDelete" @click.prevent="showModalDelete(socialNetworkMediaItem.data.id, index)" v-if="socialNetworkMediaItem.can.delete">
                                    <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pagination or Loader -->
            <div v-if="!loading">
                <pagination-centered
                    :visible="socialNetworkMedia.length > 0"
                    @change-page:clicked="loadData($event)"
                    :links="links"
                    :meta="meta"
                />
                <div class="text-center" v-if="socialNetworkMedia.length === 0">
                    <i class="mdi mdi-instagram fs-36 text-muted"></i>
                    <p class="text-muted">Aucun média</p>
                </div>
            </div>

            <div v-if="loading">
                <loader/>
            </div>

            <modal id="modalDelete" title="Supprimer le média ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteMedia">
                <template v-slot:icon>
                    <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                </template>
                <template v-slot:content>
                    <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer un média.</p>
                </template>
            </modal>
        </div>
    </div>
</template>


<style scoped>

</style>
