<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, inject, onBeforeMount, onBeforeUnmount, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import {useToast} from "vue-toast-notification";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const brandConfig = inject('brandConfig');

const url = ref('');
const sortingField = ref('created_at');
const sortingFieldsOrder = reactive({
    id: 'asc',
    name: 'asc',
    created_at: 'asc',
});
watch(url, (newValue) => {
    localStorage.setItem('centersListUrl', url.value);
    localStorage.setItem('centersSortingField', sortingField.value);
    localStorage.setItem('centersSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('centersSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('centersQuery', query.value);
    localStorage.setItem('centersShowArchived', showArchived.value.toString());
});

onBeforeMount(() => {
    if (localStorage.getItem('centersListUrl')) {
        url.value = localStorage.getItem('centersListUrl');
        sortingField.value = localStorage.getItem('centersSortingField');
        sortingFieldsOrder[sortingField.value] = localStorage.getItem('centersSortingFieldsOrder');
        searchLoaded.value = localStorage.getItem('centersSearchLoaded')  === 'true';
        showArchived.value = localStorage.getItem('centersShowArchived')  === 'true';
        query.value = localStorage.getItem('centersQuery');
    }else{
        url.value = '/api/centers?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    }
    loadData(url.value);
})

//Get data
const loading = ref(true);
const centers = ref([]);
function loadData(urlToFetch) {
    centers.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if (searchLoaded.value) {
                return performSearch(urlToFetch);
            }
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const searchLoaded = ref(false);
const savedResponse = ref(null);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response, mustSave = true) {
    centers.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
    if (mustSave === true && searchLoaded.value === false) {
        savedResponse.value = response;
    }
}

//Ordering table
function changeOrdering(sortingValue) {
    sortingField.value = sortingValue;
    sortingFieldsOrder[sortingField.value] = sortingFieldsOrder[sortingField.value] === 'asc' ? 'desc' : 'asc';

    url.value = searchLoaded.value === true
        ? '/api/search/centers?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        : '/api/centers?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    if (showArchived.value)
        url.value += '&archived=true';
    loadData(url.value);
}
function activeSorting(sortingValue){
    return sortingField.value === sortingValue ? 'text-secondary' : 'text-dark';
}

//Searching
const query = ref('');
const search = _.debounce(async () => {
    if (query.value.length > 2) {
        await performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('centersQuery', query.value);
}, 250);
const performSearch = async ( urlPerformSearch = null ) => {
    centers.value = [];
    loading.value = true;
    let searchUrl = showArchived.value
        ? '/api/search/centers?archived=true&query=' + query.value + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        : '/api/search/centers?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }
    axios.get(searchUrl)
        .then(response => {
            paginationAndDataMapping(response.data, false);
            url.value = searchUrl
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
};
const clearSearch = () => {
    if (savedResponse.value === null) {
        centers.value = [];
        url.value = showArchived.value
            ? '/api/centers?archived=true&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
            : '/api/centers?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        loadData(url.value);
    } else {
        url.value = '/api/centers?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value] + '&page=' + savedResponse.value.meta.current_page;
        paginationAndDataMapping(savedResponse.value);
    }
    searchLoaded.value = false;
};

const modaleDeleteShown = ref(false);
const idToArchive = ref(null);
const indexToArchive = ref(null);
function showModalArchive(id, index) {
    idToArchive.value = id;
    indexToArchive.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalArchive'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function archive() {
    axios.delete('/api/centers/' + idToArchive.value)
        .then(response => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalArchive'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    if (modaleDeleteShown.value) {
        const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalArchive'))
        modaleDelete.hide()
    }
})

const showArchived = ref(false);
function getArchived(){
    loading.value = true;
    showArchived.value = true;
    url.value = '/api/centers?archived=true&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    loadData(url.value);
}

function hideArchived(){
    showArchived.value = false;
    url.value = '/api/centers?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    loadData(url.value);
}

function restore(id){
    axios.post('/api/centers/' + id + '/restore')
        .then(response => {
            loadData(url.value);
            $toast.success('Centre restauré', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card" id="contactList">
                <div class="card-header">
                    <div class="row g-3">
                        <div class="col-md-4">
                            <div class="search-box search-box-list">
                                <input type="text" class="form-control search" placeholder="Rechercher..." v-model="query" @input="search" id="searchCenters">
                                <i class="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        <div class="col-md-auto ms-auto">
                            <button type="button" class="btn btn-dark waves-light me-2" @click="getArchived" v-if="!showArchived">
                                <i class="ri-archive-fill me-1 align-bottom"></i> Archives
                            </button>
                            <button type="button" class="btn btn-success waves-light me-2" @click="hideArchived" v-else>
                                <i class="ri-community-fill me-1 align-bottom"></i> Centres
                            </button>
                            <router-link :to="{ name:'centers.create' }" class="btn btn-secondary"  v-if="authUser.can && authUser.can.create_center">
                                <i class="ri-add-fill me-1 align-bottom"></i> Ajouter
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                <tr>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('id')" :class="activeSorting('id')" class="fw-bold pe-4">#ID</a>
                                    </th>
                                    <th class="col-5 sort">
                                        <a href="#" @click.prevent="changeOrdering('name')" :class="activeSorting('name')" class="pe-4">Nom</a>
                                    </th>
                                    <th class="col-2" scope="col">Téléphone</th>
                                    <th class="col-1 text-center" scope="col">Utilisateurs</th>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('created_at')" :class="activeSorting('created_at')" class="pe-4">Date création</a>
                                    </th>
                                    <th class="col-1 text-center" scope="col">Détails</th>
                                    <th class="col-1 text-center" scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(center, index) in centers" :key="index">
                                    <td class="fw-medium text-dark">#{{ center.data.id }}</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                                <img :src="brandConfig.logoBlackSmall" alt="" class="avatar-xs">
                                            </div>
                                            <div class="flex-grow-1 ms-2 name">{{ center.data.name }}</div>
                                        </div>
                                    </td>
                                    <td>{{ center.data.phone_number }}</td>
                                    <td class="text-center">
                                        <span class="badge rounded-pill bg-danger-subtle text-danger fs-11" v-if="center.data.users_count === 0">{{ center.data.users_count }}</span>
                                        <span class="badge rounded-pill bg-secondary-subtle text-secondary fs-11" v-else>{{ center.data.users_count }}</span>
                                    </td>
                                    <td>{{ center.data.created_at_fr }}</td>
                                    <td class="text-center">
                                        <router-link :to="{ name: 'centers.show', params:{ id: center.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</router-link>
                                    </td>
                                    <td class="text-center">
                                        <!--UPDATE-->
                                        <router-link :to="{ name: 'centers.edit', params:{ id: center.data.id } }" class="text-info" title="Modifier le centre" v-if="center.can.update">
                                            <i class="ri-pencil-fill fs-17 px-1"></i>
                                        </router-link>
                                        <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                        <!--ARCHIVE-->
                                        <a href="#" class="text-danger" title="Archiver le centre"  data-bs-toggle="modal" data-bs-target="#modalArchive" @click.prevent="showModalArchive(center.data.id, index)" v-if="!showArchived && center.can.delete">
                                            <i class="ri-inbox-archive-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-inbox-archive-fill fs-17 px-1 text-muted" v-if="!showArchived && !center.can.delete"></i>
                                        <!--RESTORE-->
                                        <a href="#" class="text-success" title="Restaurer le centre" @click.prevent="restore(center.data.id)" v-if="showArchived && center.can.restore">
                                            <i class="ri-inbox-unarchive-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-inbox-unarchive-fill fs-17 px-1 text-muted"  v-if="showArchived && !center.can.restore"></i>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="centers.length === 0 && !loading">
                                    <td colspan="7" v-if="searchLoaded">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                        <p class="mt-2 h5">Aucun résultat</p>
                                        <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                    </td>
                                    <td colspan="7" v-else>Aucun centre...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="7">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                        ></pagination>
                    </div>

                    <modal id="modalArchive" title="Archiver l'utilisateur ?" btn-class="btn-danger" btn-text="Archiver" @click:button="archive">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point d'archiver un centre.</p>
                        </template>
                    </modal>


                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
