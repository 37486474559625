<script setup>

//props
const props = defineProps({
    id: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    btnClass: {
        type: String,
        required: false,
        default: 'btn-secondary'
    },
    btnText: {
        type: String,
        required: false,
        default: 'Valider'
    }
})

// Emits
const emit = defineEmits(['click:button']);

// Méthode pour émettre l'événement
const onArchiveClick = () => {
    emit('click:button');
}
</script>

<template>
    <div class="modal fade zoomIn" :id="id" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" id="deleteRecord-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-5 text-center">
                    <div class="text-center">
                        <slot name="icon"></slot>

                        <h4 class="fs-semibold mt-4 text-dark">{{ title }}</h4>
                        <slot name="content"></slot>

                        <div class="hstack gap-2 justify-content-center remove">
                            <button class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" id="deleteRecord-close" data-bs-dismiss="modal">Annuler</button>
                            <button class="btn" :class="btnClass" @click="onArchiveClick">{{ btnText }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
