<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {computed, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import Loader from "../../Components/Loader.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import {useToast} from "vue-toast-notification";
import FormSelectCenter from "../../Components/FormSelectCenter.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import FormSelectClient from "../../Components/FormSelectClient.vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import FormNumericField from "../../Components/FormNumericField.vue";
import axios from "axios";
import FormDatePicker from "../../Components/FormDatePicker.vue";

const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const loading = ref(false)
const form = reactive( {
    client_id: null,
    center_id: null,
    quote_date: null,
    valid_until: null,
    comment: null,
    notes: null,
    tax_rate: 20,
    currency: 'EUR'
})
const centerPreSelected = ref([])
const errors = ref(null)

onBeforeMount(() => {
    if (authUser.value.centers.length >= 1) {
        centerPreSelected.value = {
            data: {
                id: authUser.value.centers[0].id,
                name: authUser.value.centers[0].name
            }
        }
    }
})
function createQuote() {

    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    axios.post("/api/quotes", formData)
        .then(response => {
            $toast.success('Devis créé avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.push({ name: 'quotes.show', params: { id: response.data.data.id } });
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}

onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

watch(() => form.quote_date, (newStartDate) => {
    if (!newStartDate) return;
    const startDate = parseDate(newStartDate);
    let endDate = form.valid_until ? parseDate(form.valid_until) : null;

    if (!endDate || startDate > endDate) {
        endDate = new Date(startDate.getTime());
        //add one month
        endDate.setMonth(startDate.getMonth() + 1);
        form.valid_until = formatDate(endDate);
    }
});


watch(() => form.valid_until, (newEndDate) => {
    if (!newEndDate || form.quote_date === null) return;
    const startDate = parseDate(form.quote_date);
    const endDate = parseDate(newEndDate);

    if (startDate > endDate) {
        form.valid_until = formatDate(startDate);
        $toast.warning('La date de fin ne peut pas être antérieure à la date de début.', {
            position: 'top',
            duration: 5000,
            dismissible: true,
            pauseOnHover: true,
        });
    }
});

function parseDate(dateStr) {
    const parts = dateStr.split(' ');
    const datePart = parts[0];
    const [year, month, day] = datePart.split('-');
    return new Date(year, month - 1, day);
}
function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
</script>

<template>
    <div class="row">
        <div class="col-xl-12">
            <!--Loader-->
            <div class="card" v-if="loading">
                <div class="card-body">
                    <loader/>
                </div>
            </div>
        </div>
    </div>
    <!--Form-->
    <form v-on:submit.prevent="createQuote" v-if="!loading">
        <div class="row">
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-file-sign me-2 align-middle fs-4"></i>Informations devis</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <form-select-client
                                :errors="errors"
                                label="Client"
                                name="client_id"
                                :required="true"
                                :data="form.client_id"
                                @update:field="form.client_id = ($event !== null ? $event.id : null)"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                placeholder="Sélectionnez un client"
                            />


                            <form-select-center
                                :errors="errors"
                                label="Centre"
                                name="center_id"
                                :required="true"
                                :data="form.center_id"
                                @update:field="form.center_id = ($event !== null ? $event.id : null)"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                placeholder="Sélectionnez un centre"
                                :center-pre-selected="centerPreSelected"
                            />

                            <form-date-picker
                                name="quote_date"
                                label="Date du devis"
                                :label-hidden="false"
                                @update:field="form.quote_date = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.quote_date"
                                bs-class="col-xl-6"
                                :disabled="false"
                            />

                            <form-date-picker
                                name="valid_until"
                                label="Date fin de validité"
                                :label-hidden="false"
                                @update:field="form.valid_until = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.valid_until"
                                bs-class="col-xl-6"
                                :disabled="false"
                            />

                            <form-textarea
                                name="notes"
                                label="Notes internes"
                                :label-hidden="false"
                                @update:field="form.notes = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.notes"
                                bs-class="col-xl-12"
                                :row="1"
                            />

                            <form-textarea
                                name="comment"
                                label="Commentaires"
                                :label-hidden="false"
                                @update:field="form.comment = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.comment"
                                bs-class="col-xl-12"
                                :row="2"
                                information-content="Commentaires visibles sur le devis."
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <!--Vehicle-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-currency-eur me-2 align-middle fs-4"></i>Informations financières</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-numeric-field
                                name="tax_rate"
                                label="Taux de TVA"
                                :label-hidden="false"
                                @update:field="form.tax_rate = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.tax_rate"
                                bs-class="col-md-6"
                                :min="0"
                                :step="0.01"
                                information-content="Taux de TVA en %. Mettre 0 si le client est exonéré de TVA."
                            />

                            <form-select-field
                                name="currency"
                                label="Devise"
                                :label-hidden="false"
                                @update:field="form.currency = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.currency"
                                bs-class="col-md-6"
                                :liste-options="[
                                    { text: 'EUR', value: 'EUR' },
                                ]"
                            />

                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<style scoped>

</style>
