<script setup>
import { inject } from 'vue';
import SideBarAdminAndBackOffice from "./SideBarAdminAndBackOffice.vue";
import SideBarManager from "./SideBarManagerAndStaff.vue";
import { useAuthUserStore } from "../Store/AuthUserStore.js";

const authUserStore = useAuthUserStore();
const brandConfig = inject('brandConfig');

const onClickOutside = () => {
    const windowSize = document.documentElement.clientWidth;
    if (windowSize <= 767 && document.body.classList.contains("vertical-sidebar-enable")) {
        console.log("Clicked outside and vertical sidebar is enabled");
        document.body.classList.remove("vertical-sidebar-enable");
    }
};


</script>

<template>
    <div class="app-menu navbar-menu" v-click-outside="onClickOutside">

        <div class="navbar-brand-box">
            <router-link :to="{name : 'dashboard'}" class="logo logo-light">
                <span class="logo-sm">
                    <img :src="brandConfig.logoSmall" alt="" :height="brandConfig.logoSmallHeight">
                </span>
                <span class="logo-lg">
                    <img :src="brandConfig.logo" alt="" :height="brandConfig.logoHeight">
                </span>
            </router-link>
            <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
                <i class="ri-record-circle-line"></i>
            </button>
        </div>

        <div id="scrollbar" class="h-100" data-simplebar>
            <div class="container-fluid">
                <side-bar-admin-and-back-office v-if="authUserStore.authUser.role === 'admin' || authUserStore.authUser.role === 'back_office'"/>
                <side-bar-manager v-else-if="authUserStore.authUser.role === 'manager' || authUserStore.authUser.role === 'staff'"/>
            </div>
        </div>
        <div class="sidebar-background"></div>
    </div>
</template>

<style scoped>
</style>
