import HelpArticleList from "../Views/HelpArticle/HelpArticleList.vue";
import HelpArticleShow from "../Views/HelpArticle/HelpArticleShow.vue";
import HelpArticleCreate from "../Views/HelpArticle/HelpArticleCreate.vue";
import HelpArticleEdit from "../Views/HelpArticle/HelpArticleEdit.vue";
import {useAuthUserStore} from "../Store/AuthUserStore.js";
import {computed} from "vue";

export const helpArticleRoutes = [
    {
        path: "/help-articles/edit/:id",
        name: "help-articles.edit",
        component: HelpArticleEdit,
        beforeEnter: (to, from, next) => {
            const authUserStore = useAuthUserStore();
            const authUser = computed(() => authUserStore.authUser);
            if(authUser.value.role !== 'admin' && authUser.value.role !== 'back_office' ) {
                next('/unauthorized');
            } else {
                next();
            }
        },
        meta: {
            menu: {
                parent: 'help-articles',
                child: 'help-articles'
            },
            breadcrumb: {
                title: "Centre d'aide",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Toutes les fiches d'aide",
                            link: {name: 'help-articles.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/help-articles/create",
        name: "help-articles.create",
        component: HelpArticleCreate,
        beforeEnter: (to, from, next) => {
            const authUserStore = useAuthUserStore();
            const authUser = computed(() => authUserStore.authUser);
            if(authUser.value.role !== 'admin' && authUser.value.role !== 'back_office' ) {
                next('/unauthorized');
            } else {
                next();
            }
        },
        meta: {
            menu: {
                parent: 'help-articles',
                child: 'help-articles'
            },
            breadcrumb: {
                title: "Centre d'aide",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Toutes les fiches d'aide",
                            link: {name: 'help-articles.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/help-articles/:id",
        name: "help-articles.show",
        component: HelpArticleShow,
        meta: {
            menu: {
                parent: 'help-articles',
                child: 'help-articles'
            },
            breadcrumb: {
                title: "Centre d'aide",
                nav: {
                    all: {
                        active: "Fiche d'aide",
                        inactive: [{
                            title: "Toutes les fiches d'aide",
                            link: {name: 'help-articles.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/help-articles",
        name: "help-articles.list",
        component: HelpArticleList,
        meta: {
            menu: {
                parent: 'help-articles',
                child: 'help-articles'
            },
            breadcrumb: {
                title: "Centre d'aide",
                nav: {
                    all: {
                        active: "Toutes les fiches d'aide",
                        inactive: []
                    }
                }
            }
        },
    }
];
