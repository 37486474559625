<template>
    <div class="d-flex" :class="getPaginationClasses()">

        <div class="text-muted hide-on-mobile">
            Affichage de <span class="fw-semibold">{{ meta.from }} à {{ meta.to }}</span> sur <span class="fw-semibold">{{ meta.total }}</span> résultats
        </div>

        <nav>
            <ul class="pagination pagination-separated mb-0" v-if="visible">
                <li class="page-item" :class="{ 'disabled': links.prev === null }">
                    <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(links.first)">
                        <i class="mdi mdi-chevron-double-left"></i>
                    </a>
                </li>
                <li class="page-item" :class="{ 'disabled': links.prev === null }">
                    <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(links.prev)">
                        <i class="mdi mdi-chevron-left"></i>
                    </a>
                </li>

                <li class="page-item" v-for="(link, index) in meta.links" :key="index" :class="{ 'active': link.active }">
                    <a href="#" class="page-link" @click.prevent="changePage(link.url)" v-html="link.label" v-if="isInMiddle(index)">
                    </a>
                </li>

                <li class="page-item" :class="{ 'disabled': links.next === null }">
                    <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(links.next)">
                        <i class="mdi mdi-chevron-right"></i>
                    </a>
                </li>
                <li class="page-item" :class="{ 'disabled': links.next === null }">
                    <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(links.last)">
                        <i class="mdi mdi-chevron-double-right"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>

</template>

<script>
export default {
    props: {
        visible: Boolean,
        links: Object,
        meta: Object
    },
    data() {
        return {
            pageClicked: null,
            isMobile: window.innerWidth <= 768
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        changePage(link) {
            this.pageClicked = link;
            if (link !== null) {
                this.$emit("change-page:clicked", this.pageClicked);
            }
        },
        isInMiddle(index){
            return !(index === 0 || index === (this.meta.links.length - 1));
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 768;
        },
        getPaginationClasses() {
            return this.isMobile ? 'justify-content-center' : 'justify-content-between align-items-center';
        }
    }
}
</script>
