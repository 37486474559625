<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import _ from 'lodash';
import {useToast} from "vue-toast-notification";
import PaginationCentered from "../../Components/PaginationCentered.vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');

watch(url, (newValue) => {
    localStorage.setItem('helpArticlesListUrl', url.value);
    localStorage.setItem('helpArticlesSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('helpArticlesQuery', query.value);
    localStorage.setItem('helpArticlesFilterCategoryId', filterCategoryId.value);
});

onBeforeMount(() => {
    if (localStorage.getItem('helpArticlesListUrl')) {
        url.value = localStorage.getItem('helpArticlesListUrl');
        searchLoaded.value = localStorage.getItem('helpArticlesSearchLoaded')  === 'true';
        query.value = localStorage.getItem('helpArticlesQuery');
        filterCategoryId.value = isNaN(localStorage.getItem('helpArticlesFilterCategoryId')) ? null : parseInt(localStorage.getItem('helpArticlesFilterCategoryId'));
    }else{
        url.value = '/api/help-articles?sortingField=created_at&sortingOrder=desc';
    }
    loadData(url.value);
})

//Get data
const loading = ref(true);
const helpArticles = ref([]);
function loadData(urlToFetch) {
    helpArticles.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if (searchLoaded.value) {
                return performSearch();
            }
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const searchLoaded = ref(false);
const savedResponse = ref(null);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response, mustSave = true) {
    helpArticles.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
    if (mustSave === true && searchLoaded.value === false) {
        savedResponse.value = response;
    }
}

//Searching
const query = ref('');
const search = _.debounce(async () => {
    if (query.value.length > 2) {
        await performSearch();
    } else {
        clearSearch();
    }
}, 250);
const performSearch = async () => {
    helpArticles.value = [];
    loading.value = true;
    let searchUrl = ''
    if (filterCategoryId.value === null){
        searchUrl  = '/api/search/help-articles?sortingField=created_at&sortingOrder=desc&query=' + query.value;
    }else {
        searchUrl = '/api/search/help-articles?sortingField=created_at&sortingOrder=desc&query=' + query.value + '&category_id=' + filterCategoryId.value;
    }
    axios.get(searchUrl)
        .then(response => {
            paginationAndDataMapping(response.data, false);
            url.value = searchUrl
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
};
const clearSearch = () => {
    if (savedResponse.value === null) {
        helpArticles.value = [];
        url.value = '/api/help-articles?sortingField=created_at&sortingOrder=desc';
        loadData(url.value);
    } else {
        paginationAndDataMapping(savedResponse.value);
    }
    searchLoaded.value = false;
};

const helpArticlesCategories = ref([]);
function getHelpArticleCategories(){
    axios.get('/api/categories?category_type=App\\Models\\HelpArticle&sortingField=name&sortingOrder=asc&all')
        .then(response => {
            helpArticlesCategories.value = response.data.data;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onBeforeMount(() => {
    getHelpArticleCategories();
})

const modaleDeleteShown = ref(false);
const idToDelete = ref(null);
const indexToDelete = ref(null);
function showModalDelete(id, index) {
    idToDelete.value = id;
    indexToDelete.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDelete'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function deleteHelpArticle() {
    axios.delete('/api/help-articles/' + idToDelete.value)
        .then(response => {
            loadData(url.value);
            getHelpArticleCategories();
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    if (modaleDeleteShown.value) {
        const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
        modaleDelete.hide()
    }
})

const filterCategoryId = ref(null);
function filterHelpArticleByCategory(categoryId){
    filterCategoryId.value = categoryId;
    if (categoryId === null) {
        url.value = '/api/help-articles?sortingField=created_at&sortingOrder=desc';
        return loadData(url.value);
    }
    url.value = '/api/help-articles?sortingField=created_at&sortingOrder=desc&category_id=' + categoryId;
    loadData(url.value);
}

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
</script>

<template>
    <div class="row">
        <!-- Sidebar: Search and categories -->
        <div class="col-xxl-3 col-12">
            <div class="card">
                <div class="card-header">
                    <p class="text-muted text-uppercase fs-12 fw-medium mb-3">Recherche</p>
                    <div class="search-box search-box-sm">
                        <input type="text" class="form-control bg-light border-0" id="searchBrandsList" placeholder="Rechercher..." @input="search" v-model="query">
                        <i class="ri-search-line search-icon"></i>
                    </div>
                </div>
                <div class="accordion accordion-flush filter-accordion">
                    <div class="card-body border-bottom">
                        <div>
                            <div class="d-flex justify-content-between">
                                <p class="text-muted text-uppercase fs-12 fw-medium mb-3">Catégories</p>
                                <a href="#" class="text-danger text-decoration-underline" @click.prevent="filterHelpArticleByCategory(null)" v-if="filterCategoryId !== null">Supprimer filtre</a>
                            </div>
                            <ul class="list-unstyled mb-0 filter-list">
                                <li v-for="(helpArticlesCategory, index) in helpArticlesCategories" :key="index" @click.prevent="filterHelpArticleByCategory(helpArticlesCategory.data.id)">
                                    <a href="#" class="d-flex py-1 align-items-center">
                                        <div class="flex-grow-1">
                                            <h5 class="fs-13 mb-0 listname" :class="{ 'text-secondary': filterCategoryId === helpArticlesCategory.data.id }">{{ helpArticlesCategory.data.name }}</h5>
                                        </div>
                                        <div class="flex-shrink-0 ms-2">
                                            <span class="badge bg-secondary-subtle text-secondary">{{ helpArticlesCategory.data.help_articles_count }}</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body border-bottom" v-if="authUser.can && authUser.can.create_help_articles">
                        <div class="d-grid">
                            <router-link :to="{'name' : 'help-articles.create' }" class="btn btn-secondary waves-effect waves-light" type="button">Ajouter</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Main Content -->
        <div class="col-xxl-9 col-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="d-lg-flex align-items-center">
                        <div class="flex-grow-1">
                            <h5 class="card-title fw-semibold mb-1">Bienvenue dans le centre d'aide !</h5>
                            <p class="text-muted mb-0">Cliquez sur l'une des catégories dans le bandeau de gauche pour afficher les articles associés, ou effectuez une recherche.</p>
                        </div>
                        <div class="flex-shrink-0 mt-4 mt-lg-0 text-muted hide-on-mobile ms-4">
                            {{ meta.total }} fiche(s) d'aide
                        </div>
                    </div>
                </div>
            </div>
            <!-- HelpArticle -->
            <div class="row">
                <div class="col-xl-4 col-sm-12 pb-4" v-for="(helpArticlesItem, index) in helpArticles" :key="index">
                    <div class="card h-100">
                        <router-link :to="{ name: 'help-articles.show', params: { id: helpArticlesItem.data.id } }">
                            <img class="card-img-top img-fluid" :src="'/format-image/'+helpArticlesItem.data.image+'?w=800&h=470&fm=webp&fit=crop'" :alt="'Thumbnail fiche d\'aide' + helpArticlesItem.data.id" width="800" height="470">
                        </router-link>
                        <div class="card-body pb-0">
                            <router-link :to="{ name: 'help-articles.show', params: { id: helpArticlesItem.data.id } }">
                                <h4 class="card-title">{{ helpArticlesItem.data.title }}</h4>
                            </router-link>
                            <p class="card-text text-muted">{{ helpArticlesItem.data.content_txt.slice(0, 300) }}...</p>
                        </div>
                        <div class="card-footer d-flex justify-content-between align-items-center">
                            <p class="card-text mb-0">
                                <small class="text-muted">{{ helpArticlesItem.data.created_at_fr_human }}</small>
                            </p>
                            <div>
                                <!--UPDATE-->
                                <router-link :to="{ name: 'help-articles.edit', params:{ id: helpArticlesItem.data.id } }" class="text-info" title="Modifier la fiche d'aide" v-if="helpArticlesItem.can.update">
                                    <i class="ri-pencil-fill fs-17 px-1"></i>
                                </router-link>
                                <!--Delete-->
                                <a href="#" class="text-danger" title="Supprimer la fiche d'aide"  data-bs-toggle="modal" data-bs-target="#modalDelete" @click.prevent="showModalDelete(helpArticlesItem.data.id, index)" v-if="helpArticlesItem.can.delete">
                                    <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pagination or Loader -->
            <div v-if="!loading">
                <pagination-centered
                    :visible="helpArticles.length > 0"
                    @change-page:clicked="loadData($event)"
                    :links="links"
                    :meta="meta"
                />
                <div class="text-center" v-if="helpArticles.length === 0">
                    <i class="mdi mdi-help-rhombus fs-36 text-muted"></i>
                    <p class="text-muted">Aucune fiche d'aide</p>
                </div>
            </div>

            <div v-if="loading">
                <loader/>
            </div>

            <modal id="modalDelete" title="Supprimer l'actualité ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteHelpArticle">
                <template v-slot:icon>
                    <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                </template>
                <template v-slot:content>
                    <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer une fiche d'aide.</p>
                </template>
            </modal>
        </div>
    </div>
</template>


<style scoped>

</style>
