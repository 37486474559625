<template>
    <div class="row">
        <div class="col-md-12 text-center mt-5">
            <div class="page-headline text-center">
                <img src="/images/illustrations/access_denied.svg" alt="illustration unauthorized" height="250" style="max-height: 250px" class="mb-6 img-fluid">
                <p class="font-size-14 mt-3">Vous n'êtes pas autorisé à accéder à cette ressource.</p>
                <a href="#" @click.prevent="$router.back()" class="btn btn-dark waves-effect waves-light mb-2">Retour</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Unauthorized"
}
</script>

<style scoped>

</style>
