import EventList from "../Views/Event/EventList.vue";
import EventCreate from "../Views/Event/EventCreate.vue";
import EventEdit from "../Views/Event/EventEdit.vue";

export const eventsRoutes = [
    {
        path: "/events/edit/:id",
        name: "events.edit",
        component: EventEdit,
        meta: {
            menu: {
                parent: 'events',
                child: null
            },
            breadcrumb: {
                title: "Évènement",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Tous les évènements",
                            link: {name: 'events.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/events/create",
        name: "events.create",
        component: EventCreate,
        meta: {
            menu: {
                parent: 'events',
                child: null
            },
            breadcrumb: {
                title: "Évènement",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Tous les évènements",
                            link: {name: 'events.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/events",
        name: "events.list",
        component: EventList,
        meta: {
            menu: {
                parent: 'events',
                child: null
            },
            breadcrumb: {
                title: "Évènement",
                nav: {
                    all: {
                        active: "Tous les évènements",
                        inactive: []
                    }
                }
            }
        },
    }
];
