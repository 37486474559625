<script setup>
import { inject, computed } from 'vue';
const brandConfig = inject('brandConfig');
const currentYear = computed(() => new Date().getFullYear());
</script>

<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    {{ currentYear }} © {{ brandConfig.companyName }}
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-end d-none d-sm-block">
                        Créé par <a href="https://mettadev.com" target="_blank" class="text-muted">MettaDev</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>
</style>
