import ProductEdit from "../Views/Product/ProductEdit.vue";
import ProductCreate from "../Views/Product/ProductCreate.vue";
import ProductList from "../Views/Product/ProductList.vue";

export const productRoutes = [
    {
        path: "/products/edit/:id",
        name: "products.edit",
        component: ProductEdit,
        meta: {
            menu: {
                parent: 'products',
                child: null
            },
            breadcrumb: {
                title: "Produits & Services",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Tous les produits",
                            link: {name: 'products.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/products/create",
        name: "products.create",
        component: ProductCreate,
        meta: {
            menu: {
                parent: 'products',
                child: null
            },
            breadcrumb: {
                title: "Produits & Services",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Tous les produits",
                            link: {name: 'products.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/products",
        name: "products.list",
        component: ProductList,
        meta: {
            menu: {
                parent: 'products',
                child: null
            },
            breadcrumb: {
                title: "Produits & Services",
                nav: {
                    all: {
                        active: "Tous les produits",
                        inactive: []
                    }
                }
            }
        },
    }
];
