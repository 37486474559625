<template>
    <div class="mb-3" :class="bsClass">
        <label :for="name" class="form-label" v-if="!labelHidden">
            {{ label }}
            <span class="text-danger" v-if="required"> *</span>
        </label>
        <br>
        <input
            class="form-control"
            :autocomplete="autocomplete"
            type="text"
            :style="hasError ? { 'borderColor': '#f06548' } : {}"
            :placeholder="placeholder"
            :name="name"
            :required="required"
            @input="updateField()"
            v-model="value"
            :disabled="disabled"
            maxlength="255"
            :id="name"
            :ref="name"
            :tabindex="tabindex"
        />
        <span class="error-message" v-html="errorMessage()" v-if="hasError"></span>
        <span class="form-text text-muted" v-if="informationContent !== null">
            {{ informationContent }}
        </span>
    </div>
</template>

<script>
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
export default {
    props : {
        name : {
            type: String,
            required: true
        },
        label : {
            type: String,
            required: true
        },
        labelHidden : {
            type: Boolean,
            required: false,
            default: false
        },
        required : {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        informationContent : {
            type: String,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        disabled : {
            type: Boolean,
            required: false,
            default: false
        },
        data: {
            required: false,
            default: null
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        },
        autocomplete : {
            type: String,
            required: false,
            default: "off"
        },
        tabindex : {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            value: null,
            iti: null
        }
    },
    mounted() {
        // Sélectionnez l'élément input par son ID
        const input = document.querySelector('#'+this.name);
        let initialCountry = 'FR';

        // Initialisez le plugin
        this.iti = intlTelInput(input, {
            nationalMode: false,
            autoPlaceholder: "aggressive",
            initialCountry: initialCountry,
            countrySearch : false,
            preferredCountries: ["FR", "BE", "LU"],
            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.20/build/js/utils.js",
            i18n: {
                searchPlaceholder: 'Rechercher un pays',
            }
        });
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            )
        }
    },
    methods: {
        updateField: function() {
            this.clearErrors(this.name);
            const fullNumber = this.iti.getNumber();
            this.$emit("update:field", fullNumber);
        },
        errorMessage: function() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        },
        clearErrors: function() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject: function() {
            return {
                "invalid-feedback-phone": this.hasError
            }
        }
    },
    watch: {
        data: {
            immediate: true,
            handler(newVal, oldVal) {
                return this.value = newVal;
            }
        }
    }
};
</script>
