<template>
    <div class="mb-3" :class="bsClass">
        <label :for="name" class="form-label" v-if="!labelHidden">
            {{ label }}
            <span class="text-danger" v-if="required"> *</span>
        </label>
        <multiselect
            v-model="userSelected"
            :options="users"
            :multiple="false"
            :close-on-select="true"
            :preserve-search="true"
            :placeholder="placeholder"
            label="name"
            :hide-selected="false"
            select-label=""
            selected-label=""
            deselect-label=""

            @search-change="searchUser"
        >
            <template v-slot:noResult>
                <span>Aucun résultats.</span>
            </template>
            <template v-slot:noOptions>
                <span>Écrivez pour rechercher un utilisateur...</span>
            </template>
        </multiselect>
        <span class="invalid-feedback text-danger" v-html="errorMessage()"></span>
        <span class="form-text text-muted" v-if="informationContent !== null">
            {{ informationContent }}
        </span>
    </div>
</template>

<script>
import _ from 'lodash';
export default {
    name: "FormSelectSearchUser",
    props: {
        name : {
            type: String,
            required: true
        },
        label : {
            type: String,
            required: true
        },
        labelHidden : {
            type: Boolean,
            required: false,
            default: false
        },
        required : {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        informationContent : {
            type: String,
            required: false,
            default: null
        },
        reference : {
            type: String,
            required: false,
            default: 'multiple-select'
        },
        userPreSelected: {
            type: Object,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        }
    },
    data() {
        return {
            userSelected : [],
            users: [],
            loading: false,
        }
    },
    mounted() {
    },
    methods: {
        searchUser: _.debounce(function(query) {
            if (query.length < 3) {
                return;
            }
            this.loading = true;
            axios.get('/api/search/users', { params: { query: query } })
                .then(response => {
                    this.users = response.data.data.map(user => ({
                        id: user.data.id,
                        name: `${user.data.first_name} ${user.data.last_name}`
                    }));
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }, 250),
        clearAll () {
            this.userSelected = []
        },
        clearErrors: function() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject: function() {
            return {
                "is-invalid": this.hasError
            }
        },
        errorMessage: function() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        }
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            );
        }
    },
    watch: {
        userSelected: function(){
            if( this.userSelected === null) {
                return this.$emit("update:field", null);
            } else {
                this.clearErrors(this.name);
                return this.$emit("update:field", this.userSelected);
            }
        },
        userPreSelected: {
            immediate: true,
            handler(newVal, oldVal) {
                //is not empty object or null
                if (this.userPreSelected !== null && Object.keys(this.userPreSelected).length !== 0 ) {
                    this.userSelected = {
                        id: this.userPreSelected.data.id,
                        name: this.userPreSelected.data.first_name + ' ' + this.userPreSelected.data.last_name
                    }
                    this.users = [this.userSelected];
                    this.$emit("update:field", this.userSelected);
                }
            }
        }
    }
}
</script>

<style>

</style>
