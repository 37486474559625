<script setup>
import { onBeforeMount, reactive, ref, watch} from "vue";
import axios from "axios";
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import Loader from "../../Components/Loader.vue";
import FormSelectSearchProduct from "../../Components/FormSelectSearchProduct.vue";
import FormNumericField from "../../Components/FormNumericField.vue";
import {useToast} from "vue-toast-notification";
import Modal from "../../Components/Modal.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();
const $toast = useToast();

const quote = ref(null);
const quoteLines = ref([]);
const loading = ref(true);
const errors = ref(null);
function getQuote(){
    axios.get("/api/quotes/" + router.currentRoute.value.params.id)
        .then(response => {
            quote.value = response.data;
            internalNotes.value = response.data.data.notes;
            formCreate.quote_id = response.data.data.id;
            formCreate.tax_rate = response.data.data.tax_rate;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}

function getQuoteLines(){
    loadingQuoteLines.value = true;
    axios.get("/api/quote-lines", { params: { quote_id: router.currentRoute.value.params.id, sortingField: 'created_at', sortingOrder: 'asc' } })
        .then(response => {
            quoteLines.value = response.data.data;
            loadingQuoteLines.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loadingQuoteLines.value = false;
        });
}
onBeforeMount(() => {
    getQuote();
    getQuoteLines();
});

function showModalAddProduct(){
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalAddProduct'))
    modal.show();
}
const loadingQuoteLines = ref(false);
const formCreate = reactive({
    quote_id: null,
    product_id: null,
    quantity: 1,
    unit_price_excl_tax: 0,
    unit_price_with_tax: null,
    percentage_discount: null,
    tax_rate: null,
    total_price_with_tax: null,
})
function addProduct(){
    loadingQuoteLines.value = true;
    axios.post("/api/quote-lines", formCreate)
        .then(response => {
            getQuotePrices();
            quoteLines.value.push(response.data);
            $toast.success('Produit ajouté avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            loadingQuoteLines.value = false;
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalAddProduct'))
            modal.hide();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loadingQuoteLines.value = false;
        });
}

const formUpdate = reactive({
    quote_id: null,
    product_id: null,
    quantity: null,
    unit_price_excl_tax: null,
    unit_price_with_tax: null,
    percentage_discount: null,
    tax_rate: null,
    total_price_with_tax: null,
})
const quoteLineUpdateId = ref(null);
const quoteLineUpdateIndex = ref(null);
const productSelectedToUpdate = ref(null);
function showModalEditProduct(quoteLineId, quoteLineIndex){
    productSelectedToUpdate.value = {
        data : {
            id: quoteLines.value[quoteLineIndex].data.product.data.id,
            name: quoteLines.value[quoteLineIndex].data.product.data.product_code + ' - ' + quoteLines.value[quoteLineIndex].data.product.data.name,
            price_excluding_tax: quoteLines.value[quoteLineIndex].data.unit_price_excl_tax
        }
    };
    quoteLineUpdateId.value = quoteLineId;
    quoteLineUpdateIndex.value = quoteLineIndex;
    formUpdate.quote_id = quote.value.data.id;
    formUpdate.product_id = quoteLines.value[quoteLineIndex].data.product.data.id;
    formUpdate.quantity = quoteLines.value[quoteLineIndex].data.quantity;
    formUpdate.unit_price_excl_tax = quoteLines.value[quoteLineIndex].data.unit_price_excl_tax;
    formUpdate.percentage_discount = quoteLines.value[quoteLineIndex].data.percentage_discount;
    formUpdate.tax_rate = quoteLines.value[quoteLineIndex].data.tax_rate;
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEditProduct'))
    modal.show();
}
function updateProduct(){
    loadingQuoteLines.value = true;
    axios.put("/api/quote-lines/" + quoteLineUpdateId.value, formUpdate)
        .then(response => {
            getQuotePrices();
            quoteLines.value[quoteLineUpdateIndex.value] = response.data;
            $toast.success('Produit modifié avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            loadingQuoteLines.value = false;
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEditProduct'))
            modal.hide();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loadingQuoteLines.value = false;
        });
}

const quoteLineDeleteId = ref(null);
const quoteLineDeleteIndex = ref(null);
function showModalDeleteProduct(quoteLineId, quoteLineIndex){
    quoteLineDeleteId.value = quoteLineId;
    quoteLineDeleteIndex.value = quoteLineIndex;
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDeleteProduct'))
    modal.show();
}
function deleteProductFromQuote(){
    loadingQuoteLines.value = true;
    axios.delete("/api/quote-lines/" + quoteLineDeleteId.value)
        .then(() => {
            getQuotePrices();
            quoteLines.value.splice(quoteLineDeleteIndex.value, 1);
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDeleteProduct'))
            modal.hide();
            $toast.success('Produit supprimé avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            loadingQuoteLines.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loadingQuoteLines.value = false;
        });

}

const updatingQuotePrices = ref(false);
function getQuotePrices(){
    updatingQuotePrices.value = true;
    axios.get("/api/quotes/" + quote.value.data.id + "?price_only=true")
        .then(response => {
            quote.value.data.total_price_excl_tax = response.data.data.total_price_excl_tax;
            quote.value.data.total_tax = response.data.data.total_tax;
            quote.value.da
            quote.value.data.total_price_with_tax = response.data.data.total_price_with_tax;
            updatingQuotePrices.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            updatingQuotePrices.value = false;
        });
}

function roundToTwo(num) {
    return Math.round(num * 100) / 100;
}

const generatingPdf = ref(false);
function generateQuotePdf(){
    generatingPdf.value = true;
    axios.get("/api/quotes/" + quote.value.data.id + "/pdf")
        .then(response => {
            window.open(response.data.url, '_blank');
            generatingPdf.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            generatingPdf.value = false;
        });

}

watch(() => [formCreate.unit_price_excl_tax, formCreate.tax_rate, formCreate.quantity, formCreate.percentage_discount], () => {
    if(formCreate.quantity !== null && formCreate.unit_price_excl_tax !== null && formCreate.tax_rate !== null){
        formCreate.unit_price_with_tax = roundToTwo(formCreate.unit_price_excl_tax * (1 + formCreate.tax_rate / 100));
    }
});

watch(() => [formUpdate.unit_price_excl_tax, formUpdate.tax_rate, formUpdate.quantity, formUpdate.percentage_discount], () => {
    if(formUpdate.quantity !== null && formUpdate.unit_price_excl_tax !== null && formUpdate.tax_rate !== null){
        formUpdate.unit_price_with_tax = roundToTwo(formUpdate.unit_price_excl_tax * (1 + formUpdate.tax_rate / 100));
    }
});

const quoteStatuses = reactive([
    { value: 'to_bo_processed', label: 'À traiter', badgeColor: 'warning' },
    { value: 'waiting_for_answer', label: 'En attente de réponse', badgeColor: 'secondary' },
    { value: 'accepted', label: 'Accepté', badgeColor: 'success' },
    { value: 'rejected', label: 'Refusé', badgeColor: 'danger' },
    { value: 'canceled', label: 'Annulé', badgeColor: 'dark' },
]);
function changeStatus(status) {
    if (status === quote.value.data.status) {
        return;
    }
    axios.patch("/api/quotes/" + quote.value.data.id + "/status", { status: status })
        .then(response => {
            quote.value.data.status = response.data.data.status;
            quote.value.data.status_label_fr = response.data.data.status_label_fr;
            quote.value.data.status_badge_color = response.data.data.status_badge_color;
            $toast.success('Statut mis à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function showModalInternalNotes() {
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalInternalNotes'))
    modal.show();
}
const internalNotes = ref(null)
function updateInternalNotes() {
    axios.patch("/api/quotes/" + quote.value.data.id + "/notes", { notes: internalNotes.value })
        .then(response => {
            quote.value.data.notes = response.data.data.notes;
            $toast.success('Note interne mise à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalInternalNotes'))
            modal.hide();
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function copyLinkQuote(){
    const url = window.location.origin + '/external/quotes/' + quote.value.data.uuid;
    navigator.clipboard.writeText(url);
    $toast.info('Lien copié', {
        position: 'top',
        duration: 5000,
        dismissible: true,
        pauseOnHover: true,
    });
}

function showModalConvertToInvoice(){
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalConvertQuote'))
    modal.show();
}
const convertingToInvoice = ref(false);
function convertToInvoice(){
    convertingToInvoice.value = true;
    axios.post("/api/quotes/" + quote.value.data.id + "/convert-to-invoice")
        .then(response => {
            $toast.success('Devis converti en facture', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalConvertQuote'))
            modal.hide();
            convertingToInvoice.value = false;
            router.push({ name: 'invoices.show', params: { id: response.data.data.id } });
        })
        .catch(error => {
            httpErrorsHandler(error);
            convertingToInvoice.value = false;
        });
}
</script>

<template>
    <div>
        <div class="col-12" v-show="loading">
            <loader />
        </div>
        <div v-if="!loading" class="row">
            <!--Quotes infos-->
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header align-items-center d-flex border-bottom-0">
                        <h4 class="card-title mb-0 flex-grow-1 align-middle"><i class="mdi mdi-file-sign me-2 align-middle fs-4"></i>Informations générales</h4>
                        <div class="avatar-xs me-2">
                            <a href="#" class="avatar-title bg-warning-subtle text-warning fs-15 rounded" title="Notes" @click.prevent="showModalInternalNotes">
                                <i class="ri-sticky-note-fill"></i>
                            </a>
                        </div>
                        <div class="avatar-xs">
                            <router-link :to="{ name: 'quotes.edit', params:{ id: quote.data.id } }" class="avatar-title bg-secondary-subtle text-secondary fs-15 rounded" title="Modifier">
                                <i class="ri-edit-2-fill"></i>
                            </router-link>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="table-responsive">
                            <table class="table table-small table-nowrap mb-0">
                                <tbody>
                                    <tr>
                                        <th scope="row" class="col-5">Numéro devis</th>
                                        <td class="text-body fw-semibold col-7">{{ quote.data.quote_number }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="col-5">Centre</th>
                                        <td class="text-body col-7">{{ quote.data.center.data.name }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="col-5">Statut</th>
                                        <td class="col-7">
                                            <span :class="'badge fs-11 bg-'+quote.data.status_badge_color+'-subtle  text-'+quote.data.status_badge_color">{{ quote.data.status_label_fr }}</span>
                                            <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" id="statusDropdown" data-bs-toggle="dropdown" v-if="quote.can.update"></a>
                                            <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                                <li v-for="(status, key) in quoteStatuses" :key="key">
                                                    <a class="dropdown-item" href="#" @click.prevent="changeStatus(status.value)">
                                                        <span :class="`fs-11 badge bg-${status.badgeColor}-subtle text-${status.badgeColor}`">{{ status.label }}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                            <span class="text-muted ms-1" v-if="quote.data.status === 'accepted'">{{quote.data.accepted_at}}</span>
                                            <span class="text-muted ms-1" v-if="quote.data.status === 'rejected'">{{quote.data.refused_at}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="col-5">Date devis</th>
                                        <td class="text-body col-7">{{ quote.data.quote_date_fr }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="col-5 border-bottom-0">Fin de validité</th>
                                        <td class="border-bottom-0 text-body col-7">{{ quote.data.valid_until_fr }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!--Client infos-->
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header align-items-center d-flex border-bottom-0">
                        <h4 class="card-title mb-0 flex-grow-1 align-middle"><i class="mdi mdi-account me-2 align-middle fs-4"></i>Client</h4>
                    </div>
                    <div class="card-body pt-0">
                        <div class="table-responsive">
                            <table class="table table-small table-nowrap mb-0">
                                <tbody>
                                <tr>
                                    <th scope="row" class="col-5">Nom</th>
                                    <td class="text-body col-7">
                                        <router-link :to="{ name: 'clients.show', params:{ id: quote.data.client.data.id } }" class="text-secondary">
                                            {{ quote.data.client.data.first_name }} {{ quote.data.client.data.last_name }}
                                        </router-link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="col-5">Type</th>
                                    <td class="text-body col-7">
                                        <span :class="'badge fs-11 bg-'+quote.data.client.data.client_status_badge_color+'-subtle  text-'+quote.data.client.data.client_status_badge_color">{{ quote.data.client.data.client_status_label_fr }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="col-5">Téléphone</th>
                                    <td class="text-body col-7">{{ quote.data.client.data.phone_number }}</td>
                                </tr>
                                <tr>
                                    <th scope="row" class="col-5">Email</th>
                                    <td class="col-7">{{ quote.data.client.data.email }}</td>
                                </tr>
                                <tr>
                                    <th scope="row" class="col-5 border-bottom-0">Véhicule</th>
                                    <td class="border-bottom-0 text-body col-7">
                                        <span v-if="quote.data.client.data.engine !== null">
                                            <router-link :to="{ name: 'chiptuning.show', params:{ id: quote.data.client.data.engine.id } }" class="text-secondary">
                                                {{ quote.data.client.data.engine.data.brand.data.name }} {{ quote.data.client.data.engine.data.model.data.name }}
                                            </router-link>
                                        </span>
                                        <span v-else>--</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Quotes lines -->
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header d-flex flex-column flex-md-row align-items-start align-items-md-center">
                        <h4 class="card-title mb-0 flex-grow-1">
                            <i class="mdi mdi-format-list-text me-2 align-middle fs-4"></i>Détails devis
                        </h4>
                        <div class="mt-3 mt-md-0 d-flex flex-wrap">
                            <!--Button copy link-->
                            <button type="button" class="btn btn-soft-info btn-sm shadow-none me-1 align-middle mb-1" @click.prevent="copyLinkQuote">
                                <i class="ri-file-copy-2-fill align-middle me-1"></i> Copier lien
                            </button>
                            <!--Convert invoice-->
                            <button type="button" class="btn btn-soft-primary btn-sm shadow-none me-1 align-middle mb-1" @click.prevent="showModalConvertToInvoice">
                                <i class=" ri-bank-fill align-middle me-1"></i> Convertir en facture
                            </button>
                            <button type="button" class="btn btn-soft-dark btn-sm shadow-none me-1 align-middle mb-1" @click.prevent="generateQuotePdf">
                                <i class="ri-file-pdf-fill align-middle me-1" v-if="!generatingPdf"></i>
                                <i class="spinner-border spinner-border-xs me-1" role="status" v-else></i> Générer PDF
                            </button>
                            <button type="button" class="btn btn-secondary btn-sm shadow-none mb-1" @click.prevent="showModalAddProduct">
                                <i class="ri-add-circle-line align-middle me-1"></i> Ajouter produit
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive table-card mb-3">
                            <table class="table table-small mb-0 align-middle table-hover table-nowrap table-bordered">
                                <thead class="table-light">
                                    <tr>
                                        <th class="col-1">Code produit</th>
                                        <th class="col-1">Type</th>
                                        <th class="col-4" scope="col">Libellé produit</th>
                                        <th class="col-1 text-end" scope="col">TVA</th>
                                        <th class="col-1 text-end" scope="col">P.U. HT</th>
                                        <th class="col-1 text-center" scope="col">Quantité</th>
                                        <th class="col-1 text-end" scope="col">Réduction</th>
                                        <th class="col-1 text-end" scope="col">Total HT</th>
                                        <th class="col-1 text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                    <tr v-for="(quoteLine, index) in quoteLines" :key="index" v-show="!loadingQuoteLines">
                                        <td class="fw-medium text-dark">
                                            {{ quoteLine.data.product.data.product_code }}
                                        </td>
                                        <td>
                                            <span :class="'badge fs-11 bg-'+quoteLine.data.product.data.type_badge_color+'-subtle  text-'+quoteLine.data.product.data.type_badge_color">{{ quoteLine.data.product.data.type_label_fr }}</span>
                                        </td>
                                        <td class="description-cell">
                                            {{ quoteLine.data.product.data.name }}
                                            <div v-if="quoteLine.data.product.data.description" class="text-black-50">
                                                <br><span v-html="quoteLine.data.product.data.description.replace(/\n/g, '<br />')"></span>
                                            </div>
                                        </td>
                                        <td class="text-end">{{ quoteLine.data.tax_rate.toPrecision() }}%</td>
                                        <td class="text-end">{{ quoteLine.data.unit_price_excl_tax.toLocaleString('fr-FR', { style: 'currency', currency: quote.data.currency }) }}</td>
                                        <td class="text-center">{{ quoteLine.data.quantity }}</td>
                                        <td class="text-end">{{ quoteLine.data.percentage_discount > 0 ? `${quoteLine.data.percentage_discount}%` : '--' }}</td>
                                        <td class="text-end">{{ quoteLine.data.total_price_excl_tax.toLocaleString('fr-FR', { style: 'currency', currency: quote.data.currency }) }}</td>
                                        <td class="text-center">
                                            <!--Update-->
                                            <a href="#" class="text-info" title="Modifier le produit" @click.prevent="showModalEditProduct(quoteLine.data.id, index)" v-if="quoteLine.can.update">
                                                <i class="ri-pencil-fill fs-17 px-1"></i>
                                            </a>
                                            <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                            <!--Delete-->
                                            <a href="#" class="text-danger" title="Supprimer le produit" @click.prevent="showModalDeleteProduct(quoteLine.data.id, index)" v-if="quoteLine.can.delete">
                                                <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                            </a>
                                            <i class="ri-delete-bin-3-fill fs-17 px-1 text-muted" v-else></i>
                                        </td>
                                    </tr>
                                    <tr class="text-center font-italic text-muted" v-if="quoteLines.length === 0 && !loadingQuoteLines">
                                        <td colspan="9">Aucun produit...</td>
                                    </tr>
                                    <!--Total HT -->
                                    <tr class="align-middle" v-show="!loadingQuoteLines">
                                        <td class="text-end fw-medium" colspan="7"><span class="me-1">TOTAL HT</span></td>
                                        <td class="text-end border-end-0 fw-medium">
                                            <span class="card-text placeholder-glow" v-if="updatingQuotePrices">
                                                <span class="placeholder rounded col-3"></span>
                                            </span>
                                            <span v-else>
                                                {{ quote.data.total_price_excl_tax.toLocaleString('fr-FR', { style: 'currency', currency: quote.data.currency }) }}
                                            </span>
                                        </td>
                                        <td class="border-start-0"></td>
                                    </tr>
                                    <!--Total TVA -->
                                    <tr class="align-middle" v-show="!loadingQuoteLines">
                                        <td class="text-end text-muted" colspan="7"><span class="me-1">TOTAL TVA</span></td>
                                        <td class="text-muted text-end border-end-0">
                                            <span class="card-text placeholder-glow" v-if="updatingQuotePrices">
                                                <span class="placeholder rounded col-3"></span>
                                            </span>
                                            <span v-else>
                                                {{ quote.data.total_tax.toLocaleString('fr-FR', { style: 'currency', currency: quote.data.currency }) }}
                                            </span>
                                        </td>
                                        <td class="border-start-0"></td>
                                    </tr>
                                    <!--Total TTC -->
                                    <tr class="align-middle" v-show="!loadingQuoteLines">
                                        <td class="text-end fw-bold" colspan="7"><span class="me-1">TOTAL TTC</span></td>
                                        <td class="fw-bold text-end border-end-0">
                                            <span class="card-text placeholder-glow" v-if="updatingQuotePrices">
                                                <span class="placeholder rounded col-3"></span>
                                            </span>
                                            <span v-else>
                                                {{ quote.data.total_price_with_tax.toLocaleString('fr-FR', { style: 'currency', currency: quote.data.currency }) }}
                                            </span>
                                        </td>
                                        <td class="border-start-0"></td>
                                    </tr>
                                    <tr v-if="loadingQuoteLines">
                                        <td colspan="9">
                                            <loader></loader>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p class="mb-0 col-8 fs-11" v-html="quote.data.comment !== null ? quote.data.comment.replace(/\n/g, '<br />') : null"></p>
                    </div>
                </div>
                <!-- Modal add product -->
                <div id="modalAddProduct" class="modal fade" tabindex="-1" aria-labelledby="modalAddProduct" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <form v-on:submit.prevent="addProduct">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalAddProduct">Ajouter un produit</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                                </div>
                                <div class="modal-body row">
                                    <form-select-search-product
                                        :errors="errors"
                                        label="Produit / Service"
                                        name="product_id"
                                        :required="true"
                                        :data="formCreate.product_id"
                                        @update:field="formCreate.product_id = ($event !== null ? $event.id : null)"
                                        @update:price="formCreate.unit_price_excl_tax = ($event !== null ? $event.price_excluding_tax : null)"
                                        bs-class="col-md-12"
                                        :disabled="false"
                                        :taggable="false"
                                        :label-hidden="false"
                                        placeholder="Sélectionnez un produit"
                                    />

                                    <form-numeric-field
                                        name="quantity"
                                        label="Quantité"
                                        :label-hidden="false"
                                        @update:field="formCreate.quantity = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formCreate.quantity"
                                        bs-class="col-md-4"
                                        :min="1"
                                        :step="1"
                                    />

                                    <form-numeric-field
                                        name="unit_price_excl_tax"
                                        label="Prix unitaire HT"
                                        :label-hidden="false"
                                        @update:field="formCreate.unit_price_excl_tax = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formCreate.unit_price_excl_tax"
                                        bs-class="col-md-4"
                                        :min="0"
                                        :step="0.01"
                                    />

                                    <form-numeric-field
                                        name="unit_price_with_tax"
                                        label="Prix unitaire TTC"
                                        :label-hidden="false"
                                        @update:field="formCreate.unit_price_with_tax = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formCreate.unit_price_with_tax"
                                        bs-class="col-md-4"
                                        :disabled="true"
                                        :min="0"
                                        :step="0.01"
                                    />

                                    <form-numeric-field
                                        name="percentage_discount"
                                        label="Remise en %"
                                        :label-hidden="false"
                                        @update:field="formCreate.percentage_discount = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formCreate.percentage_discount"
                                        bs-class="col-md-6"
                                        :min="0"
                                        information-content="Laisser à 0 ou vide si pas de réduction"
                                    />

                                    <form-numeric-field
                                        name="tax_rate"
                                        label="TVA en %"
                                        :label-hidden="false"
                                        @update:field="formCreate.tax_rate = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formCreate.tax_rate"
                                        bs-class="col-md-6"
                                        :min="0"
                                        :step="0.1"
                                        information-content="Taux de TVA basé sur le devis"
                                    />

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                                    <button type="submit" class="btn btn-secondary">Valider</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- Modal edit product -->
                <div id="modalEditProduct" class="modal fade" tabindex="-1" aria-labelledby="modalEditProduct" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <form v-on:submit.prevent="updateProduct">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalEditProduct">Modifier un produit</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                                </div>
                                <div class="modal-body row">
                                    <form-select-search-product
                                        :errors="errors"
                                        label="Produit / Service"
                                        name="product_id"
                                        :required="true"
                                        :data="formUpdate.product_id"
                                        @update:field="formUpdate.product_id = ($event !== null ? $event.id : null)"
                                        @update:price="formUpdate.unit_price_excl_tax = ($event !== null ? $event.price_excluding_tax : null)"
                                        bs-class="col-md-12"
                                        :disabled="false"
                                        :taggable="false"
                                        :label-hidden="false"
                                        placeholder="Sélectionnez un produit"
                                        :product-pre-selected="productSelectedToUpdate"
                                    />

                                    <form-numeric-field
                                        name="quantity"
                                        label="Quantité"
                                        :label-hidden="false"
                                        @update:field="formUpdate.quantity = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formUpdate.quantity"
                                        bs-class="col-md-4"
                                        :min="1"
                                        :step="1"
                                    />

                                    <form-numeric-field
                                        name="unit_price_excl_tax"
                                        label="Prix unitaire HT"
                                        :label-hidden="false"
                                        @update:field="formUpdate.unit_price_excl_tax = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formUpdate.unit_price_excl_tax"
                                        bs-class="col-md-4"
                                        :min="0"
                                        :step="0.01"
                                    />

                                    <form-numeric-field
                                        name="unit_price_with_tax"
                                        label="Prix unitaire TTC"
                                        :label-hidden="false"
                                        @update:field="formUpdate.unit_price_with_tax = $event"
                                        :errors="errors"
                                        :required="false"
                                        :disabled="true"
                                        :data="formUpdate.unit_price_with_tax"
                                        bs-class="col-md-4"
                                        :min="0"
                                        :step="0.01"
                                    />

                                    <form-numeric-field
                                        name="percentage_discount"
                                        label="Remise en %"
                                        :label-hidden="false"
                                        @update:field="formUpdate.percentage_discount = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formUpdate.percentage_discount"
                                        bs-class="col-md-6"
                                        :min="0"
                                        information-content="Laisser à 0 ou vide si pas de réduction"
                                    />

                                    <form-numeric-field
                                        name="tax_rate"
                                        label="TVA en %"
                                        :label-hidden="false"
                                        @update:field="formUpdate.tax_rate = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formUpdate.tax_rate"
                                        bs-class="col-md-6"
                                        :min="0"
                                        :step="0.1"
                                        information-content="Taux de TVA basé sur le devis"
                                    />

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                                    <button type="submit" class="btn btn-secondary">Valider</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!--Modal delete product-->
                <modal id="modalDeleteProduct" title="Supprimer le produit ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteProductFromQuote">
                    <template v-slot:icon>
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                    </template>
                    <template v-slot:content>
                        <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer un produit du devis. Continuer ?</p>
                    </template>
                </modal>
                <!-- Modal notes -->
                <div id="modalInternalNotes" class="modal fade" tabindex="-1" aria-labelledby="modalInternalNotes" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalInternalNotes">Notes internes</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <div class="modal-body">
                                <form-textarea
                                    name="notes"
                                    label="Notes internes"
                                    :label-hidden="false"
                                    @update:field="internalNotes = $event"
                                    :errors="errors"
                                    :required="false"
                                    :data="internalNotes"
                                    bs-class="col-xl-12"
                                    :row="10"
                                />
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                                <button type="button" class="btn btn-secondary" @click.prevent="updateInternalNotes">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal convert to invoice -->
                <div id="modalConvertQuote" class="modal fade" tabindex="-1" aria-labelledby="modalConvertQuote" aria-hidden="true" >
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalConvertQuote">Convertir le devis en facture</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <div class="modal-body">
                                <div v-if="!loading">
                                    <p>Vous êtes sur le point de convertir le devis en facture. Toutes les données du devis seront transférées à la facture. Continuer ? </p>
                                </div>
                                <loader v-else />
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                                <button type="button" class="btn btn-soft-primary" @click.prevent="convertToInvoice" v-if="!convertingToInvoice">Convertir</button>
                                <button type="button" class="btn btn-soft-primary btn-load" v-else>
                                <span class="d-flex align-items-center">
                                    <span class="flex-grow-1 me-2">
                                        Conversion...
                                    </span>
                                    <span class="spinner-border flex-shrink-0" role="status">
                                        <span class="visually-hidden">Conversion...</span>
                                    </span>
                                </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.description-cell {
    min-width: 500px !important;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
</style>
