import Dashboard from "../Views/Dashboard/Dashboard.vue";

export const dashboardRoutes = [
    {
        path: "/",
        name: "dashboard",
        component: Dashboard,
        meta: {
            menu: {
                parent: 'dashboard',
                child: null
            },
            breadcrumb: {
                title: "Dashboard",
                nav: {
                    all: {
                        active: "Dashboard",
                        inactive: []
                    }
                }
            }
        },
    },
];
