<script setup>
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import {computed, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref} from "vue";
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import Loader from "../../Components/Loader.vue";

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
const { httpErrorsHandler } = useHttpErrorsHandler();

// Client requests
const loadingClientRequests = ref(true);
const clientRequests = ref([]);
function getClientRequests() {
    clientRequests.value = [];
    loadingClientRequests.value = true;
    axios.get('/api/client-requests', { params : { take: 5, sortingField: 'updated_at', sortingOrder: 'desc' } })
        .then(response => {
            clientRequests.value = response.data.data;
            loadingClientRequests.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeMount(() => {
    if (authUser.value.role === 'manager'){
        getClientRequests();
    }
});

//News
const loadingNews = ref(true);
const news = ref([]);

function getNews() {
    news.value = [];
    loadingNews.value = true;
    axios.get('/api/news', { params : { take: 3, sortingField: 'created_at', sortingOrder: 'desc' } })
        .then(response => {
            news.value = response.data.data;
            loadingNews.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onBeforeMount(() => {
    getNews();
});

//Events
const loadingEvents = ref(true);
const upcomingEvents = ref([]);
function getUpcomingEvents() {
    loadingEvents.value = true;
    upcomingEvents.value = [];
    axios.get('/api/events', { params: {
            start_date: new Date(),
            take: 3,
            show_my_events: true,
            show_other_events: false,
            show_global_events: true
    } })
        .then(response => {
            upcomingEvents.value = response.data.data;
            loadingEvents.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onBeforeMount(() => {
    getUpcomingEvents();
});

//Calls
const loadingCalls = ref(true);
const calls = ref([]);
function getCalls() {
    calls.value = [];
    loadingCalls.value = true;
    axios.get('/api/calls', { params : { take: 5, sortingField: 'created_at', sortingOrder: 'desc' } })
        .then(response => {
            calls.value = response.data.data;
            loadingCalls.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeMount(() => {
    if (authUser.value.role === 'manager'){
        getCalls();
    }
});
function defineCallTypeBadge(call) {
    if (call.data.direction === 'out') {
        return 'out';
    }
    if (call.data.direction === 'in' && call.data.last_state === 'missed') {
        return 'missed';
    }
    if (call.data.direction === 'in' && (call.data.last_state === 'voicemail' || call.data.voicemail_link !== null)) {
        return 'voicemail';
    }
    if (call.data.direction === 'in' && (call.data.last_state === 'hangup' || call.data.last_state === 'answered' || call.data.last_state === 'ringing')) {
        return 'in';
    }
}

//Refresh data
let intervalId = ref(null);

function fetchAll() {
    getClientRequests();
    getClientRequestsCount();
    getCallCount();
}

onMounted(() => {
    if (authUser.value.role === 'manager'){
        intervalId.value = setInterval(fetchAll, 120000); //2minutes
    }
});

onBeforeUnmount(() => {
    if (intervalId.value) {
        clearInterval(intervalId.value);
    }
});

const currentYear = computed(() => new Date().getFullYear());
const currentMonth = computed(() => new Intl.DateTimeFormat("fr-FR", { month: "long" }).format());

const clientRequestsCountThisMonth = ref(0);
const clientRequestsPercentageChange = ref(0);
const loadingClientRequestsCount = ref(true);
function getClientRequestsCount() {
    loadingClientRequestsCount.value = true;
    axios.get('/api/client-requests/dashboard')
        .then(response => {
            clientRequestsCountThisMonth.value = response.data.data.client_requests_this_month;
            clientRequestsPercentageChange.value = response.data.data.percentage_change;
            loadingClientRequestsCount.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeMount(() => {
    if (authUser.value.role === 'manager'){
        getClientRequestsCount();
    }
});

const callsIncomingCountThisMonth = ref(0);
const callsIncomingPercentageChange = ref(0);
const callsOutgoingCountThisMonth = ref(0);
const callsOutgoingPercentageChange = ref(0);
const loadingCallsIncomingCount = ref(true);
function getCallCount() {
    loadingCallsIncomingCount.value = true;
    axios.get('/api/calls/dashboard')
        .then(response => {
            callsIncomingCountThisMonth.value = response.data.data.incoming_calls_this_month;
            callsIncomingPercentageChange.value = response.data.data.percentage_change_incoming;
            callsOutgoingCountThisMonth.value = response.data.data.outgoing_calls_this_month;
            callsOutgoingPercentageChange.value = response.data.data.percentage_change_outgoing;
            loadingCallsIncomingCount.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeMount(() => {
    if (authUser.value.role === 'manager'){
        getCallCount();
    }
});

onBeforeMount(() => {
    if (authUser.value.role === 'manager'){
        subscribeToCallChannel();
    }
});
function subscribeToCallChannel() {
    authUser.value.centers.forEach(center => {
        Echo.channel('calls_center_' + center.id)
            .listen('CallCreatedEvent', (event) => {
                calls.value.unshift(event.call);
                calls.value.pop();
            })
            .listen('CallUpdatedEvent', (event) => {
                const index = calls.value.findIndex(call => call.data.id === event.call.data.id)
                if (index !== -1) {
                    calls.value[index] = event.call
                }
            });
    });
}

onUnmounted(() => {
    if (authUser.value.role === 'manager'){
        authUser.value.centers.forEach(center => {
            Echo.leaveChannel('calls_center_' + center.id);
        });
    }
});
</script>
<template>
    <div>
        <div class="row pb-1">
            <!--Welcome header-->
            <div class="col-12 mb-3">
                <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                    <div class="flex-grow-1">
                        <h4 class="fs-16 mb-1">Bonjour {{ authUser.first_name }} !</h4>
                        <p class="text-muted mb-0">Bienvenue dans votre dashboard.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="authUser.role === 'manager'">
            <div class="col-xl-3">
                <div class="card card-animate">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <img :src="'/format-image/'+authUser.profile_picture+'?w=110&h=110&fm=webp&fit=crop'"  class="rounded-circle avatar-lg img-thumbnail user-profile-image" alt="user-profile-image">
                            </div>
                            <div class="flex-grow-1 overflow-hidden ms-3">
                                <div class="d-flex align-items-center">
                                    <h4 class="fs-16 flex-grow-1 mb-2">
                                        {{ authUser.first_name }} {{ authUser.last_name }}
                                    </h4>
                                </div>
                                <span :class="'badge fs-11 bg-'+authUser.role_badge_color+'-subtle mb-2  text-'+authUser.role_badge_color">{{ authUser.role_label_fr }}</span>
                                <p class="fw-medium text-muted mb-0">{{ authUser.job_title }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Client requests-->
            <div class="col-xl-3">
                <div class="card card-animate">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <p class="fw-medium text-muted mb-0">Demandes clients - {{ currentMonth }} {{ currentYear }}</p>
                                <h2 class="mt-3 ff-secondary fw-semibold">
                                    <span class="counter-value" :data-target="clientRequestsCountThisMonth" v-if="!loadingClientRequestsCount">
                                        {{ clientRequestsCountThisMonth }}
                                    </span>
                                    <span class="card-text placeholder-glow" v-else>
                                        <span class="placeholder rounded col-3"></span>
                                    </span>
                                </h2>
                                <p class="mb-0 text-muted">
                                    <span class="badge bg-danger-subtle text-danger mb-0" v-if="clientRequestsPercentageChange <= 0">
                                        <i class="ri-arrow-down-line align-middle"></i> {{ clientRequestsPercentageChange }} %
                                    </span>
                                    <span class="badge bg-success-subtle text-success mb-0" v-else>
                                        <i class="ri-arrow-up-line align-middle"></i> {{ clientRequestsPercentageChange }} %
                                    </span>
                                     vs. mois dernier
                                </p>
                            </div>
                            <div>
                                <div class="avatar-sm flex-shrink-0">
                                    <span class="avatar-title bg-warning-subtle text-warning rounded-circle fs-2">
                                        <i class="mdi mdi-car-info"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Incoming calls-->
            <div class="col-xl-3">
                <div class="card card-animate">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <p class="fw-medium text-muted mb-0">Appels entrants - {{ currentMonth }} {{ currentYear }}</p>
                                <h2 class="mt-3 ff-secondary fw-semibold">
                                    <span class="counter-value" :data-target="callsIncomingCountThisMonth" v-if="!loadingCalls">
                                        {{ callsIncomingCountThisMonth }}
                                    </span>
                                    <span class="card-text placeholder-glow" v-else>
                                        <span class="placeholder rounded col-3"></span>
                                    </span>
                                </h2>

                                <p class="mb-0 text-muted">
                                    <span class="badge bg-danger-subtle text-danger mb-0" v-if="callsIncomingPercentageChange <= 0">
                                        <i class="ri-arrow-down-line align-middle"></i> {{ callsIncomingPercentageChange }} %
                                    </span>
                                    <span class="badge bg-success-subtle text-success mb-0" v-else>
                                        <i class="ri-arrow-up-line align-middle"></i> {{ callsIncomingPercentageChange }} %
                                    </span>
                                    vs. mois dernier
                                </p>
                            </div>
                            <div>
                                <div class="avatar-sm flex-shrink-0">
                                    <span class="avatar-title bg-success-subtle text-success rounded-circle fs-2">
                                        <i class="mdi mdi-phone-incoming"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Outgoing calls-->
            <div class="col-xl-3">
                <div class="card card-animate">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <p class="fw-medium text-muted mb-0">Appels sortant - {{ currentMonth }} {{ currentYear }}</p>
                                <h2 class="mt-3 ff-secondary fw-semibold">
                                    <span class="counter-value" :data-target="callsOutgoingCountThisMonth" v-if="!loadingCalls">
                                        {{ callsOutgoingCountThisMonth }}
                                    </span>
                                    <span class="card-text placeholder-glow" v-else>
                                        <span class="placeholder rounded col-3"></span>
                                    </span>
                                </h2>

                                <p class="mb-0 text-muted">
                                    <span class="badge bg-danger-subtle text-danger mb-0" v-if="callsOutgoingPercentageChange <= 0">
                                        <i class="ri-arrow-down-line align-middle"></i> {{ callsOutgoingPercentageChange }} %
                                    </span>
                                    <span class="badge bg-success-subtle text-success mb-0" v-else>
                                        <i class="ri-arrow-up-line align-middle"></i> {{ callsOutgoingPercentageChange }} %
                                    </span>
                                    vs. mois dernier
                                </p>
                            </div>
                            <div>
                                <div class="avatar-sm flex-shrink-0">
                                    <span class="avatar-title bg-secondary-subtle text-secondary rounded-circle fs-2">
                                        <i class="mdi mdi-phone-outgoing"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-6">
                <!--Client requests-->
                <div class="card"  v-if="authUser.role === 'manager'">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1">Dernières demandes clients</h4>
                        <div class="flex-shrink-0">
                            <router-link :to="{ name: 'client-requests.list' }" class="btn btn-soft-dark btn-sm shadow-none">Accéder</router-link>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive table-card">
                            <table class="table table-hover table-nowrap align-middle mb-0">
                                <thead class="table-light">
                                <tr class="text-muted">
                                    <th scope="col">Client</th>
                                    <th scope="col">Centre</th>
                                    <th scope="col">Raison contact</th>
                                    <th scope="col">Statut</th>
                                    <th scope="col">Dernière activité</th>
                                    <th scope="col" class="text-center">Détails</th>
                                </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="clientRequest in clientRequests">
                                        <td>{{ clientRequest.data.client.data.last_name }} {{ clientRequest.data.client.data.first_name }}</td>
                                        <td>{{ clientRequest.data.center.data.name }}</td>
                                        <td>{{ clientRequest.data.contact_reason }}</td>
                                        <td>
                                            <span :class="'badge fs-11 bg-'+clientRequest.data.status_badge_color+'-subtle  text-'+clientRequest.data.status_badge_color">{{ clientRequest.data.status_label_fr }}</span>
                                        </td>
                                        <td>{{ clientRequest.data.updated_at_fr }}</td>
                                        <td class="text-center">
                                            <router-link :to="{ name: 'client-requests.show', params:{ id: clientRequest.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</router-link>
                                        </td>
                                    </tr>
                                    <tr class="text-center font-italic text-muted" v-if="clientRequests.length === 0 && !loadingClientRequests">
                                        <td colspan="6">Aucune demande client...</td>
                                    </tr>
                                    <tr v-if="loadingClientRequests">
                                        <td colspan="6">
                                            <loader></loader>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!--News-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1">Dernières actualités</h4>
                        <div class="flex-shrink-0">
                            <router-link :to="{ name: 'news.list' }" class="btn btn-soft-dark btn-sm shadow-none">Actualités</router-link>
                        </div>
                    </div>
                    <div class="card-body py-1">
                        <ul class="list-group list-group-flush border-dashed">
                            <li  class="list-group-item ps-0 py-0" v-if="loadingNews">
                                <div class="text-center">
                                    <loader></loader>
                                </div>
                            </li>
                            <li class="list-group-item ps-0 py-2" v-for="newsItem in news">
                                <div class="row align-items-center g-3">
                                    <div class="col-auto">
                                        <router-link :to="{ name: 'news.show', params: { id: newsItem.data.id } }">
                                            <img :src="'/format-image/'+newsItem.data.image+'?w=70&h=42&fm=webp&fit=crop'" class="rounded shadow border">
                                        </router-link>
                                    </div>
                                    <div class="col">
                                        <h5 class="text-muted mt-0 mb-1 fs-13">{{ newsItem.data.created_at_fr_human }}</h5>
                                        <router-link :to="{ name: 'news.show', params: { id: newsItem.data.id } }" class="text-reset fs-14 mb-0">{{ newsItem.data.title }}</router-link>
                                    </div>
                                </div>
                            </li>
                            <li  class="list-group-item ps-0 py-2" v-if="news.length === 0">
                                <div class="text-center">
                                    <i class="mdi mdi-newspaper-remove fs-36 text-muted"></i>
                                    <p class="text-muted mb-0 fs-12">Aucune actualité</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6">
                <!--Calls-->
                <div class="card" v-if="authUser.role === 'manager'">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1">Mes appels</h4>
                        <div class="flex-shrink-0">
                            <router-link :to="{ name: 'calls.list' }" class="btn btn-soft-dark btn-sm shadow-none">Appels</router-link>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive table-card">
                            <table class="table table-hover table-nowrap align-middle mb-0">
                                <thead class="table-light">
                                <tr class="text-muted">
                                    <th scope="col">Type</th>
                                    <th scope="col">Numéro</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Durée</th>
                                    <th scope="col" class="text-center">Actions</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr v-for="call in calls">
                                    <td class="text-center">
                                        <span class="badge fs-11 bg-danger-subtle text-danger" v-if="defineCallTypeBadge(call) === 'missed'">Manqué <span v-if="call.data.missed_call_count > 0"> ({{ call.data.missed_call_count }})</span></span>
                                        <span class="badge fs-11 bg-warning-subtle text-warning" v-if="defineCallTypeBadge(call) === 'voicemail'">Messagerie</span>
                                        <span class="badge fs-11 bg-secondary-subtle text-secondary" v-if="defineCallTypeBadge(call) === 'out'">Sortant</span>
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="defineCallTypeBadge(call) === 'in'">Entrant</span>
                                    </td>
                                    <td>
                                        <i class="mdi mdi-phone-outgoing me-2 fs-18 align-middle text-secondary" v-if="defineCallTypeBadge(call) === 'out'"></i>
                                        <i class="mdi mdi-phone-incoming me-2 fs-18 align-middle text-success" v-if="defineCallTypeBadge(call) === 'in'"></i>
                                        <i class="mdi mdi-phone-missed me-2 fs-18 align-middle text-danger" v-if="defineCallTypeBadge(call) === 'missed'"></i>
                                        <i class="mdi mdi-voicemail me-2 fs-18 align-middle text-warning" v-if="defineCallTypeBadge(call) === 'voicemail'"></i>
                                        <span class="fw-semibold text-dark">{{ call.data.client.data.phone_number }}</span>
                                    </td>
                                    <td>
                                        <router-link :to="{ name: 'clients.show', params: { id: call.data.client.data.id }}" class="mr-1 align-middle text-secondary" title="Afficher la fiche client">
                                            {{ call.data.client.data.last_name }} {{ call.data.client.data.first_name }}
                                        </router-link>
                                        <router-link :to="{ name: 'clients.edit', params:{ id: call.data.client.data.id } }" class="text-info align-middle ms-1" title="Modifier le client" v-if="call.data.client.can.update">
                                            <i class="ri-pencil-fill fs-16 px-1 align-middle"></i>
                                        </router-link>
                                    </td>
                                    <td>{{ call.data.start_time_fr }}</td>
                                    <td>{{ call.data.total_duration }}</td>
                                    <td class="text-center">
                                        <!--Call-->
                                        <a :href="'tel:' + call.data.client.data.phone_number.replace(/\s+/g, '')" class="text-info" title="Appeler">
                                            <i class="ri-phone-fill fs-16 px-1"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="calls.length === 0 && !loadingCalls">
                                    <td colspan="6">Aucun appel...</td>
                                </tr>
                                <tr v-if="loadingCalls">
                                    <td colspan="6">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!--Events-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1">Évènements à venir</h4>
                        <div class="flex-shrink-0">
                            <router-link :to="{ name: 'events.list' }" class="btn btn-soft-dark btn-sm shadow-none">Calendrier</router-link>
                        </div>
                    </div>
                    <div class="card-body py-1">
                        <ul class="list-group list-group-flush border-dashed">
                            <li class="list-group-item ps-0 py-2"  v-for="event in upcomingEvents">
                                <div class="row align-items-center g-3">
                                    <div class="col-auto">
                                        <div class="avatar-sm p-1 py-2 h-auto bg-dark rounded-3 shadow">
                                            <div class="text-center">
                                                <h5 class="mb-0 text-white">{{ event.data.day }}</h5>
                                                <div class="text-muted">{{ event.data.month_fr }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <h5 class="text-muted mt-0 mb-1 fs-13">{{ event.data.start_end_date_fr }} - {{ event.data.all_day ? 'Journée entière' : event.data.start_end_hour_fr }}</h5>
                                        <p class="text-reset fs-14 mb-0">{{ event.data.title }}</p>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item ps-0 py-2" v-if="upcomingEvents.length === 0">
                                <div class="text-center">
                                    <i class="mdi mdi-calendar-remove fs-36 text-muted"></i>
                                    <p class="text-muted mb-0 fs-12">Aucun évènement</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
