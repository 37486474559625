<template>
    <div class="mb-3" :class="bsClass">
        <label :for="name" v-if="!labelHidden">
            {{ label }}
            <span class="text-danger" v-if="required"> *</span>
        </label>
        <div class="input-group" :class="errorClassObject()">
            <input
                class="form-control"
                :autocomplete="autocomplete"
                :type="type"
                :class="errorClassObject()"
                :placeholder="placeholder"
                :name="name"
                :required="required"
                @input="updateField()"
                v-model="value"
                :disabled="disabled"
                :id="name"
                :ref="name"
                :tabindex="tabindex"
            >
            <span class="input-group-text" :title="iconDescription">
                <a href="#" @click.prevent="actionIcon()">
                    <i :class="iconClass" class="text-dark"></i>
                </a>
            </span>
        </div>
        <span class="invalid-feedback" v-html="errorMessage()"></span>
        <span class="form-text text-muted" v-if="informationContent !== null">
            {{ informationContent }}
        </span>
    </div>
</template>

<script>
export default {
    props : {
        name : {
            type: String,
            required: true
        },
        label : {
            type: String,
            required: true
        },
        labelHidden : {
            type: Boolean,
            required: false,
            default: false
        },
        required : {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        informationContent : {
            type: String,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        disabled : {
            type: Boolean,
            required: false,
            default: false
        },
        data: {
            required: false,
            default: null
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        },
        type : {
            type: String,
            required: false,
            default: "text"
        },
        autocomplete : {
            type: String,
            required: false,
            default: "off"
        },
        iconClass : {
            type: String,
            required: true
        },
        iconDescription : {
            type: String,
            required: true
        },
        tabindex : {
            type: Number,
            required: false,
            default: -1
        }
    },
    data() {
        return {
            value: null
        };
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            );
        }
    },
    methods: {
        actionIcon() {
            this.$emit("clic:icon", 'clic');
        },
        updateField() {
            this.clearErrors(this.name);
            this.$emit("update:field", this.value);
        },
        errorMessage() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        },
        clearErrors() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject() {
            return {
                "is-invalid": this.hasError
            };
        }
    },
    watch: {
        data: {
            immediate: true,
            handler(newVal, oldVal) {
                return this.value = newVal;
            }
        }
    }
};
</script>

<style scoped>

</style>
