<script setup>
import { onBeforeMount, reactive, ref, watch} from "vue";
import axios from "axios";
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import Loader from "../../Components/Loader.vue";
import FormSelectSearchProduct from "../../Components/FormSelectSearchProduct.vue";
import FormNumericField from "../../Components/FormNumericField.vue";
import {useToast} from "vue-toast-notification";
import Modal from "../../Components/Modal.vue";
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();
const $toast = useToast();

const invoice = ref(null);
const invoiceLines = ref([]);
const loading = ref(true);
const errors = ref(null);
function getInvoice(){
    axios.get("/api/invoices/" + router.currentRoute.value.params.id)
        .then(response => {
            invoice.value = response.data;
            formCreate.invoice_id = response.data.data.id;
            formCreate.tax_rate = response.data.data.tax_rate;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}

function getInvoiceLines(){
    loadingInvoiceLines.value = true;
    axios.get("/api/invoice-lines", { params: { invoice_id: router.currentRoute.value.params.id, sortingField: 'created_at', sortingOrder: 'asc' } })
        .then(response => {
            invoiceLines.value = response.data.data;
            loadingInvoiceLines.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loadingInvoiceLines.value = false;
        });
}
onBeforeMount(() => {
    getInvoice();
    getInvoiceLines();
});

function showModalAddProduct(){
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalAddProduct'))
    modal.show();
}
const loadingInvoiceLines = ref(false);
const formCreate = reactive({
    invoice_id: null,
    product_id: null,
    quantity: 1,
    unit_price_excl_tax: 0,
    unit_price_with_tax: null,
    percentage_discount: null,
    tax_rate: null,
    total_price_with_tax: null,
})
function addProduct(){
    loadingInvoiceLines.value = true;
    axios.post("/api/invoice-lines", formCreate)
        .then(response => {
            getInvoicePrices();
            invoiceLines.value.push(response.data);
            $toast.success('Produit ajouté avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            loadingInvoiceLines.value = false;
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalAddProduct'))
            modal.hide();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loadingInvoiceLines.value = false;
        });
}

const formUpdate = reactive({
    invoice_id: null,
    product_id: null,
    quantity: null,
    unit_price_excl_tax: null,
    unit_price_with_tax: null,
    percentage_discount: null,
    tax_rate: null,
    total_price_with_tax: null,
})
const invoiceLineUpdateId = ref(null);
const invoiceLineUpdateIndex = ref(null);
const productSelectedToUpdate = ref(null);
function showModalEditProduct(invoiceLineId, invoiceLineIndex){
    productSelectedToUpdate.value = {
        data : {
            id: invoiceLines.value[invoiceLineIndex].data.product.data.id,
            name: invoiceLines.value[invoiceLineIndex].data.product.data.product_code + ' - ' + invoiceLines.value[invoiceLineIndex].data.product.data.name,
            price_excluding_tax: invoiceLines.value[invoiceLineIndex].data.unit_price_excl_tax
        }
    };
    invoiceLineUpdateId.value = invoiceLineId;
    invoiceLineUpdateIndex.value = invoiceLineIndex;
    formUpdate.invoice_id = invoice.value.data.id;
    formUpdate.product_id = invoiceLines.value[invoiceLineIndex].data.product.data.id;
    formUpdate.quantity = invoiceLines.value[invoiceLineIndex].data.quantity;
    formUpdate.unit_price_excl_tax = invoiceLines.value[invoiceLineIndex].data.unit_price_excl_tax;
    formUpdate.percentage_discount = invoiceLines.value[invoiceLineIndex].data.percentage_discount;
    formUpdate.tax_rate = invoiceLines.value[invoiceLineIndex].data.tax_rate;
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEditProduct'))
    modal.show();
}
function updateProduct(){
    loadingInvoiceLines.value = true;
    axios.put("/api/invoice-lines/" + invoiceLineUpdateId.value, formUpdate)
        .then(response => {
            getInvoicePrices();
            invoiceLines.value[invoiceLineUpdateIndex.value] = response.data;
            $toast.success('Produit modifié avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            loadingInvoiceLines.value = false;
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEditProduct'))
            modal.hide();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loadingInvoiceLines.value = false;
        });
}

const invoiceLineDeleteId = ref(null);
const invoiceLineDeleteIndex = ref(null);
function showModalDeleteProduct(invoiceLineId, invoiceLineIndex){
    invoiceLineDeleteId.value = invoiceLineId;
    invoiceLineDeleteIndex.value = invoiceLineIndex;
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDeleteProduct'))
    modal.show();
}
function deleteProductFromInvoice(){
    loadingInvoiceLines.value = true;
    axios.delete("/api/invoice-lines/" + invoiceLineDeleteId.value)
        .then(() => {
            getInvoicePrices();
            invoiceLines.value.splice(invoiceLineDeleteIndex.value, 1);
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDeleteProduct'))
            modal.hide();
            $toast.success('Produit supprimé avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            loadingInvoiceLines.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loadingInvoiceLines.value = false;
        });

}

const updatingInvoicePrices = ref(false);
function getInvoicePrices(){
    updatingInvoicePrices.value = true;
    axios.get("/api/invoices/" + invoice.value.data.id + "?price_only=true")
        .then(response => {
            invoice.value.data.total_price_excl_tax = response.data.data.total_price_excl_tax;
            invoice.value.data.total_tax = response.data.data.total_tax;
            invoice.value.da
            invoice.value.data.total_price_with_tax = response.data.data.total_price_with_tax;
            updatingInvoicePrices.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            updatingInvoicePrices.value = false;
        });
}

function roundToTwo(num) {
    return Math.round(num * 100) / 100;
}

const generatingPdf = ref(false);
function generateInvoicePdf(){
    generatingPdf.value = true;
    axios.get("/api/invoices/" + invoice.value.data.id + "/pdf")
        .then(response => {
            window.open(response.data.url, '_blank');
            generatingPdf.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            generatingPdf.value = false;
        });

}

watch(() => [formCreate.unit_price_excl_tax, formCreate.tax_rate, formCreate.quantity, formCreate.percentage_discount], () => {
    if(formCreate.quantity !== null && formCreate.unit_price_excl_tax !== null && formCreate.tax_rate !== null){
        formCreate.unit_price_with_tax = roundToTwo(formCreate.unit_price_excl_tax * (1 + formCreate.tax_rate / 100));
    }
});

watch(() => [formUpdate.unit_price_excl_tax, formUpdate.tax_rate, formUpdate.quantity, formUpdate.percentage_discount], () => {
    if(formUpdate.quantity !== null && formUpdate.unit_price_excl_tax !== null && formUpdate.tax_rate !== null){
        formUpdate.unit_price_with_tax = roundToTwo(formUpdate.unit_price_excl_tax * (1 + formUpdate.tax_rate / 100));
    }
});

const invoiceStatuses = reactive([
    { value: 'draft', label: 'Brouillon', badgeColor: 'secondary' },
    { value: 'waiting_for_payment', label: 'En attente de paiement', badgeColor: 'warning' },
    { value: 'paid', label: 'Payé', badgeColor: 'success' },
    { value: 'canceled', label: 'Annulé', badgeColor: 'dark' },
]);
function changeStatus(status) {
    if (status === invoice.value.data.status) {
        return;
    }
    axios.patch("/api/invoices/" + invoice.value.data.id + "/status", { status: status })
        .then(response => {
            invoice.value.data.status = response.data.data.status;
            invoice.value.data.status_label_fr = response.data.data.status_label_fr;
            invoice.value.data.status_badge_color = response.data.data.status_badge_color;
            $toast.success('Statut mis à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function copyLinkInvoice(){
    const url = window.location.origin + '/external/invoices/' + invoice.value.data.uuid;
    navigator.clipboard.writeText(url);
    $toast.info('Lien copié', {
        position: 'top',
        duration: 5000,
        dismissible: true,
        pauseOnHover: true,
    });
}
</script>

<template>
    <div>
        <div class="col-12" v-show="loading">
            <loader />
        </div>
        <div v-if="!loading" class="row">
            <!--Invoices infos-->
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header align-items-center d-flex border-bottom-0">
                        <h4 class="card-title mb-0 flex-grow-1 align-middle"><i class="mdi mdi-file-sign me-2 align-middle fs-4"></i>Informations générales</h4>
                        <div class="avatar-xs">
                            <router-link :to="{ name: 'invoices.edit', params:{ id: invoice.data.id } }" class="avatar-title bg-secondary-subtle text-secondary fs-15 rounded" title="Modifier">
                                <i class="ri-edit-2-fill"></i>
                            </router-link>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="table-responsive">
                            <table class="table table-small table-nowrap mb-0">
                                <tbody>
                                    <tr>
                                        <th scope="row" class="col-5">Numéro facture</th>
                                        <td class="text-body fw-semibold col-7">{{ invoice.data.invoice_number }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="col-5">Centre</th>
                                        <td class="text-body col-7">{{ invoice.data.center.data.name }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="col-5">Statut</th>
                                        <td class="col-7">
                                            <span :class="'badge fs-11 bg-'+invoice.data.status_badge_color+'-subtle  text-'+invoice.data.status_badge_color">{{ invoice.data.status_label_fr }}</span>
                                            <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" id="statusDropdown" data-bs-toggle="dropdown" v-if="invoice.can.update"></a>
                                            <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                                <li v-for="(status, key) in invoiceStatuses" :key="key">
                                                    <a class="dropdown-item" href="#" @click.prevent="changeStatus(status.value)">
                                                        <span :class="`fs-11 badge bg-${status.badgeColor}-subtle text-${status.badgeColor}`">{{ status.label }}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                            <span class="text-muted ms-1" v-if="invoice.data.status === 'accepted'">{{invoice.data.accepted_at}}</span>
                                            <span class="text-muted ms-1" v-if="invoice.data.status === 'rejected'">{{invoice.data.refused_at}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="col-5">Date facturation - échéance</th>
                                        <td class="text-body col-7">{{ invoice.data.invoice_date_fr }} <span class="text-muted">-</span> {{ invoice.data.due_date_fr }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="col-5 border-bottom-0">Devis</th>
                                        <td class="border-bottom-0 text-body col-7">
                                            <span v-if="invoice.data.quote_id === null">--</span>
                                            <router-link :to="{ name: 'quotes.show', params:{ id: invoice.data.quote_id } }" class="text-secondary " v-else>
                                                {{ invoice.data.quote.data.quote_number }}
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!--Client infos-->
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header align-items-center d-flex border-bottom-0">
                        <h4 class="card-title mb-0 flex-grow-1 align-middle"><i class="mdi mdi-account me-2 align-middle fs-4"></i>Client</h4>
                    </div>
                    <div class="card-body pt-0">
                        <div class="table-responsive">
                            <table class="table table-small table-nowrap mb-0">
                                <tbody>
                                <tr>
                                    <th scope="row" class="col-5">Nom</th>
                                    <td class="text-body col-7">
                                        <router-link :to="{ name: 'clients.show', params:{ id: invoice.data.client.data.id } }" class="text-secondary">
                                            {{ invoice.data.client.data.first_name }} {{ invoice.data.client.data.last_name }}
                                        </router-link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="col-5">Type</th>
                                    <td class="text-body col-7">
                                        <span :class="'badge fs-11 bg-'+invoice.data.client.data.client_status_badge_color+'-subtle  text-'+invoice.data.client.data.client_status_badge_color">{{ invoice.data.client.data.client_status_label_fr }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="col-5">Téléphone</th>
                                    <td class="text-body col-7">{{ invoice.data.client.data.phone_number }}</td>
                                </tr>
                                <tr>
                                    <th scope="row" class="col-5">Email</th>
                                    <td class="col-7">{{ invoice.data.client.data.email }}</td>
                                </tr>
                                <tr>
                                    <th scope="row" class="col-5 border-bottom-0">Véhicule</th>
                                    <td class="border-bottom-0 text-body col-7">
                                        <span v-if="invoice.data.client.data.engine !== null">
                                            <router-link :to="{ name: 'chiptuning.show', params:{ id: invoice.data.client.data.engine.id } }" class="text-secondary">
                                                {{ invoice.data.client.data.engine.data.brand.data.name }} {{ invoice.data.client.data.engine.data.model.data.name }}
                                            </router-link>
                                        </span>
                                        <span v-else>--</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Invoices lines -->
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header d-flex flex-column flex-md-row align-items-start align-items-md-center">
                        <h4 class="card-title mb-0 flex-grow-1">
                            <i class="mdi mdi-format-list-text me-2 align-middle fs-4"></i>Détails facture
                        </h4>
                        <div class="mt-3 mt-md-0 d-flex flex-wrap">
                            <!--Button copy link-->
                            <!--<button type="button" class="btn btn-info btn-sm shadow-none me-1 align-middle" @click.prevent="copyLinkInvoice">-->
                            <!--    <i class="ri-file-copy-2-fill align-middle me-1"></i> Copier lien-->
                            <!--</button>-->
                            <button type="button" class="btn btn-soft-dark btn-sm shadow-none me-1 align-middle" @click.prevent="generateInvoicePdf">
                                <i class="ri-file-pdf-fill align-middle me-1" v-if="!generatingPdf"></i>
                                <i class="spinner-border spinner-border-xs me-1" role="status" v-else></i> Générer PDF
                            </button>
                            <button type="button" class="btn btn-secondary btn-sm shadow-none" @click.prevent="showModalAddProduct">
                                <i class="ri-add-circle-line align-middle me-1"></i> Ajouter produit
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive table-card mb-3">
                            <table class="table table-small mb-0 align-middle table-hover table-nowrap table-bordered">
                                <thead class="table-light">
                                    <tr>
                                        <th class="col-1">Code produit</th>
                                        <th class="col-1">Type</th>
                                        <th class="col-4" scope="col">Libellé produit</th>
                                        <th class="col-1 text-end" scope="col">TVA</th>
                                        <th class="col-1 text-end" scope="col">P.U. HT</th>
                                        <th class="col-1 text-center" scope="col">Quantité</th>
                                        <th class="col-1 text-end" scope="col">Réduction</th>
                                        <th class="col-1 text-end" scope="col">Total HT</th>
                                        <th class="col-1 text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                    <tr v-for="(invoiceLine, index) in invoiceLines" :key="index" v-show="!loadingInvoiceLines">
                                        <td class="fw-medium text-dark">
                                            {{ invoiceLine.data.product.data.product_code }}
                                        </td>
                                        <td>
                                            <span :class="'badge fs-11 bg-'+invoiceLine.data.product.data.type_badge_color+'-subtle  text-'+invoiceLine.data.product.data.type_badge_color">{{ invoiceLine.data.product.data.type_label_fr }}</span>
                                        </td>
                                        <td class="description-cell">
                                            {{ invoiceLine.data.product.data.name }}
                                            <div v-if="invoiceLine.data.product.data.description" class="text-black-50">
                                                <br><span v-html="invoiceLine.data.product.data.description.replace(/\n/g, '<br />')"></span>
                                            </div>
                                        </td>
                                        <td class="text-end">{{ invoiceLine.data.tax_rate.toPrecision() }}%</td>
                                        <td class="text-end">{{ invoiceLine.data.unit_price_excl_tax.toLocaleString('fr-FR', { style: 'currency', currency: invoice.data.currency }) }}</td>
                                        <td class="text-center">{{ invoiceLine.data.quantity }}</td>
                                        <td class="text-end">{{ invoiceLine.data.percentage_discount > 0 ? `${invoiceLine.data.percentage_discount}%` : '--' }}</td>
                                        <td class="text-end">{{ invoiceLine.data.total_price_excl_tax.toLocaleString('fr-FR', { style: 'currency', currency: invoice.data.currency }) }}</td>
                                        <td class="text-center">
                                            <!--Update-->
                                            <a href="#" class="text-info" title="Modifier le produit" @click.prevent="showModalEditProduct(invoiceLine.data.id, index)" v-if="invoiceLine.can.update">
                                                <i class="ri-pencil-fill fs-17 px-1"></i>
                                            </a>
                                            <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                            <!--Delete-->
                                            <a href="#" class="text-danger" title="Supprimer le produit" @click.prevent="showModalDeleteProduct(invoiceLine.data.id, index)" v-if="invoiceLine.can.delete">
                                                <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                            </a>
                                            <i class="ri-delete-bin-3-fill fs-17 px-1 text-muted" v-else></i>
                                        </td>
                                    </tr>
                                    <tr class="text-center font-italic text-muted" v-if="invoiceLines.length === 0 && !loadingInvoiceLines">
                                        <td colspan="9">Aucun produit...</td>
                                    </tr>
                                    <!--Total HT -->
                                    <tr class="align-middle" v-show="!loadingInvoiceLines">
                                        <td class="text-end fw-medium" colspan="7"><span class="me-1">TOTAL HT</span></td>
                                        <td class="text-end border-end-0 fw-medium">
                                            <span class="card-text placeholder-glow" v-if="updatingInvoicePrices">
                                                <span class="placeholder rounded col-3"></span>
                                            </span>
                                            <span v-else>
                                                {{ invoice.data.total_price_excl_tax.toLocaleString('fr-FR', { style: 'currency', currency: invoice.data.currency }) }}
                                            </span>
                                        </td>
                                        <td class="border-start-0"></td>
                                    </tr>
                                    <!--Total TVA -->
                                    <tr class="align-middle" v-show="!loadingInvoiceLines">
                                        <td class="text-end text-muted" colspan="7"><span class="me-1">TOTAL TVA</span></td>
                                        <td class="text-muted text-end border-end-0">
                                            <span class="card-text placeholder-glow" v-if="updatingInvoicePrices">
                                                <span class="placeholder rounded col-3"></span>
                                            </span>
                                            <span v-else>
                                                {{ invoice.data.total_tax.toLocaleString('fr-FR', { style: 'currency', currency: invoice.data.currency }) }}
                                            </span>
                                        </td>
                                        <td class="border-start-0"></td>
                                    </tr>
                                    <!--Total TTC -->
                                    <tr class="align-middle" v-show="!loadingInvoiceLines">
                                        <td class="text-end fw-bold" colspan="7"><span class="me-1">TOTAL TTC</span></td>
                                        <td class="fw-bold text-end border-end-0">
                                            <span class="card-text placeholder-glow" v-if="updatingInvoicePrices">
                                                <span class="placeholder rounded col-3"></span>
                                            </span>
                                            <span v-else>
                                                {{ invoice.data.total_price_with_tax.toLocaleString('fr-FR', { style: 'currency', currency: invoice.data.currency }) }}
                                            </span>
                                        </td>
                                        <td class="border-start-0"></td>
                                    </tr>
                                    <tr v-if="loadingInvoiceLines">
                                        <td colspan="9">
                                            <loader></loader>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p class="mb-0 col-8 fs-11" v-html="invoice.data.comment !== null ? invoice.data.comment.replace(/\n/g, '<br />') : null"></p>
                    </div>
                </div>
                <!-- Modal add product -->
                <div id="modalAddProduct" class="modal fade" tabindex="-1" aria-labelledby="modalAddProduct" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <form v-on:submit.prevent="addProduct">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalAddProduct">Ajouter un produit</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                                </div>
                                <div class="modal-body row">
                                    <form-select-search-product
                                        :errors="errors"
                                        label="Produit / Service"
                                        name="product_id"
                                        :required="true"
                                        :data="formCreate.product_id"
                                        @update:field="formCreate.product_id = ($event !== null ? $event.id : null)"
                                        @update:price="formCreate.unit_price_excl_tax = ($event !== null ? $event.price_excluding_tax : null)"
                                        bs-class="col-md-12"
                                        :disabled="false"
                                        :taggable="false"
                                        :label-hidden="false"
                                        placeholder="Sélectionnez un produit"
                                    />

                                    <form-numeric-field
                                        name="quantity"
                                        label="Quantité"
                                        :label-hidden="false"
                                        @update:field="formCreate.quantity = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formCreate.quantity"
                                        bs-class="col-md-4"
                                        :min="1"
                                        :step="1"
                                    />

                                    <form-numeric-field
                                        name="unit_price_excl_tax"
                                        label="Prix unitaire HT"
                                        :label-hidden="false"
                                        @update:field="formCreate.unit_price_excl_tax = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formCreate.unit_price_excl_tax"
                                        bs-class="col-md-4"
                                        :min="0"
                                        :step="0.01"
                                    />

                                    <form-numeric-field
                                        name="unit_price_with_tax"
                                        label="Prix unitaire TTC"
                                        :label-hidden="false"
                                        @update:field="formCreate.unit_price_with_tax = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formCreate.unit_price_with_tax"
                                        bs-class="col-md-4"
                                        :disabled="true"
                                        :min="0"
                                        :step="0.01"
                                    />

                                    <form-numeric-field
                                        name="percentage_discount"
                                        label="Remise en %"
                                        :label-hidden="false"
                                        @update:field="formCreate.percentage_discount = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formCreate.percentage_discount"
                                        bs-class="col-md-6"
                                        :min="0"
                                        information-content="Laisser à 0 ou vide si pas de réduction"
                                    />

                                    <form-numeric-field
                                        name="tax_rate"
                                        label="TVA en %"
                                        :label-hidden="false"
                                        @update:field="formCreate.tax_rate = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formCreate.tax_rate"
                                        bs-class="col-md-6"
                                        :min="0"
                                        :step="0.1"
                                        information-content="Taux de TVA basé sur la facture"
                                    />

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                                    <button type="submit" class="btn btn-secondary">Valider</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- Modal edit product -->
                <div id="modalEditProduct" class="modal fade" tabindex="-1" aria-labelledby="modalEditProduct" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <form v-on:submit.prevent="updateProduct">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalEditProduct">Modifier un produit</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                                </div>
                                <div class="modal-body row">
                                    <form-select-search-product
                                        :errors="errors"
                                        label="Produit / Service"
                                        name="product_id"
                                        :required="true"
                                        :data="formUpdate.product_id"
                                        @update:field="formUpdate.product_id = ($event !== null ? $event.id : null)"
                                        @update:price="formUpdate.unit_price_excl_tax = ($event !== null ? $event.price_excluding_tax : null)"
                                        bs-class="col-md-12"
                                        :disabled="false"
                                        :taggable="false"
                                        :label-hidden="false"
                                        placeholder="Sélectionnez un produit"
                                        :product-pre-selected="productSelectedToUpdate"
                                    />

                                    <form-numeric-field
                                        name="quantity"
                                        label="Quantité"
                                        :label-hidden="false"
                                        @update:field="formUpdate.quantity = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formUpdate.quantity"
                                        bs-class="col-md-4"
                                        :min="1"
                                        :step="1"
                                    />

                                    <form-numeric-field
                                        name="unit_price_excl_tax"
                                        label="Prix unitaire HT"
                                        :label-hidden="false"
                                        @update:field="formUpdate.unit_price_excl_tax = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formUpdate.unit_price_excl_tax"
                                        bs-class="col-md-4"
                                        :min="0"
                                        :step="0.01"
                                    />

                                    <form-numeric-field
                                        name="unit_price_with_tax"
                                        label="Prix unitaire TTC"
                                        :label-hidden="false"
                                        @update:field="formUpdate.unit_price_with_tax = $event"
                                        :errors="errors"
                                        :required="false"
                                        :disabled="true"
                                        :data="formUpdate.unit_price_with_tax"
                                        bs-class="col-md-4"
                                        :min="0"
                                        :step="0.01"
                                    />

                                    <form-numeric-field
                                        name="percentage_discount"
                                        label="Remise en %"
                                        :label-hidden="false"
                                        @update:field="formUpdate.percentage_discount = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formUpdate.percentage_discount"
                                        bs-class="col-md-6"
                                        :min="0"
                                        information-content="Laisser à 0 ou vide si pas de réduction"
                                    />

                                    <form-numeric-field
                                        name="tax_rate"
                                        label="TVA en %"
                                        :label-hidden="false"
                                        @update:field="formUpdate.tax_rate = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formUpdate.tax_rate"
                                        bs-class="col-md-6"
                                        :min="0"
                                        :step="0.1"
                                        information-content="Taux de TVA basé sur la facture"
                                    />

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                                    <button type="submit" class="btn btn-secondary">Valider</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!--Modal delete product-->
                <modal id="modalDeleteProduct" title="Supprimer le produit ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteProductFromInvoice">
                    <template v-slot:icon>
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                    </template>
                    <template v-slot:content>
                        <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer un produit de la facture. Continuer ?</p>
                    </template>
                </modal>
            </div>
        </div>
    </div>
</template>

<style scoped>
.description-cell {
    min-width: 500px !important;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
</style>
