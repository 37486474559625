import UserShow from "../Views/User/UserShow.vue";
import SurveyList from "../Views/Survey/SurveyList.vue";
import SurveyEdit from "../Views/Survey/SurveyEdit.vue";
import SurveyCreate from "../Views/Survey/SurveyCreate.vue";
import {useAuthUserStore} from "../Store/AuthUserStore.js";
import {computed} from "vue";

export const surveyRoutes = [
    {
        path: "/surveys/edit/:id",
        name: "surveys.edit",
        component: SurveyEdit,
        beforeEnter: (to, from, next) => {
            const authUserStore = useAuthUserStore();
            const authUser = computed(() => authUserStore.authUser);
            if(authUser.value.role !== 'admin' && authUser.value.role !== 'back_office' ) {
                next('/unauthorized');
            } else {
                next();
            }
        },
        meta: {
            menu: {
                parent: 'surveys',
                child: null
            },
            breadcrumb: {
                title: "Sondages",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Tous les sondages",
                            link: {name: 'surveys.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/surveys/create",
        name: "surveys.create",
        component: SurveyCreate,
        beforeEnter: (to, from, next) => {
            const authUserStore = useAuthUserStore();
            const authUser = computed(() => authUserStore.authUser);
            if(authUser.value.role !== 'admin' && authUser.value.role !== 'back_office' ) {
                next('/unauthorized');
            } else {
                next();
            }
        },
        meta: {
            menu: {
                parent: 'surveys',
                child: null
            },
            breadcrumb: {
                title: "Sondages",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Tous les sondages",
                            link: {name: 'surveys.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/surveys",
        name: "surveys.list",
        component: SurveyList,
        meta: {
            menu: {
                parent: 'surveys',
                child: null
            },
            breadcrumb: {
                title: "Sondages",
                nav: {
                    all: {
                        active: "Tous les sondages",
                        inactive: []
                    }
                }
            }
        },
    }
];
