<template>
    <dashboard-admin v-if="authUser.role === 'admin' || authUser.role === 'back_office'"/>
    <dashboard-manager-and-staff v-if="authUser.role === 'manager' || authUser.role === 'staff'"/>
</template>

<script>
import {mapState} from "pinia";
import {useAuthUserStore} from "../../Store/AuthUserStore";
import DashboardAdmin from "./DashboardAdminAndBackOffice.vue";
import DashboardManagerAndStaff from "./DashboardManagerAndStaff.vue";
export default {
    name: "Dashboard",
    components: {
        DashboardManagerAndStaff,
        DashboardAdmin
    },
    computed: {
        ...mapState(useAuthUserStore, ['authUser'])
    }
}
</script>

<style scoped>

</style>
