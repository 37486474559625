<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import {useToast} from "vue-toast-notification";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');
const sortingField = ref('updated_at');
const sortingFieldsOrder = reactive({
    id: 'asc',
    updated_at: 'asc',
});
watch(url, (newValue) => {
    localStorage.setItem('informationRequestsListUrl', url.value);
    localStorage.setItem('informationRequestsSortingField', sortingField.value);
    localStorage.setItem('informationRequestsSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('informationRequestsSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('informationRequestsQuery', query.value);
    localStorage.setItem('informationRequestsFiltersApplied', filtersApplied.value.toString());
});
const isInitialized = ref(false);

onBeforeMount(() => {
    loadSettingsFromLocalStorage();
    loadData(url.value);
});

function loadSettingsFromLocalStorage() {
    const hasStoredSettings = localStorage.getItem('informationRequestsListUrl') != null;
    if (hasStoredSettings) {
        loadStoredSettings();
    } else {
        setDefaultSettings();
    }
}

function loadStoredSettings() {
    url.value = localStorage.getItem('informationRequestsListUrl');
    sortingField.value = localStorage.getItem('informationRequestsSortingField');
    sortingFieldsOrder[sortingField.value] = localStorage.getItem('informationRequestsSortingFieldsOrder');
    searchLoaded.value = localStorage.getItem('informationRequestsSearchLoaded') === 'true';
    query.value = localStorage.getItem('informationRequestsQuery') ?? '';
    filtersApplied.value = localStorage.getItem('informationRequestsFiltersApplied') === 'true';

    const savedFilters = localStorage.getItem('informationRequestsFilters');
    if (savedFilters) {
        Object.assign(filters, JSON.parse(savedFilters));
    }
}

function setDefaultSettings() {
    url.value = '/api/information-requests?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
}

onMounted(() => {
    isInitialized.value = true;
})

//Get data
const loading = ref(true);
const informationRequests = ref([]);
function loadData(urlToFetch) {
    informationRequests.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if (searchLoaded.value) {
                return performSearch(urlToFetch);
            }
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const searchLoaded = ref(false);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response, mustSave = true) {
    informationRequests.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
}

//Ordering table
function changeOrdering(sortingValue) {
    sortingField.value = sortingValue;
    sortingFieldsOrder[sortingField.value] = sortingFieldsOrder[sortingField.value] === 'asc' ? 'desc' : 'asc';

    url.value = searchLoaded.value === true
        ? '/api/search/information-requests?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        : '/api/information-requests?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];

    let newUrl = new URL(url.value, window.location.origin);
    // Update filters
    filters.user
        ? newUrl.searchParams.set('user', filters.user)
        : newUrl.searchParams.delete('user');

    filters.status
        ? newUrl.searchParams.set('status', filters.status)
        : newUrl.searchParams.delete('status');

    url.value = newUrl.href;
    loadData(url.value);
}
function activeSorting(sortingValue){
    return sortingField.value === sortingValue ? 'text-secondary' : 'text-dark';
}

//Searching
const query = ref('');
const search = _.debounce(async () => {
    if (query.value.length > 2) {
        await performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('informationRequestsQuery', query.value);
}, 250);
const performSearch = async (urlPerformSearch = null) => {
    informationRequests.value = [];
    loading.value = true;
    let searchUrl = '/api/search/information-requests?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }
    let newUrl = new URL(searchUrl, window.location.origin);
    // Update filters
    filters.user
        ? newUrl.searchParams.set('user', filters.user)
        : newUrl.searchParams.delete('user');

    filters.status
        ? newUrl.searchParams.set('status', filters.status)
        : newUrl.searchParams.delete('status');

    axios.get(newUrl.href)
        .then(response => {
            paginationAndDataMapping(response.data, false);
            url.value = searchUrl
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
};
const clearSearch = () => {
    informationRequests.value = [];
    let newUrl = '/api/information-requests?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    if (filters.user) {
        newUrl += '&user=' + filters.user;
    }
    if (filters.status) {
        newUrl += '&status=' + filters.status;
    }

    loadData(newUrl);
    searchLoaded.value = false;
};

const modaleDeleteShown = ref(false);
const idToDelete = ref(null);
const indexToDelete = ref(null);
function showModalDelete(id, index) {
    idToDelete.value = id;
    indexToDelete.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDelete'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function deleteClientRequest() {
    axios.delete('/api/information-requests/' + idToDelete.value)
        .then(response => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    if (modaleDeleteShown.value) {
        const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
        modaleDelete.hide()
    }
})

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const errors = ref(null);
const filters = reactive( {
    user: null,
    status: null,
})
const filtersApplied = ref(true);

function applyFilter(){

    let newUrl = new URL(url.value, window.location.origin);

    filters.user
        ? newUrl.searchParams.set('user', filters.user)
        : newUrl.searchParams.delete('user');

    filters.status
        ? newUrl.searchParams.set('status', filters.status)
        : newUrl.searchParams.delete('status');

    filtersApplied.value = true;

    localStorage.setItem('informationRequestsFilters', JSON.stringify(filters));
    localStorage.setItem('informationRequestsFiltersApplied', filtersApplied.value.toString());

    loadData(newUrl.href);
}

watch(
    () => [filters.user, filters.status],
    (newValues, oldValues) => {
        if (isInitialized.value) {
            filtersApplied.value = false;
        }
    },
    { deep: true }
);

const centers = ref([]);
onBeforeMount(() => {
    getCenters();
})
function getCenters(){
    centers.value.push({
        text: 'Tous les centres',
        value: null
    })
    axios.get('/api/centers?sortingField=name&sortingOrder=asc&all==true')
        .then(response => {
            response.data.data.forEach(center => {
                centers.value.push({
                    text: center.data.name,
                    value: center.data.id
                })
            })
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header border border-dashed border-top-0 border-start-0 border-end-0">
                    <div class="d-flex align-items-center">
                        <h5 class="card-title mb-0 flex-grow-1">Toutes les demandes d'informations</h5>
                        <router-link :to="{ name:'information-requests.create' }" class="btn btn-secondary" v-if="authUser.can && authUser.can.create_client_request">
                            <i class="ri-add-fill me-1 align-bottom hide-on-mobile"></i> Ajouter
                        </router-link>
                    </div>
                </div>
                <!--Filters-->
                <div  class="card-body">
                    <div class="row g-3">
                        <div class="col-xxl-5">
                            <div class="search-box">
                                <input type="search" class="form-control search" placeholder="Rechercher..."  v-model="query" @input="search" id="searchTickets">
                                <i class="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        <form-select-field
                            name="filters.status"
                            label="Statuts"
                            :label-hidden="true"
                            @update:field="filters.status = $event"
                            :errors="errors"
                            :required="true"
                            :data="filters.status"
                            bs-class="col-xxl-3 col-sm-4"
                            :disabled="false"
                            additional-class="bg-light border-light"
                            placeholder="Tous les statuts"
                            :liste-options="[
                                { text: 'Statuts ouverts (A traiter, En cours)', value: 'all_open' },
                                { text: 'Statuts fermés', value: 'all_closed' },
                                { text: 'Tous les statuts', value: null },
                                { text: '---', value: null },
                                { text: 'A traiter', value: 'pending' },
                                { text: 'En cours', value: 'in_progress' },
                                { text: 'Fermé', value: 'closed' },
                                ]"
                            margin="mb-0"
                        />

                        <form-select-field
                            name="filters.user"
                            label="User"
                            :label-hidden="true"
                            @update:field="filters.user = $event"
                            :errors="errors"
                            :required="true"
                            :data="filters.user"
                            bs-class="col-xxl-3 col-sm-4"
                            :disabled="false"
                            additional-class="bg-light border-light"
                            placeholder="Toutes les demandes"
                            :liste-options="[
                                { text: 'Mes demandes', value: 'my_tickets' },
                                { text: 'Demandes qui me sont assignés', value: 'assigned_to_me' },
                                { text: 'Demandes non assignés', value: 'unassigned' },
                                { text: 'Toutes les demandes', value: null },
                                ]"
                            margin="mb-0"
                        />

                        <div class="col-xxl-1 col-sm-2">
                            <button type="button" class="btn w-100" @click.prevent="applyFilter" :class="filtersApplied ? 'btn-dark' : 'btn-warning'">
                                <i class="ri-equalizer-fill me-1 align-bottom hide-on-mobile"></i>
                                Filtrer
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                    <tr>
                                        <th class="col-1 sort">
                                            <a href="#" @click.prevent="changeOrdering('id')" :class="activeSorting('id')" class="fw-bold pe-4">#ID</a>
                                        </th>
                                        <th class="col-1" scope="col">Créé par</th>
                                        <th class="col-2" scope="col">Véhicule</th>
                                        <th class="col-2" scope="col">Immatriculation</th>
                                        <th class="col-1" scope="col">Statut</th>
                                        <th class="col-1" scope="col">Assigné à</th>
                                        <th class="col-1 sort">
                                            <a href="#" @click.prevent="changeOrdering('updated_at')" :class="activeSorting('updated_at')" class="pe-4">Dernière MAJ</a>
                                        </th>
                                        <th class="col-1 text-center" scope="col">Notifications</th>
                                        <th class="col-1 text-center" scope="col">Détails</th>
                                        <th class="col-1 text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(informationRequest, index) in informationRequests" :key="index">
                                    <td class="fw-medium text-dark">#{{ informationRequest.data.id }}</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0"><img :src="'/format-image/'+informationRequest.data.user.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" alt="" class="avatar-xs rounded-circle"></div>
                                            <div class="flex-grow-1 ms-2 name">{{ informationRequest.data.user.data.last_name }} {{ informationRequest.data.user.data.first_name }}</div>
                                        </div>
                                    </td>
                                    <td>{{ informationRequest.data.car_brand }} - {{ informationRequest.data.car_model }}</td>
                                    <td>{{ informationRequest.data.license_plate }}</td>
                                    <td>
                                        <span :class="'badge fs-11 bg-'+informationRequest.data.status_badge_color+'-subtle  text-'+informationRequest.data.status_badge_color">{{ informationRequest.data.status_label_fr }}</span>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center" v-if="informationRequest.data.assigned_to !== null">
                                            <div class="flex-shrink-0"><img :src="'/format-image/'+informationRequest.data.assigned_to.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" alt="" class="avatar-xs rounded-circle"></div>
                                            <div class="flex-grow-1 ms-2 name">{{ informationRequest.data.assigned_to.data.last_name }} {{ informationRequest.data.assigned_to.data.first_name }}</div>
                                        </div>
                                        <span v-else>--</span>
                                    </td>
                                    <td>{{ informationRequest.data.updated_at_fr }}</td>
                                    <td class="text-center">
                                        <div v-if="authUser.id === informationRequest.data.user_id">
                                            <span v-if="informationRequest.data.notifications_user > 0" class="badge rounded-pill bg-danger fs-11">{{ informationRequest.data.notifications_user }}</span>
                                        </div>
                                        <div v-else-if="authUser.id === informationRequest.data.assigned_to_id">
                                            <span v-if="informationRequest.data.notifications_assigned > 0" class="badge rounded-pill bg-danger fs-11">{{ informationRequest.data.notifications_assigned }}</span>
                                        </div>
                                        <div v-else-if="informationRequest.data.assigned_to_id === null && (authUser.role === 'admin' || authUser.role === 'back_office')">
                                            <span v-if="informationRequest.data.notifications_bo > 0" class="badge rounded-pill bg-danger fs-11">{{ informationRequest.data.notifications_bo }}</span>
                                        </div>
                                        <div v-else>
                                            <span v-if="informationRequest.data.notifications_assigned > 0" class="badge rounded-pill bg-danger fs-11">{{ informationRequest.data.notifications_assigned }}</span>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <router-link :to="{ name: 'information-requests.show', params:{ id: informationRequest.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</router-link>
                                    </td>
                                    <td class="text-center">
                                        <!--UPDATE-->
                                        <router-link :to="{ name: 'information-requests.edit', params:{ id: informationRequest.data.id } }" class="text-info" title="Modifier la demande" v-if="informationRequest.can.update">
                                            <i class="ri-pencil-fill fs-17 px-1"></i>
                                        </router-link>
                                        <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                        <!--Delete-->
                                        <a href="#" class="text-danger" title="Supprimer la demande" @click.prevent="showModalDelete(informationRequest.data.id, index)" v-if="informationRequest.can.delete">
                                            <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-delete-bin-3-fill fs-17 px-1 text-muted" v-else></i>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="informationRequests.length === 0 && !loading">
                                    <td colspan="11" v-if="searchLoaded">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                        <p class="mt-2 h5">Aucun résultat</p>
                                        <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                    </td>
                                    <td colspan="11" v-else>Aucune demande d'informations...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="11">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                        ></pagination>
                    </div>

                    <modal id="modalDelete" title="Supprimer la demande d'information ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteClientRequest">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer une demande d'informations. Confirmer la suppression ?</p>
                        </template>
                    </modal>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
