<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import {useRouter} from "vue-router";
import {computed, onBeforeMount, reactive, ref, watch} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import FormSwitch from "../../Components/FormSwitch.vue";
import FormDatePicker from "../../Components/FormDatePicker.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import FormDateTimePicker from "../../Components/FormDateTimePicker.vue";
import FormSelectCenter from "../../Components/FormSelectCenter.vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import axios from "axios";
import FormInputFilePond from "../../Components/FormInputFilePond.vue";

const { httpErrorsHandler } = useHttpErrorsHandler();
const $toast = useToast();
const router = useRouter();
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const loading = ref(true)
const form = reactive( {
    title: null,
    description: null,
    start_date: null,
    end_date: null,
    location: null,
    all_day: false,
    center_id: null,
    attachments: [],
    _method: 'PUT'
})
const errors = ref(null)
function updateEvent() {
    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (key === 'centers') {
            return value.forEach((center) => {
                formData.append(`${key}[]`, center.id);
            });
        }
        if (key === 'attachments') {
            return Array.from(value).forEach(attachment => {
                formData.append(`${key}[]`, attachment);
            });
        }
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (value !== null && value !== '') {
            if (form.all_day && (key === 'start_date' || key === 'end_date')){
                value = value.split('T')[0];
            }
            formData.append(key, value);
        }
    });
    axios.post("/api/events/" + router.currentRoute.value.params.id, formData)
        .then(() => {
            $toast.success('Événement modifié avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.back();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}
const centerPreSelected = ref([])
const event = ref(null)
function getEvent() {
    loading.value = true;
    axios.get("/api/events/" + router.currentRoute.value.params.id)
        .then(response => {
            Object.entries(response.data.data).forEach(([key, value]) => {
                if (key === 'center') {
                    centerPreSelected.value = value;
                    return;
                }
                if(form.hasOwnProperty(key) && key !== 'attachments'){
                    form[key] = value;
                }
                form.start_date = response.data.data.start_date_en
                form.end_date = response.data.data.end_date_en
            });
            event.value = response.data;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onBeforeMount(() => {
    getEvent()
})

watch(() => form.start_date, (newStartDate) => {
    if (!newStartDate) return;
    const startDate = parseDate(newStartDate);
    let endDate = form.end_date ? parseDate(form.end_date) : null;

    if (!endDate || startDate > endDate) {
        endDate = new Date(startDate.getTime());

        if (form.all_day) {
            endDate.setDate(startDate.getDate() + 1);
        } else {
            endDate.setHours(startDate.getHours() + 1);
        }

        form.end_date = formatDate(endDate, !form.all_day);
    }
});


watch(() => form.end_date, (newEndDate) => {
    if (!newEndDate) return;
    const startDate = parseDate(form.start_date);
    const endDate = parseDate(newEndDate);

    if (startDate > endDate) {
        form.end_date = formatDate(startDate, !form.all_day);
        $toast.warning('La date de fin ne peut pas être antérieure à la date de début.', {
            position: 'top',
            duration: 5000,
            dismissible: true,
            pauseOnHover: true,
        });
    }
});

function parseDate(dateStr) {
    const parts = dateStr.split(' ');
    const datePart = parts[0];
    const timePart = parts.length > 1 ? parts[1] : null;

    const [year, month, day] = datePart.split('-');
    if (timePart) {
        const [hours, minutes] = timePart.split(':');
        return new Date(year, month - 1, day, hours, minutes);
    } else {
        return new Date(year, month - 1, day);
    }
}


function formatDate(date, includeTime = true) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    if (includeTime) {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`; // Retirer le `-` entre la date et l'heure
    } else {
        return `${year}-${month}-${day}`;
    }
}
function deleteAttachment(id, index){
    axios.delete("/api/attachments/" + id)
        .then(() => {
            event.value.data.attachments.splice(index, 1);
            $toast.success('Fichier supprimé avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <form v-on:submit.prevent="updateEvent">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1">
                            <i class="mdi mdi-calendar me-2 align-middle fs-4"></i>Modifier un évènement
                        </h4>
                    </div>
                    <div class="card-body">
                        <loader v-show="loading"/>
                        <div class="row" v-show="!loading">
                            <form-text-field
                                name="title"
                                label="Titre de l'évènement"
                                :label-hidden="false"
                                @update:field="form.title = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.title"
                                bs-class="col-md-12"
                            />

                            <form-textarea
                                name="description"
                                label="Description"
                                :label-hidden="false"
                                @update:field="form.description = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.description"
                                bs-class="col-lmd-12"
                                :row="3"
                            />

                            <form-switch
                                name="all_day"
                                label="Toute la journée"
                                :label-hidden="false"
                                @update:field="form.all_day = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.all_day"
                                bs-class="col-md-12"
                                :disabled="false"
                            />

                            <form-date-picker
                                v-if="form.all_day"
                                name="start_date"
                                label="Date début"
                                :label-hidden="false"
                                @update:field="form.start_date = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.start_date"
                                bs-class="col-md-6"
                                :disabled="false"
                            />

                            <form-date-time-picker
                                v-if="!form.all_day"
                                name="start_date"
                                label="Date / heure début"
                                :label-hidden="false"
                                @update:field="form.start_date = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.start_date"
                                bs-class="col-md-6"
                                :disabled="false"
                            />

                            <form-date-picker
                                v-if="form.all_day"
                                name="end_date"
                                label="Date fin"
                                :label-hidden="false"
                                @update:field="form.end_date = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.end_date"
                                bs-class="col-md-6"
                                :disabled="false"
                            />

                            <form-date-time-picker
                                v-if="!form.all_day"
                                name="end_date"
                                label="Date / heure fin"
                                :label-hidden="false"
                                @update:field="form.end_date = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.end_date"
                                bs-class="col-md-6"
                                :disabled="false"
                            />

                            <form-text-field
                                name="location"
                                label="Lieux de l'évènement"
                                :label-hidden="false"
                                @update:field="form.location = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.location"
                                bs-class="col-md-6"
                            />

                            <form-select-center
                                :errors="errors"
                                label="Centre"
                                name="center_id"
                                :required="(!(authUser.role === 'admin' || authUser.role === 'back_office'))"
                                :data="form.center_id"
                                @update:field="form.center_id = ($event !== null ? $event.id : null)"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                :centers-pre-selected="null"
                                placeholder="Sélectionnez un centre"
                                :information-content="(authUser.role === 'admin' || authUser.role === 'back_office') ? 'Si vous ne sélectionnez pas de centre, l\'évènement sera visible pour tous les centres.' : ''"
                                :center-pre-selected="centerPreSelected"
                            />

                        </div>
                    </div>
                </div>

                <div class="card" v-show="!loading">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-attachment me-2 align-middle fs-4"></i>Fichiers joints</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <form-input-file-pond
                                name="form.attachments"
                                label="Fichiers joints"
                                :label-hidden="false"
                                :errors="errors"
                                :required="false"
                                :data="form.attachments"
                                @update:field="form.attachments = $event"
                                bs-class="col-md-12"
                                :disabled="false"
                                :multiple="true"
                            />

                            <div v-if="event !== null && event.data.attachments.length > 0" class="mb-3">
                                <label class="label form-label">Fichiers existants</label>
                                <div class="vstack gap-2">

                                    <div class="border rounded border-dashed p-2" v-for="(attachment, index) in event.data.attachments" :key="index">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0 me-3">
                                                <div class="avatar-sm">
                                                    <div class="avatar-title bg-light text-secondary rounded fs-24 shadow">
                                                        <!--Icon depending mime type-->
                                                        <i class="ri-image-2-line" v-if="attachment.data.mime_type.includes('image')"></i>
                                                        <i class="ri-video-line" v-else-if="attachment.data.mime_type.includes('video')"></i>
                                                        <i class="ri-folder-music-line" v-else-if="attachment.data.mime_type.includes('audio')"></i>
                                                        <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('zip')"></i>
                                                        <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('pdf')"></i>
                                                        <i class="ri-file-text-line" v-else></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1 overflow-hidden">
                                                <h5 class="fs-13 mb-1"><a :href="attachment.data.path" target="_blank" class="text-body text-truncate d-block">{{ attachment.data.name }}</a></h5>
                                                <div>{{ attachment.data.size }}</div>
                                            </div>
                                            <div class="flex-shrink-0 ms-2">
                                                <div class="d-flex gap-1">
                                                    <a href="#" class="btn btn-icon text-muted btn-sm fs-18 shadow-none" @click.prevent="deleteAttachment(attachment.data.id, index)"><i class="ri-delete-bin-3-fill text-danger"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
