import CenterList from "../Views/Center/CenterList.vue";
import CenterCreate from "../Views/Center/CenterCreate.vue";
import CenterEdit from "../Views/Center/CenterEdit.vue";
import CenterShow from "../Views/Center/CenterShow.vue";

export const centerRoutes = [
    {
        path: "/centers/edit/:id",
        name: "centers.edit",
        component: CenterEdit,
        meta: {
            menu: {
                parent: 'centers',
                child: null
            },
            breadcrumb: {
                title: "Centres",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Tous les centres",
                            link: {name: 'centers.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/centers/create",
        name: "centers.create",
        component: CenterCreate,
        meta: {
            menu: {
                parent: 'centers',
                child: null
            },
            breadcrumb: {
                title: "Centres",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Tous les centres",
                            link: {name: 'centers.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/centers/:id",
        name: "centers.show",
        component: CenterShow,
        meta: {
            menu: {
                parent: 'centers',
                child: null
            },
            breadcrumb: {
                title: "Centres",
                nav: {
                    all: {
                        active: "Fiche centre",
                        inactive: [{
                            title: "Tous les centres",
                            link: {name: 'centers.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/centers",
        name: "centers.list",
        component: CenterList,
        meta: {
            menu: {
                parent: 'centers',
                child: null
            },
            breadcrumb: {
                title: "Centres",
                nav: {
                    all: {
                        active: "Tous les centres",
                        inactive: []
                    }
                }
            }
        },
    }
];
