import NewsCategoryList from "../Views/NewsCategory/NewsCategoryList.vue";
import NewsCategoryCreate from "../Views/NewsCategory/NewsCategoryCreate.vue";
import NewsCategoryEdit from "../Views/NewsCategory/NewsCategoryEdit.vue";
import HelpArticleCategoryEdit from "../Views/HelpArticleCategory/HelpArticleCategoryEdit.vue";
import HelpArticleCategoryCreate from "../Views/HelpArticleCategory/HelpArticleCategoryCreate.vue";
import HelpArticleCategoryList from "../Views/HelpArticleCategory/HelpArticleCategoryList.vue";

export const helpArticleCategoryRoutes = [
    {
        path: "/help-article-categories/edit/:id",
        name: "help-article-categories.edit",
        component: HelpArticleCategoryEdit,
        meta: {
            menu: {
                parent: 'help-articles',
                child: 'help-article-categories'
            },
            breadcrumb: {
                title: "Catégories du centre d'aide",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Toutes les catégories",
                            link: {name: 'help-article-categories.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/help-article-categories/create",
        name: "help-article-categories.create",
        component: HelpArticleCategoryCreate,
        meta: {
            menu: {
                parent: 'help-articles',
                child: 'help-article-categories'
            },
            breadcrumb: {
                title: "Catégories du centre d'aide",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Toutes les catégories",
                            link: {name: 'help-article-categories.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/help-article-categories",
        name: "help-article-categories.list",
        component: HelpArticleCategoryList,
        meta: {
            menu: {
                parent: 'help-articles',
                child: 'help-article-categories'
            },
            breadcrumb: {
                title: "Catégories du centre d'aide",
                nav: {
                    all: {
                        active: "Toutes les catégories",
                        inactive: []
                    }
                }
            }
        },
    }
];
