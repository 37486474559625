<template>
    <div class="mb-3 d-flex flex-column align-items-start" :class="bsClass">
        <div class="form-check form-check-secondary form-switch form-switch-md mt-auto" dir="ltr">
            <label class="form-check-label" :for="name" v-if="!labelHidden">
                {{ label }}
                <span class="text-danger" v-if="required"> *</span>
            </label>
            <input
                class="form-check-input"
                :class="errorClassObject()"
                type="checkbox"
                :name="name"
                :id="name"
                :required="required"
                v-model="value"
                :disabled="disabled"
                @change="updateField(value)"
            >
        </div>
        <span class="invalid-feedback" v-html="errorMessage()"></span>
        <span class="form-text text-muted" v-if="informationContent !== null">
            {{ informationContent }}
        </span>
    </div>
</template>

<script>
export default {
    props : {
        name : {
            type: String,
            required: true
        },
        label : {
            type: String,
            required: true
        },
        labelHidden : {
            type: Boolean,
            required: false,
            default: false
        },
        required : {
            type: Boolean,
            required: false,
            default: false
        },
        informationContent : {
            type: String,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        disabled : {
            type: Boolean,
            required: false,
            default: false
        },
        data: {
            required: false,
            default: null
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        },
    },
    data() {
        return {
            value: null
        }
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            )
        }
    },
    methods: {
        updateField: function() {
            this.clearErrors(this.name);
            this.$emit("update:field", this.value);
        },
        errorMessage: function() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        },
        clearErrors: function() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject: function() {
            return {
                "is-invalid": this.hasError
            }
        }
    },
    watch: {
        data: {
            immediate: true,
            handler(newVal, oldVal) {
                return this.value = newVal;
            }
        }
    }
};
</script>
