<template>
<div class="mb-3" :class="bsClass">
    <label :for="name" class="form-label" v-if="!labelHidden">
        {{ label }}
        <span class="text-danger" v-if="required"> *</span>
    </label>
    <textarea
        class="form-control"
        :class="errorClassObject()"
        :name="name"
        :id="name"
        :rows="row"
        :required="required"
        @input="updateField()"
        v-model="value"
        :disabled="disabled"
        :placeholder="placeholder"
        :ref="name"
    >
    </textarea>
    <div class="invalid-feedback" v-html="errorMessage()"></div>
    <span class="form-text text-muted" v-if="informationContent !== null">
        {{ informationContent }}
    </span>
</div>
</template>

<script>
export default {
    name: "FormTextarea",
    props : {
        name : {
            type: String,
            required: true
        },
        label : {
            type: String,
            required: true
        },
        labelHidden : {
            type: Boolean,
            required: false,
            default: false
        },
        required : {
            type: Boolean,
            required: false,
            default: false
        },
        errors: {
            required: true
        },
        data: {
            required: false,
            default: null
        },
        informationContent : {
            type: String,
            required: false,
            default: null
        },
        row : {
            type: Number,
            required: false,
            default: 3
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        },
        disabled : {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },

    },
    data: function() {
        return {
            value : null
        }
    },
    computed : {
        hasError : function(){
            return this.errors && this.errors[this.name] && this.errors[this.name].length > 0 ;
        }
    },
    methods : {
        updateField : function(){
            this.clearErrors(this.name);
            this.$emit('update:field', this.value)
        },
        errorMessage : function(){
            if(this.hasError){
                return this.errors[this.name][0];
            }
        },
        clearErrors : function(){
            if(this.hasError){
                this.errors[this.name] = null;
            }
        },
        errorClassObject: function(){
            return{
                'is-invalid' : this.hasError
            }

        }
    },
    watch: {
        data: {
            immediate: true,
            handler(newVal, oldVal) {
                this.value = newVal;
            },
        }
    },
}
</script>

<style scoped>

</style>
