<script setup>
import {nextTick, onBeforeMount, ref, watch} from "vue";
import {useHttpErrorsHandler} from "../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import FormInputFilePond from "./FormInputFilePond.vue";
import {api as viewerApi} from "v-viewer";

const props = defineProps({
    bsClass: {
        type: String,
        required: false,
        default: "col-md-12"
    },
    commentableType: {
        type: String,
        required: true
    },
    commentableId: {
        type: Number,
        required: true
    },
})

const $toast = useToast();
const comments = ref([]);
const loading = ref(true)
const errors = ref(null)
const { httpErrorsHandler } = useHttpErrorsHandler();

function getComments() {
    loading.value = true;
    axios.get("/api/comments?commentable_type=" + props.commentableType + "&commentable_id=" + props.commentableId)
        .then(response => {
            comments.value = response.data.data;
            scrollToBottom(false);
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}

onBeforeMount(() => {
    getComments()
})

const comment = ref(null);
const newCommentId = ref(null);
function addComment() {
    let formData = new FormData();

    formData.append('commentable_type', props.commentableType);
    formData.append('commentable_id', props.commentableId);
    formData.append('body', comment.value);
    if (parentComment.value) {
        formData.append('parent_id', parentComment.value.data.id);
    }
    //add attachments
    Array.from(attachments.value).forEach(attachment => {
        formData.append('attachments[]', attachment);
    });

    axios.post("/api/comments", formData)
        .then(response => {
            const newComment = response.data;
            newCommentId.value = newComment.data.id;

            if (parentComment.value) {
                comments.value[parentIndex.value].data.replies.push(newComment);
                parentComment.value = null;
                parentIndex.value = null;
            } else {
                comments.value.push(newComment);
            }
            focusOnCommentAdded();
            comment.value = null;
            showFileInput.value = false;
            attachments.value = [];

            $toast.success('Commentaire ajouté', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const commentsContainer = ref(null);

function scrollToBottom(smooth = false) {
    nextTick(() => {
        const scrollContainer = commentsContainer.value ? commentsContainer.value.querySelector('.simplebar-content-wrapper') : null;
        if (scrollContainer) {
            if (smooth) {
                scrollContainer.scrollTo({
                    top: scrollContainer.scrollHeight,
                    behavior: 'smooth'
                });
            } else {
                scrollContainer.scrollTop = scrollContainer.scrollHeight;
            }
        }
    });
}

const parentComment = ref(null);
const parentIndex = ref(null);
function prepareAnswerComment(comment, indexParent){
    parentComment.value = comment;
    parentIndex.value = indexParent;
    focusOnCommentInput();
}

function cancelAnswerComment(){
    parentComment.value = null;
    parentIndex.value = null;
}

const commentInput = ref(null);

function focusOnCommentInput() {
    nextTick(() => {
        if (commentInput.value) {
            commentInput.value.focus();
        }
    });
}

function focusOnCommentAdded() {
    nextTick(() => {
        const commentElement = document.getElementById(`comment-${newCommentId.value}`);
        if (commentElement) {
            commentElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    });
    // Retirer la classe après 2 secondes
    setTimeout(() => {
        newCommentId.value = null;
    }, 5000);
}

function removeComment(id) {
    axios.delete("/api/comments/" + id)
        .then(response => {
            //look for the comments and in the replies and remove it
            comments.value.forEach((comment, index) => {
                if (comment.data.id === id) {
                    comments.value.splice(index, 1);
                }
                else {
                    comment.data.replies.forEach((reply, indexReply) => {
                        if (reply.data.id === id) {
                            comments.value[index].data.replies.splice(indexReply, 1);
                        }
                    });
                }
            });
            $toast.success('Commentaire supprimé', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const commentToEdit = ref(null);
const idCommentToEdit = ref(null);
const indexCommentToEdit = ref(null);
const indexCommentReplyToEdit = ref(null);
const editingComment = ref(false);
function editComment(comment, index, indexReply = null) {
    commentToEdit.value = comment.data.body;
    idCommentToEdit.value = comment.data.id;
    indexCommentToEdit.value = index;
    indexCommentReplyToEdit.value = indexReply;
    editingComment.value = true;
    focusOnCommentInput();
}

function updateComment() {

    let formData = new FormData();

    formData.append('body', commentToEdit.value);
    //add attachments
    Array.from(attachments.value).forEach(attachment => {
        formData.append('attachments[]', attachment);
    });
    formData.append('_method', 'PUT');
    axios.post("/api/comments/" + idCommentToEdit.value, formData)
        .then(response => {
            if (indexCommentReplyToEdit.value !== null) {
                comments.value[indexCommentToEdit.value].data.replies[indexCommentReplyToEdit.value].data.body = commentToEdit.value;
                comments.value[indexCommentToEdit.value].data.replies[indexCommentReplyToEdit.value].data.attachments = response.data.data.attachments;
            } else {
                comments.value[indexCommentToEdit.value].data.body = commentToEdit.value;
                comments.value[indexCommentToEdit.value].data.attachments = response.data.data.attachments;
            }
            commentToEdit.value = null;
            idCommentToEdit.value = null;
            indexCommentToEdit.value = null;
            indexCommentReplyToEdit.value = null;
            editingComment.value = false;
            comment.value = null;
            showFileInput.value = false;
            attachments.value = [];
            $toast.success('Commentaire modifié', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
const showFileInput = ref(false);
const attachments = ref([]);

watch(showFileInput, () => {
    if (!showFileInput.value){
        attachments.value = [];
    }
});
const previewImage = (imgPath) => {
    event.preventDefault()
    viewerApi({
        images: [imgPath],
        options: {
            toolbar: false,
            transition: false,
            loading: true,
        }
    })
}
function downloadFile(path) {
    window.open(path);
}
const textareaRef = ref(null);
const autoResize = () => {
    if (textareaRef.value) {
        textareaRef.value.style.height = 'auto';
        textareaRef.value.style.height = textareaRef.value.scrollHeight + 'px';
    }
};

watch(editingComment, () => {
    attachments.value = [];
});

function deleteAttachment(attachmentId) {
    axios.delete("/api/attachments/" + attachmentId)
        .then(response => {
            document.getElementById('attachment' + attachmentId).remove();
            $toast.success('Fichier supprimé', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
const hover = ref(false);
function touchStart(event) {
    event.currentTarget.classList.add('touch');
}
</script>

<template>
    <div class="row">
        <div :class="bsClass">
            <div class="card" id="commentsSection">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1 align-middle"><i class="mdi mdi-comment-multiple-outline me-2 align-middle fs-4"></i>Commentaires<span class="text-muted align-middle"> ({{ comments.length }})</span></h4>
                </div>

                <div class="card-body">

                    <div data-simplebar style="max-height: 350px;" class="px-3 mx-n3 mb-2" ref="commentsContainer">
                        <!--Comments-->
                        <div class="d-flex mb-4" v-for="(comment, index) in comments" :key="index" :class="{ 'new-comment': comment.data.id === newCommentId }" :id="`comment-${comment.data.id}`">
                            <div class="flex-shrink-0">
                                <img :src="'/format-image/'+comment.data.user.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" class="avatar-xs rounded-circle shadow" />
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="fs-13">
                                    {{ comment.data.user.data.first_name }} {{ comment.data.user.data.last_name }} <small class="text-muted ms-2">{{ comment.data.created_at_fr }}</small>
                                    <!--icon edit-->
                                    <a href="#" class="ms-2 align-middle" @click.prevent="editComment(comment, index)"  v-if="comment.can.update" title="Modifier le commentaire">
                                        <i class="mdi mdi-pen fs-14 text-info"></i>
                                    </a>
                                    <!--icon delete-->
                                    <a href="#" class="ms-1 align-middle" @click.prevent="removeComment(comment.data.id)"  v-if="comment.can.delete" title="Supprimer le commentaire">
                                        <i class="mdi mdi-trash-can fs-14 text-danger"></i>
                                    </a>
                                    <transition name="fade">
                                        <span class="badge bg-secondary-subtle text-secondary badge-border ms-2" v-if="comment.data.id === newCommentId">Nouveau</span>
                                    </transition>
                                </h5>
                                <p class="text-body" v-html="comment.data.body.replace(/\n/g, '<br />')"></p>
                                <!--Attachments-->
                                <a href="#" :id="'attachment'+attachment.data.id" class="badge rounded-pill bg-info-subtle text-info me-2 mb-2 show-on-hover" v-for="attachment in comment.data.attachments" @click.prevent="attachment.data.mime_type.includes('image') ? previewImage(attachment.data.path) : downloadFile(attachment.data.path)"
                                   @mouseover="hover = true" @mouseleave="hover = false" @touchstart="touchStart">
                                    <i class="mdi mdi-attachment me-2 fs-12 align-middle"></i>
                                    <span class="align-middle fs-10">{{ attachment.data.name }}</span>
                                    <a href="#" class="ms-1 align-middle hide-on-no-hover" @click.prevent.stop="deleteAttachment(attachment.data.id)" v-if="attachment.can.delete">
                                        <i class="mdi mdi-trash-can fs-14 text-danger"></i>
                                    </a>
                                </a>
                                <!--Reply button-->
                                <div>
                                    <a href="#" class="badge text-muted bg-light fs-11" @click.prevent="prepareAnswerComment(comment, index)" v-if="comment.data.replies.length === 0">
                                        <i class="mdi mdi-reply"></i> Répondre
                                    </a>
                                </div>

                                <div class="d-flex mt-2" v-for="(reply, indexReply) in comment.data.replies" :key="indexReply" :class="{ 'new-comment': reply.data.id === newCommentId }" :id="`comment-${reply.data.id}`">
                                    <div class="flex-shrink-0">
                                        <img :src="'/format-image/'+reply.data.user.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" class="avatar-xs rounded-circle" />
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h5 class="fs-13">
                                            {{ reply.data.user.data.first_name }} {{ reply.data.user.data.last_name }} <small class="text-muted ms-2">{{ reply.data.created_at_fr }}</small>
                                            <!--icon edit-->
                                            <a href="#" class="ms-2 align-middle" @click.prevent="editComment(reply, index, indexReply)"  v-if="comment.can.update" title="Modifier le commentaire">
                                                <i class="mdi mdi-pen fs-14 text-info"></i>
                                            </a>
                                            <!--icon trash-->
                                            <a href="#" class="ms-2 align-middle" @click.prevent="removeComment(reply.data.id)" v-if="reply.can.delete">
                                                <i class="mdi mdi-trash-can fs-14 text-danger"></i>
                                            </a>
                                            <transition name="fade">
                                                <span class="badge bg-secondary-subtle text-secondary badge-border ms-2" v-if="reply.data.id === newCommentId">Nouveau</span>
                                            </transition>
                                        </h5>
                                        <p class="text-body" :class="indexReply" v-html="reply.data.body.replace(/\n/g, '<br />')"></p>
                                        <!--Attachments-->
                                        <a href="#" :id="'attachment'+attachment.data.id" class="badge rounded-pill bg-info-subtle text-info me-2 mb-2 show-on-hover" v-for="attachment in reply.data.attachments" @click.prevent="attachment.data.mime_type.includes('image') ? previewImage(attachment.data.path) : downloadFile(attachment.data.path)"
                                           @mouseover="hover = true" @mouseleave="hover = false" @touchstart="touchStart">
                                            <i class="mdi mdi-attachment me-2 fs-12 align-middle"></i>
                                            <span class="align-middle fs-10">{{ attachment.data.name }}</span>
                                            <a href="#" class="ms-1 align-middle hide-on-no-hover" @click.prevent.stop="deleteAttachment(attachment.data.id)" v-if="attachment.can.delete">
                                                <i class="mdi mdi-trash-can fs-14 text-danger"></i>
                                            </a>
                                        </a>
                                        <div>
                                            <!--Reply button-->
                                            <a href="#" class="badge text-muted bg-light fs-11" @click.prevent="prepareAnswerComment(reply, index)" v-if="comment.data.replies.length === (indexReply + 1)">
                                                <i class="mdi mdi-reply"></i> Répondre
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center" v-if="comments.length === 0">
                            <i class="mdi mdi-comment-off-outline fs-24 text-muted"></i>
                            <p class="text-muted mb-0">Aucun commentaire</p>
                        </div>

                    </div>
                    <form class="mt-0">
                        <div class="row g-3">
                            <!--Adding comment-->
                            <form v-on:submit.prevent="addComment"  v-if="!editingComment">
                                <div class="col-12">
                                    <label for="commentForm" class="form-label text-body">Laisser un commentaire</label>

                                    <div class="alert alert-info alert-dismissible shadow fade show" role="alert" v-if="parentComment !== null">
                                        <strong>Vous répondez à :</strong><br /><br />
                                        <div class="d-flex mb-1">
                                            <div class="flex-shrink-0">
                                                <img :src="'/format-image/'+parentComment.data.user.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" class="avatar-xs rounded-circle shadow" />
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <h5 class="fs-13">{{ parentComment.data.user.data.first_name }} {{ parentComment.data.user.data.last_name }} <small class="text-muted ms-2">{{ parentComment.data.created_at_fr }}</small></h5>
                                                <p class="text-body mb-0" v-html="parentComment.data.body.replace(/\n/g, '<br />')"></p>
                                            </div>
                                        </div>
                                        <button type="button" class="btn-close" @click.prevent="cancelAnswerComment()"></button>
                                    </div>
                                    <textarea class="form-control bg-light border mb-2" id="commentForm" rows="3" placeholder="Votre commentaire..." v-model="comment" @input="autoResize" ref="textareaRef" required></textarea>
                                    <form-input-file-pond
                                        v-if="showFileInput"
                                        name="attachments"
                                        label="Fichiers"
                                        :label-hidden="true"
                                        :errors="errors"
                                        :required="false"
                                        :data="attachments"
                                        @update:field="attachments = $event"
                                        bs-class="col-md-12 mt-3"
                                        :disabled="false"
                                        :multiple="true"
                                    />
                                </div>
                                <div class="col-12 text-end">
                                    <button type="button" class="btn btn-link text-decoration-none shadow-none" @click.prevent="showFileInput = !showFileInput">
                                        <i class="bx bx bx-paperclip align-middle fs-20" :class="showFileInput ? 'text-info' : 'text-muted'"></i>
                                    </button>
                                    <button type="submit" class="btn btn-secondary">Poster commentaire</button>
                                </div>
                            </form>
                            <!--Editing comment-->
                            <form v-on:submit.prevent="updateComment" v-if="editingComment">
                                <div class="col-12">
                                    <label for="commentForm" class="form-label text-body">Modifier mon commentaire</label>
                                    <textarea class="form-control bg-light border mb-2" rows="3" placeholder="Votre commentaire..." v-model="commentToEdit" ref="textareaRef" @input="autoResize" required></textarea>
                                    <form-input-file-pond
                                        v-if="showFileInput"
                                        name="attachments"
                                        label="Fichiers"
                                        :label-hidden="true"
                                        :errors="errors"
                                        :required="false"
                                        :data="attachments"
                                        @update:field="attachments = $event"
                                        bs-class="col-md-12 mt-3"
                                        :disabled="false"
                                        :multiple="true"
                                    />
                                </div>
                                <div class="col-12 text-end">
                                    <button type="button" class="btn btn-link text-decoration-none shadow-none" @click.prevent="showFileInput = !showFileInput">
                                        <i class="bx bx bx-paperclip align-middle fs-20" :class="showFileInput ? 'text-info' : 'text-muted'"></i>
                                    </button>
                                    <button type="submit" class="btn btn-success">Modifier mon commentaire</button>
                                </div>
                            </form>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.hide-on-no-hover {
    display: none;
}

.show-on-hover:hover .hide-on-no-hover, .show-on-hover.touch .hide-on-no-hover {
    display: inline;
}

</style>
