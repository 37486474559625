import QuoteList from "../Views/Quote/QuoteList.vue";
import QuoteCreate from "../Views/Quote/QuoteCreate.vue";
import QuoteEdit from "../Views/Quote/QuoteEdit.vue";
import QuoteShow from "../Views/Quote/QuoteShow.vue";

export const quoteRoutes = [
    {
        path: "/quotes/edit/:id",
        name: "quotes.edit",
        component: QuoteEdit,
        meta: {
            menu: {
                parent: 'quotes',
                child: null
            },
            breadcrumb: {
                title: "Devis",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Tous les devis",
                            link: {name: 'quotes.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/quotes/create",
        name: "quotes.create",
        component: QuoteCreate,
        meta: {
            menu: {
                parent: 'quotes',
                child: null
            },
            breadcrumb: {
                title: "Devis",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Tous les devis",
                            link: {name: 'quotes.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/quotes/:id",
        name: "quotes.show",
        component: QuoteShow,
        meta: {
            menu: {
                parent: 'quotes',
                child: null
            },
            breadcrumb: {
                title: "Devis",
                nav: {
                    all: {
                        active: "Détails du devis",
                        inactive: [{
                            title: "Tous les devis",
                            link: {name: 'quotes.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/quotes",
        name: "quotes.list",
        component: QuoteList,
        meta: {
            menu: {
                parent: 'quotes',
                child: null
            },
            breadcrumb: {
                title: "Devis",
                nav: {
                    all: {
                        active: "Tous les devis",
                        inactive: null
                    }
                }
            }
        },
    }
];
