<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {inject, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import Loader from "../../Components/Loader.vue";
import {useToast} from "vue-toast-notification";
import Chat from "../../Components/Chat.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import FormMultiSelect from "../../Components/FormMultiSelect.vue";
import axios from "axios";
const $toast = useToast();
const brandConfig = inject('brandConfig');
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();
const loading = ref(true)
const errors = ref(null)
const clientRequest = ref(null)
import { DocusealForm } from '@docuseal/vue'

function getClientRequest(clientRequestId) {
    loading.value = true;
    axios.get("/api/client-requests/" + clientRequestId)
        .then(response => {
            clientRequest.value = response.data;
            internalNotes.value = response.data.data.notes;
            loading.value = false;
            resetNotificationNumber();
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onBeforeMount(() => {
    getClientRequest(router.currentRoute.value.params.id)
})

function changeStatus(status) {
    if (status === clientRequest.value.data.status) {
        return;
    }
    axios.patch("/api/client-requests/" + clientRequest.value.data.id + "/status", { status: status })
        .then(response => {
            clientRequest.value = response.data;
            $toast.success('Statut mis à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function resetNotificationNumber() {
    if (clientRequest.value.data.notifications_center > 0){
        axios.patch("/api/client-requests/" + router.currentRoute.value.params.id + "/reset-notification-number")
            .then(response => {
                clientRequest.value.data.notifications_center = 0;
            })
            .catch(error => {
                httpErrorsHandler(error);
            });
    }
}

//Hide modal if leave page
onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

const clientRequestStatuses = reactive([
    { value: 'pending', label: 'À traiter', badgeColor: 'danger' },
    { value: 'awaiting_information', label: 'Attente d\'information', badgeColor: 'info' },
    { value: 'responded', label: 'Répondu', badgeColor: 'secondary' },
    { value: 'appointment_scheduled', label: 'RDV programmé', badgeColor: 'success' },
    { value: 'won', label: 'Gagné', badgeColor: 'success' },
    { value: 'to_follow_up', label: 'À relancer', badgeColor: 'warning' },
    { value: 'no_show', label: 'Client non présenté', badgeColor: 'dark' },
    { value: 'lost', label: 'Perdu', badgeColor: 'dark' },
    { value: 'duplicate', label: 'Doublon', badgeColor: 'dark' },
    { value: 'closed', label: 'Fermé', badgeColor: 'dark' },
    { value: 'ecu_in_dev', label: 'ECU en dev', badgeColor: 'primary' },
    { value: 'rma', label: 'RMA', badgeColor: 'warning' },
]);

function showModalInternalNotes() {
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalInternalNotes'))
    modal.show();
}
const internalNotes = ref(null)
function updateInternalNotes() {
    axios.patch("/api/client-requests/" + clientRequest.value.data.id + "/notes", { notes: internalNotes.value })
        .then(response => {
            clientRequest.value.data.notes = response.data.data.notes;
            $toast.success('Note interne mise à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalInternalNotes'))
            modal.hide();
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const formPdf = reactive({
    "prestations": [],
    "options": [],
})
const generatingPdf = ref(false)
function generatePdf() {
    generatingPdf.value = true;
    let formData = new FormData();
    Object.entries(formPdf).forEach(([key, value]) => {
        return value.forEach((item) => {
            formData.append(`${key}[]`, item.id);
        });
    });

    axios.post("/api/client-requests/" + clientRequest.value.data.id + "/pdf", formData)
        .then(response => {
            $toast.success('Fiche de travail générée', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            clientRequest.value.data.worksheet_path = response.data.data.worksheet_path;
            clientRequest.value.data.worksheet_generated_at = response.data.data.worksheet_generated_at;
            generatingPdf.value = false;
            window.open(response.data.data.worksheet_path, '_blank');
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalPdf'))
            modal.hide();
            formPdf.prestations = [];
            formPdf.options = [];
        })
        .catch(error => {
            httpErrorsHandler(error);
            generatingPdf.value = false;
        });
}

function showModalPdf() {
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalPdf'))
    modal.show();
}

function canGeneratePdf() {
    if (clientRequest.value.data.car_brand_id === null
        || clientRequest.value.data.car_model_id === null
        || clientRequest.value.data.car_version_id === null
        || clientRequest.value.data.car_engine_id === null
        || clientRequest.value.data.vin === null
        || clientRequest.value.data.license_plate === null
        || clientRequest.value.data.vehicle_year === null
        || clientRequest.value.data.mileage === null) {
        return false;
    }

    if (clientRequest.value.data.car_power_type === 'petrol' || clientRequest.value.data.car_power_type === 'hybrid_petrol') {
        if (clientRequest.value.data.spark_plug_mileage === null
            || clientRequest.value.data.coil_mileage === null
            || clientRequest.value.data.octane_rating === null) {
            return false;
        }
    }

    return true;
}

const slugForm = ref(null)
const loadingSignature = ref(false)
function generateSubmission() {
    loadingSignature.value = true;
    axios.get("/api/client-requests/" + clientRequest.value.data.id + "/initiate-signature")
        .then(response => {
            //close modal pdf
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalPdf'))
            modal.hide();
            const modalSignature = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalSignature'))
            modalSignature.show();
            slugForm.value = response.data.slug;
            loadingSignature.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loadingSignature.value = false;
        });
}

function signatureCompleted() {
    const modalSignature = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalSignature'))
    modalSignature.hide();
    $toast.success('Fiche de travail signée. Un email avec la fiche va être envoyé.', {
        position: 'top',
        duration: 5000,
        dismissible: true,
        pauseOnHover: true,
    });
}

onMounted(() => {
    Echo.channel(`client_requests.${router.currentRoute.value.params.id}`)
        .listen('ClientRequestSignedEvent', (e) => {
            clientRequest.value.data.worksheet_signed_path = e.data.data.worksheet_signed_path;
            clientRequest.value.data.worksheet_audit_path = e.data.data.worksheet_audit_path;
            $toast.info('Documents signés disponibles en téléchargement', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        });
});

const showOriginReset = ref(false)
watch(() => formPdf.prestations, () => {
    showOriginReset.value = formPdf.prestations.some(option => option.id === 'ORIGIN_RESET');
})
</script>

<template>
    <div class="row">
        <div class="col-12" v-show="loading">
            <loader />
        </div>
        <div class="col-xl-6" v-if="!loading">
            <div class="card border">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1">
                        <i class="mdi me-2 align-middle fs-4" :class="router.currentRoute.value.name === 'client-request-others.show' ? 'mdi-folder-information-outline' : 'mdi-car-info'"></i>
                        {{ router.currentRoute.value.name === 'client-request-others.show' ? 'Autre demande' : 'Demande client' }}
                    </h4>
                    <div class="avatar-xs me-2">
                        <a href="#" class="avatar-title bg-dark-subtle text-dark fs-15 rounded" title="Fiche de travail" @click.prevent="showModalPdf">
                            <i class="ri-file-edit-fill"></i>
                        </a>
                    </div>
                    <div class="avatar-xs me-2">
                        <a href="#" class="avatar-title bg-warning-subtle text-warning fs-15 rounded" title="Notes" @click.prevent="showModalInternalNotes">
                            <i class="ri-sticky-note-fill"></i>
                        </a>
                    </div>
                    <div class="avatar-xs">
                        <router-link :to="{ name: 'client-requests.edit', params:{ id: clientRequest.data.id } }" class="avatar-title bg-secondary-subtle text-secondary fs-15 rounded" title="Modifier">
                            <i class="ri-edit-2-fill"></i>
                        </router-link>
                    </div>

                </div>
                <div class="card-body">

                    <div class="table-responsive">
                        <table class="table table-md table-nowrap mb-3">
                            <tbody>
                            <tr>
                                <th scope="row" class="col-5 border-top-0">ID</th>
                                <td class="border-top-0 text-body fw-semibold">#{{ clientRequest.data.id }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Centre</th>
                                <td>{{ clientRequest.data.center.data.name }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Statut</th>
                                <td>
                                    <span :class="'fs-11 badge bg-'+clientRequest.data.status_badge_color+'-subtle  text-'+clientRequest.data.status_badge_color">{{ clientRequest.data.status_label_fr }}</span>
                                    <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" id="statusDropdown" data-bs-toggle="dropdown" v-if="clientRequest.can.update"></a>
                                    <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                        <li v-for="(status, key) in clientRequestStatuses" :key="key">
                                            <a class="dropdown-item" href="#" @click.prevent="changeStatus(status.value)">
                                                <span :class="`fs-11 badge bg-${status.badgeColor}-subtle text-${status.badgeColor}`">{{ status.label }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Client</th>
                                <td class="align-middle">
                                    <router-link :to="{ name: 'clients.show', params: { id: clientRequest.data.client.data.id }}" class="mr-1 align-middle text-secondary" title="Afficher la fiche client">
                                        {{ clientRequest.data.client.data.last_name }} {{ clientRequest.data.client.data.first_name }}
                                    </router-link>
                                    <span :class="'fs-11 align-middle ms-2 badge bg-'+clientRequest.data.client.data.client_status_badge_color+'-subtle  text-'+clientRequest.data.client.data.client_status_badge_color">{{ clientRequest.data.client.data.client_status_label_fr }}</span>
                                    <router-link :to="{ name: 'clients.edit', params:{ id: clientRequest.data.client.data.id } }" class="text-info align-middle" title="Modifier le client" v-if="clientRequest.data.client.can.update">
                                        <i class="ri-pencil-fill fs-17 px-1 align-middle"></i>
                                    </router-link>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Téléphone</th>
                                <td>{{ clientRequest.data.client.data.phone_number }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Email</th>
                                <td>{{ clientRequest.data.client.data.email }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Raisons contact</th>
                                <td>{{ clientRequest.data.contact_reason }}</td>
                            </tr>
                            <tr v-if="clientRequest.data.vehicle_type_label_fr !== null || clientRequest.data.car_power_type_label_fr !== null">
                                <th scope="row" class="col-5">Véhicule - Type</th>
                                <td>{{ clientRequest.data.vehicle_type_label_fr }} - {{ clientRequest.data.car_power_type_label_fr }}</td>
                            </tr>
                            <tr v-if="clientRequest.data.car_brand !== null || clientRequest.data.car_model !== null">
                                <th scope="row" class="col-5">Marque - Modèle</th>
                                <td>{{ clientRequest.data.car_brand }} - {{ clientRequest.data.car_model }}</td>
                            </tr>
                            <tr v-if="clientRequest.data.car_version !== null || clientRequest.data.car_engine !== null">
                                <th scope="row" class="col-5 align-middle">Version - Moteur</th>
                                <td>
                                    <span class="align-middle">{{ clientRequest.data.car_version }} - {{ clientRequest.data.car_engine }}</span>
                                    <router-link :to="{ name: 'chiptuning.show', params:{ id: clientRequest.data.engine.data.id } }" target="_blank" class="ms-2 btn btn-sm btn-dark bg-gradient bg-gradient waves-effect waves-light btn-sm" v-if="clientRequest.data.engine !== null">
                                        Fiche moteur
                                    </router-link>
                                </td>
                            </tr>
                            <tr v-if="clientRequest.data.vin !== null">
                                <th scope="row" class="col-5">VIN</th>
                                <td>
                                    {{ clientRequest.data.vin }}
                                </td>
                            </tr>
                            <tr v-if="clientRequest.data.license_plate !== null">
                                <th scope="row" class="col-5">Immatriculation</th>
                                <td>{{ clientRequest.data.license_plate }}</td>
                            </tr>
                            <tr v-if="clientRequest.data.vehicle_year !== null">
                                <th scope="row" class="col-5">Année du véhicule</th>
                                <td>{{ clientRequest.data.vehicle_year }}</td>
                            </tr>
                            <tr v-if="clientRequest.data.mileage !== null">
                                <th scope="row" class="col-5">Kilométrage</th>
                                <td>{{ clientRequest.data.mileage_formatted }} km</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Prestation</th>
                                <td>{{ clientRequest.data.car_stage === null ? '-' : clientRequest.data.car_stage }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Promotion ?</th>
                                <td>
                                    <span class="fs-11 badge bg-success-subtle text-success" v-if="clientRequest.data.is_on_promotion">Oui</span>
                                    <span class="fs-11 badge bg-danger-subtle text-danger" v-else>Non</span>
                                </td>
                            </tr>
                            <tr v-if="clientRequest.data.price !== null">
                                <th scope="row" class="col-5">Prix</th>
                                <td>{{ clientRequest.data.price_formatted }} €</td>
                            </tr>
                            <tr v-if="clientRequest.data.website_page !== null">
                                <th scope="row" class="col-5">Dernière page visitée</th>
                                <td><a :href="clientRequest.data.website_page" target="_blank" class="text-secondary">Lien site web</a></td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5 border-bottom-0">Date création</th>
                                <td class="border-bottom-0">{{ clientRequest.data.created_at_fr }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="alert alert-secondary alert-label-icon rounded-label shadow border" role="alert">
                        <i class="ri-chat-quote-line label-icon"></i>
                        <strong>Commentaire client : </strong><br>
                        <p v-html="clientRequest.data.client_comment !== null ? clientRequest.data.client_comment.replace(/\n/g, '<br />') : 'Aucun commentaire.'" class="mb-0"></p>
                    </div>
                    <div class="alert alert-warning alert-label-icon rounded-label shadow" role="alert" >
                        <a href="#" class="ri-sticky-note-fill label-icon" @click.prevent="showModalInternalNotes"></a>
                        <strong>Notes internes : </strong><br>
                        <p  v-if="clientRequest.data.notes !== null" v-html="clientRequest.data.notes.replace(/\n/g, '<br />')" class="mb-0"></p>
                        <p  class="mb-0" v-else>--</p>
                    </div>

                </div>
            </div>
            <div class="card" v-if="clientRequest.data.attachments.length > 0">
                <div class="card-header align-items-center d-flex border-bottom-dashed">
                    <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-attachment me-2 align-middle fs-4"></i>Fichiers joints</h4>
                </div>

                <div class="card-body">

                    <div class="vstack gap-2">

                        <div class="border rounded border-dashed p-2" v-for="(attachment, index) in clientRequest.data.attachments" :key="index">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 me-3">
                                    <div class="avatar-sm">
                                        <div class="avatar-title bg-light text-secondary rounded fs-24 shadow">
                                            <!--Icon depending mime type-->
                                            <i class="ri-image-2-line" v-if="attachment.data.mime_type.includes('image')"></i>
                                            <i class="ri-video-line" v-else-if="attachment.data.mime_type.includes('video')"></i>
                                            <i class="ri-folder-music-line" v-else-if="attachment.data.mime_type.includes('audio')"></i>
                                            <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('zip')"></i>
                                            <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('pdf')"></i>
                                            <i class="ri-file-text-line" v-else></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <h5 class="fs-13 mb-1"><a :href="attachment.data.path" target="_blank" class="text-body text-truncate d-block">{{ attachment.data.name }}</a></h5>
                                    <div>{{ attachment.data.size }}</div>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                    <div class="d-flex gap-1">
                                        <a :href="attachment.data.path" target="_blank" class="btn btn-icon text-muted btn-sm fs-18 shadow-none"><i class="ri-download-2-line"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal notes -->
            <div id="modalInternalNotes" class="modal fade" tabindex="-1" aria-labelledby="modalInternalNotes" aria-hidden="true" style="display: none;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalInternalNotes">Notes internes</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div class="modal-body">
                            <form-textarea
                                name="notes"
                                label="Notes internes"
                                :label-hidden="false"
                                @update:field="internalNotes = $event"
                                :errors="errors"
                                :required="false"
                                :data="internalNotes"
                                bs-class="col-xl-12"
                                :row="10"
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                            <button type="button" class="btn btn-secondary" @click.prevent="updateInternalNotes">Valider</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal fiche travail -->
            <div id="modalPdf" class="modal fade" tabindex="-1" aria-labelledby="modalPdf" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" style="display: none;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalPdf">Fiche de travail</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div class="modal-body">
                            <!--Missing data-->
                            <div class="alert alert-danger alert-border-left alert-dismissible fade shadow show mb-3" role="alert" v-if="canGeneratePdf() !== true">
                                <p>
                                    Afin de générer la fiche de travail, l'ensemble des informations suivantes doivent être renseignées dans la demande client :
                                </p>
                                <ul>
                                    <li v-if="clientRequest.data.car_brand_id === null">Sélection de la marque dans le configurateur</li>
                                    <li v-if="clientRequest.data.car_model_id === null">Sélection du modèle dans le configurateur</li>
                                    <li v-if="clientRequest.data.car_version_id === null">Sélection de la version dans le configurateur</li>
                                    <li v-if="clientRequest.data.car_engine_id === null">Sélection du moteur dans le configurateur</li>
                                    <li v-if="clientRequest.data.vin === null">VIN</li>
                                    <li v-if="clientRequest.data.license_plate === null">Immatriculation</li>
                                    <li v-if="clientRequest.data.vehicle_year === null">Année du véhicule</li>
                                    <li v-if="clientRequest.data.mileage === null">Kilométrage</li>
                                </ul>
                                <div v-if="clientRequest.data.car_power_type === null || clientRequest.data.car_power_type === 'petrol' || clientRequest.data.car_power_type === 'hybrid_petrol'">
                                    <p>
                                        Pour les véhicules à essence :
                                    </p>
                                    <ul class="mb-3">
                                        <li>Kilométrage bougies</li>
                                        <li>Kilométrage bobines</li>
                                        <li>Indice octane</li>
                                    </ul>
                                </div>
                                <router-link :to="{ name: 'client-requests.edit', params:{ id: clientRequest.data.id } }" type="button" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light">Modifier la demande</router-link>
                            </div>
                            <!--Generated document-->
                            <div class="alert alert-info shadow" role="alert" v-if="clientRequest.data.worksheet_path !== null && clientRequest.data.worksheet_signed_path === null">
                                <p class="mb-0">
                                    Vous avez déjà généré une fiche de travail le <strong>{{ clientRequest.data.worksheet_generated_at }}</strong>.
                                    <br>Vous pouvez l'afficher en cliquant sur le bouton ci-dessous : <br>
                                    <a :href="clientRequest.data.worksheet_path" target="_blank" class="btn btn-sm btn-info bg-gradient waves-effect waves-light mt-2 align-middle me-2">
                                        <i class="mdi mdi-file-certificate-outline align-middle me-1 fs-12"></i>Afficher
                                    </a>
                                    <a href="#" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light mt-2 align-middle" v-if="clientRequest.data.worksheet_signed_path === null && !loadingSignature" @click.prevent="generateSubmission()">
                                        Signer le document
                                    </a>
                                    <button type="button" class="btn btn-sm btn-dark btn-load bg-gradient mt-2 align-middle" v-if="loadingSignature">
                                        <span class="d-flex align-items-center">
                                            <span class="flex-grow-1 me-2">
                                                Préparation...
                                            </span>
                                            <span class="spinner-border flex-shrink-0" role="status">
                                                <span class="visually-hidden">Préparation...</span>
                                            </span>
                                        </span>
                                    </button>
                                </p>
                            </div>
                            <!--Signed document and audit-->
                            <div class="alert alert-success shadow" role="alert" v-if="clientRequest.data.worksheet_signed_path !== null">
                                <p class="mb-0">
                                    La fiche de travail est signée.
                                    <br>Vous pouvez l'afficher ainsi que l'audit en cliquant sur l'un des bouton ci-dessous : <br>
                                    <a :href="clientRequest.data.worksheet_signed_path" target="_blank" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light mt-2 align-middle me-2">
                                        <i class="mdi mdi-file-certificate-outline align-middle me-1 fs-12"></i>Fiche signée
                                    </a>
                                    <a :href="clientRequest.data.worksheet_audit_path" target="_blank" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light mt-2 align-middle me-2">
                                        <i class="mdi mdi-file-certificate-outline align-middle me-1 fs-12"></i>Audit
                                    </a>
                                </p>
                            </div>
                            <!--Origin reset-->
                            <div class="alert alert-warning shadow" role="alert" v-if="showOriginReset">
                                <p class="mb-0">
                                    Si vous effectuez une remise à l'origine configurée par un autre préparateur, veuillez procéder en mode bench ou boot afin d'éviter tout risque de plantage dû à un possible immo OFF.
                                </p>
                            </div>
                            <!--Form-->
                            <div v-if="clientRequest.data.worksheet_signed_path === null && !generatingPdf">
                                <form-multi-select
                                    :errors="errors"
                                    label="Prestations"
                                    name="prestations"
                                    :required="true"
                                    :data="formPdf.prestations"
                                    @update:field="formPdf.prestations = $event"
                                    bs-class="col-md-12"
                                    :disabled="false"
                                    :label-hidden="false"
                                    placeholder="Sélectionnez une ou plusieurs prestations"
                                    :liste-options="[
                                    { name: 'Stage 0', id: 'STAGE_0' },
                                    { name: 'Stage 1', id: 'STAGE_1' },
                                    { name: 'Stage 2', id: 'STAGE_2' },
                                    { name: 'Stage 3', id: 'STAGE_3' },
                                    { name: 'E85 Flex+', id: 'E85_FLEX+' },
                                    { name: 'E85 Flexfuel', id: 'E85_FLEXFUEL' },
                                    { name: 'Remise d\'origine', id: 'ORIGIN_RESET' },
                                ]"
                                />

                                <form-multi-select
                                    :errors="errors"
                                    label="Options"
                                    name="options"
                                    :required="false"
                                    :data="formPdf.options"
                                    @update:field="formPdf.options = $event"
                                    bs-class="col-md-12"
                                    :disabled="false"
                                    :label-hidden="false"
                                    placeholder="Sélectionnez une ou plusieurs options"
                                    :liste-options="[
                                        { name: 'Banc de puissance', id: 'POWER_BENCH' },
                                        { name: 'Cold Start Off', id: 'COLD_START_OFF' },
                                        { name: 'Cylinder On Demand Off', id: 'CYLINDER_ON_DEMAND_OFF' },
                                        { name: 'DPF Off', id: 'DPF_OFF' },
                                        { name: 'DSG Fart', id: 'DSG_FART' },
                                        { name: 'DTC Off', id: 'DTC_OFF' },
                                        { name: 'Decat (Cat Off)', id: 'DECAT_CAT_OFF' },
                                        { name: 'E85 Flexfuel', id: 'E85_FLEXFUEL' },
                                        { name: 'EGR Off', id: 'EGR_OFF' },
                                        { name: 'Entretien forfait essentiel', id: 'ESSENTIAL_PLAN' },
                                        { name: 'Entretien forfait sérénité', id: 'CHILL_PLAN' },
                                        { name: 'Exhaust Flaps', id: 'EXHAUST_FLAPS' },
                                        { name: 'GPF Off', id: 'GPF_OFF' },
                                        { name: 'Gearbox', id: 'GEARBOX' },
                                        { name: 'Hard Rev Cut', id: 'HARD_REV_CUT' },
                                        { name: 'Launch Control', id: 'LAUNCH_CONTROL' },
                                        { name: 'Pop & Bang', id: 'POP_BANG' },
                                        { name: 'Pop & Bang Start Button', id: 'POP_BANG_START_BUTTON' },
                                        { name: 'Pop Corn', id: 'POP_CORN' },
                                        { name: 'PPF', id: 'PPF' },
                                        { name: 'Protection céramique', id: 'CERAMIC' },
                                        { name: 'SCR Off', id: 'SCR_OFF' },
                                        { name: 'Start & Stop Off', id: 'START_STOP_OFF' },
                                        { name: 'Swirl Flap Off', id: 'SWIRL_FLAP_OFF' },
                                        { name: 'Vmax Off', id: 'VMAX_OFF' },
                                        { name: 'Véhicule de courtoisie', id: 'CAR_BORROW' },
                                    ]"
                                />
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                            <button type="button" class="btn btn-secondary" @click.prevent="generatePdf" :disabled="!canGeneratePdf()" v-if="!generatingPdf && clientRequest.data.worksheet_signed_path === null">{{ clientRequest.data.worksheet_generated_at !== null ? 'Regénérer' : 'Générer' }}</button>
                            <button type="button" class="btn btn-secondary btn-load"  v-if="generatingPdf && clientRequest.data.worksheet_signed_path === null">
                                <span class="d-flex align-items-center">
                                    <span class="flex-grow-1 me-2">
                                        Génération...
                                    </span>
                                    <span class="spinner-border flex-shrink-0" role="status">
                                        <span class="visually-hidden">Génération...</span>
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Signature -->
            <div id="modalSignature" class="modal fade" tabindex="-1" aria-labelledby="modalSignature" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalSignature">Signer le document</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div class="modal-body">
                            <DocusealForm
                                :with-title="false"
                                :with-send-copy-button="false"
                                :with-download-button="false"
                                @complete="signatureCompleted"
                                language="fr"
                                v-if="slugForm !== null"
                                :src="`https://docuseal.co/s/${slugForm}`"
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6" v-if="!loading">

            <Chat
                :messageable-id="clientRequest.data.id"
                messageable-type="App\Models\ClientRequest"
                :show-is-internal="false"
                :show-default-messages="true"
                :default-message-center-id="clientRequest.data.center_id"
            />
        </div>
    </div>
</template>

<style scoped>
</style>
