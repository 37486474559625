import InformationRequestEdit from "../Views/InformationRequest/InformationRequestEdit.vue";
import InformationRequestCreate from "../Views/InformationRequest/InformationRequestCreate.vue";
import InformationRequestShow from "../Views/InformationRequest/InformationRequestShow.vue";
import InformationRequestList from "../Views/InformationRequest/InformationRequestList.vue";

export const informationRequestRoutes = [
    {
        path: "/information-requests/edit/:id",
        name: "information-requests.edit",
        component: InformationRequestEdit,
        meta: {
            menu: {
                parent: 'information-requests',
                child: null
            },
            breadcrumb: {
                title: "Demandes d'informations",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Toutes les demandes",
                            link: {name: 'information-requests.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/information-requests/create",
        name: "information-requests.create",
        component: InformationRequestCreate,
        meta: {
            menu: {
                parent: 'information-requests',
                child: null
            },
            breadcrumb: {
                title: "Demandes d'informations",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Toutes les demandes",
                            link: {name: 'information-requests.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/information-requests/:id",
        name: "information-requests.show",
        component: InformationRequestShow,
        meta: {
            menu: {
                parent: 'information-requests',
                child: null
            },
            breadcrumb: {
                title: "Demandes d'informations",
                nav: {
                    all: {
                        active: "Détails de la demande",
                        inactive: [{
                            title: "Toutes les demandes",
                            link: {name: 'information-requests.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/information-requests",
        name: "information-requests.list",
        component: InformationRequestList,
        meta: {
            menu: {
                parent: 'information-requests',
                child: null
            },
            breadcrumb: {
                title: "Demandes d'informations",
                nav: {
                    all: {
                        active: "Toutes les demandes",
                        inactive: null
                    }
                }
            }
        },
    },
];
