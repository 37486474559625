<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import {useRouter} from "vue-router";
import {onBeforeMount, reactive, ref} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import FormDatePicker from "../../Components/FormDatePicker.vue";
import FormSelectCenters from "../../Components/FormSelectCenters.vue";
import FormTextarea from "../../Components/FormTextarea.vue";

const { httpErrorsHandler } = useHttpErrorsHandler();
const $toast = useToast();
const router = useRouter();

const loading = ref(false)
const isUploading = ref(false)
const form = reactive( {
    title: null,
    embed_code: null,
    google_form_link: null,
    end_date: null,
    centers: [],
})
const errors = ref(null)
function createSurvey() {
    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (key === 'centers') {
            return value.forEach((center) => {
                formData.append(`${key}[]`, center.id);
            });
        }
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    formData.append('_method', 'PUT');
    axios.post("/api/surveys/" + router.currentRoute.value.params.id, formData)
        .then(response => {
            $toast.success('Sondage créé avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.back();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}
const survey = ref(null)
const centersPreSelected = ref([])
function getSurvey() {
    loading.value = true;
    axios.get("/api/surveys/" + router.currentRoute.value.params.id)
        .then(response => {
            Object.entries(response.data.data).forEach(([key, value]) => {
                if (key === 'centers') {
                    return centersPreSelected.value = value;
                }
                if(form.hasOwnProperty(key)){
                    form[key] = value;
                }
            });
            survey.value = response.data;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
onBeforeMount(() => {
    getSurvey()
})
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <form v-on:submit.prevent="createSurvey">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-thumbs-up-down-outline me-2 align-middle fs-4"></i>Créer un sondage</h4>
                    </div>
                    <div class="card-body">
                        <loader v-show="loading" />
                        <div class="row" v-show="!loading">
                            <form-text-field
                                name="title"
                                label="Titre du sondage"
                                :label-hidden="false"
                                @update:field="form.title = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.title"
                                bs-class="col-md-12"
                            />

                            <form-select-centers
                                :errors="errors"
                                label="Centres"
                                name="centers"
                                :required="false"
                                :data="form.centers"
                                @update:field="form.centers = $event"
                                bs-class="col-md-12"
                                :disabled="false"
                                :label-hidden="false"
                                placeholder="Sélectionnez un ou plusieurs centres"
                                information-content="Si vous ne sélectionnez aucun centre, le sondage sera public."
                                :centers-pre-selected="centersPreSelected"
                            />

                            <form-date-picker
                                name="end_date"
                                label="Date de fin"
                                :label-hidden="false"
                                @update:field="form.end_date = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.end_date"
                                bs-class="col-md-4"
                                :disabled="false"
                            />

                            <form-text-field
                                name="google_form_link"
                                label="Lien Google Form"
                                :label-hidden="false"
                                @update:field="form.google_form_link = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.google_form_link"
                                bs-class="col-md-8"
                                type="text"
                                information-content="https://..."
                            />

                            <!--<form-textarea-->
                            <!--    name="embed_code"-->
                            <!--    label="Embed code"-->
                            <!--    :label-hidden="false"-->
                            <!--    @update:field="form.embed_code = $event"-->
                            <!--    :errors="errors"-->
                            <!--    :required="false"-->
                            <!--    :data="form.embed_code"-->
                            <!--    bs-class="col-lg-12"-->
                            <!--    :row="5"-->
                            <!--/>-->
                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
