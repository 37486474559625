<template>
    <div :class="[bsClass, margin]">
        <label :for="name" class="form-label" v-if="!labelHidden">
            {{ label }}
            <span class="text-danger" v-if="required"> *</span>
        </label>
        <select
            class="form-select"
            v-model="value"
            :class="[additionalClass, errorClassObject(), { 'select-placeholder': isPlaceholderSelected }]"
            :name="name"
            :id="name"
            :required="required"
            :disabled="disabled"
            :autocomplete="autocomplete"
            @change="updateField()"
        >
            <option :value="null" disabled hidden class="select-placeholder">{{ placeholder }}</option>
            <option v-for="(listeOptions, key) in listeOptions" :key="key" v-bind:value="listeOptions.value" class="text-dark myOption">{{ listeOptions.text }}</option>
        </select>
        <span class="invalid-feedback" v-html="errorMessage()"></span>
        <span class="form-text text-muted" v-if="informationContent !== null">
            {{ informationContent }}
        </span>
    </div>
</template>

<script>
import placeholder from "lodash/fp/placeholder.js";

export default {
    props: {
        name : {
            type: String,
            required: true
        },
        label : {
            type: String,
            required: true
        },
        labelHidden : {
            type: Boolean,
            required: false,
            default: false
        },
        required : {
            type: Boolean,
            required: false,
            default: false
        },
        informationContent : {
            type: String,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        },
        listeOptions: {
            type: Array,
            required: true,
            default: []
        },
        data: {
            required: false,
            default: null
        },
        disabled : {
            type: Boolean,
            required: false,
            default: false
        },
        autocomplete : {
            type: String,
            required: false,
            default: "off"
        },
        additionalClass : {
            type: String,
            required: false,
            default: ""
        },
        placeholder : {
            type: String,
            required: false,
            default: null
        },
        margin : {
            type: String,
            required: false,
            default: "mb-3"
        }
    },
    data() {
        return {
            value: null,
            isPlaceholderSelected: true,
        };
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            );
        }
    },
    methods: {
        updateField: function() {
            this.clearErrors(this.name);
            this.$emit("update:field", this.value);
            this.isPlaceholderSelected = this.value === null;
        },
        errorMessage: function() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        },
        clearErrors: function() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject: function() {
            if (this.hasError){
                return "is-invalid"
            }
            return "";
        }
    },
    watch: {
        data: {
            immediate: true,
            handler(newVal, oldVal) {
                this.value = newVal;
                this.isPlaceholderSelected = this.value === null;
            },
        }
    }
};
</script>

<style scoped>
.select-placeholder{
    color: #878a99;
}
.myOption {
    font-size: 14px;
}
</style>
