<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {computed, inject, onBeforeMount, reactive, ref} from "vue";
import Loader from "../../Components/Loader.vue";
import {useToast} from "vue-toast-notification";
import FormInputFilePond from "../../Components/FormInputFilePond.vue";
import {add} from "lodash";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const brandConfig = inject('brandConfig');
const router = useRouter();
const loading = ref(true)
const errors = ref(null)
const engine = ref(null)
function getCarEngine(carEngineId) {
    loading.value = true;
    axios.get("/api/chiptuning/" + carEngineId)
        .then(response => {
            engine.value = response.data;
            engine.value.data.options = JSON.parse(engine.value.data.options);
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onBeforeMount(() => {
    getCarEngine(router.currentRoute.value.params.id)
})

const activeTab = ref('stage1Tab')

function sortOptions(options) {
    return options.sort();
}

function calculatePrice(type, price) {

    let basePrice = price;
    let priceStage1 = 0;
    let priceStage2 = 0;
    let priceE85 = 0;
    let priceE85plus = 0;

    if (brandConfig.brand === 'rstronic') {
        basePrice = price - 100;
        priceStage1 = Math.max(basePrice, 350);
        priceStage2 = priceStage1 + 100;
        priceE85 = Math.max(priceStage1, 490);
        priceE85plus = Math.max(priceStage1 + 100, 590);

        if (price < 490) {
            priceE85 = 490;
            priceE85plus = 590;
        }
    }

    if (brandConfig.brand === 'shiftech') {
        basePrice = price + 9;
        priceStage1 = Math.max(basePrice, 399);
        priceStage2 = priceStage1 + 200;

        priceE85 = Math.max(priceStage1, 599);
        priceE85plus = Math.max(priceStage1 + 200, 799);

        if (price < 590) {
            priceE85 = 599;
            priceE85plus = 799;
        }
    }

    switch (type) {
        case 'stage1':
            return priceStage1;
        case 'stage2':
            return priceStage2;
        case 'e85':
            return priceE85;
        case 'e85plus':
            return priceE85plus;
        default:
            return price;
    }
}

const percentage = ref(0);
const loadingAttachments = ref(false)
const showUploadAttachments = ref(false)
const resetFileList = ref(false)
const form = reactive({
    attachments: []
})
function addAttachment(attachment) {
    loadingAttachments.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        return Array.from(value).forEach(attachment => {
            formData.append(`${key}[]`, attachment);
        });
    });

    const config = {
        onUploadProgress: progressEvent => percentage.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    }

    axios.post("/api/chiptuning/" + router.currentRoute.value.params.id +"/attachments", formData, config)
        .then(response => {
            form.attachments = [];
            resetFileList.value = true;
            $toast.success('Devis ajouté avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            engine.value.data.attachments = response.data.data.attachments;
            showUploadAttachments.value = false;
            loadingAttachments.value = false;
            resetFileList.value = false;
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loadingAttachments.value = false;
        });
}

function deleteAttachment(id, index){
    axios.delete("/api/attachments/" + id)
        .then(response => {
            engine.value.data.attachments.splice(index, 1);
            $toast.success('Fichier supprimé avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
</script>

<template>
    <div class="row">
        <div class="col-12" v-show="loading">
            <loader />
        </div>
        <div class="col-xl-3" v-if="!loading">
            <div class="card">
                <div class="card-body p-4">
                    <div>
                        <div class="mt-2 mx-auto text-center">
                            <img :src="'/format-image/'+engine.data.brand.data.logo+'?w=80&h=80&fm=webp&fit=crop'">
                        </div>
                        <div class="mt-4 text-center">
                            <h4 class="mb-1">{{ engine.data.brand.data.name }}</h4>
                            <p class="text-body fs-15 mb-0">
                                {{ engine.data.model.data.name }}<br>
                                <span class="text-muted fs-13">
                                    {{ engine.data.version.data.name === null ? engine.data.version.data.from : engine.data.version.data.name + ' - ' + engine.data.version.data.from  }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3 align-middle"><i class="mdi mdi-engine-outline fs-22 me-2 align-middle"></i>Info moteur</h5>
                    <div class="table-responsive">
                        <table class="table table-sm table-hover mb-0 table-borderless">
                            <tbody>
                                <tr>
                                    <th><span class="fw-medium">Type</span></th>
                                    <td>{{ engine.data.power_type_fr }}</td>
                                </tr>
                                <tr>
                                    <th><span class="fw-medium">Moteur</span></th>
                                    <td>{{ engine.data.name }}</td>
                                </tr>
                                <tr>
                                    <th><span class="fw-medium">Puissance</span></th>
                                    <td>{{ engine.data.horsepower }}<span class="text-muted"> ch</span></td>
                                </tr>
                                <tr>
                                    <th><span class="fw-medium">Gain</span></th>
                                    <td>{{ engine.data.torque }}<span class="text-muted"> Nm</span></td>
                                </tr>
                                <tr>
                                    <th><span class="fw-medium">Développé</span></th>
                                    <td>
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="engine.data.developed">Oui</span>
                                        <span class="badge fs-11 bg-danger-subtle text-danger" v-else>Non</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th><span class="fw-medium">E85</span></th>
                                    <td>
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="engine.data.developed && engine.data.e85_available">Oui</span>
                                        <span class="badge fs-11 bg-danger-subtle text-danger" v-else>Non</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th><span class="fw-medium">E85+</span></th>
                                    <td>
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="engine.data.developed && engine.data.e85plus_available">Oui</span>
                                        <span class="badge fs-11 bg-danger-subtle text-danger" v-else>Non</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th><span class="fw-medium">Stage 2</span></th>
                                    <td>
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="engine.data.developed && engine.data.stage2_available">Oui</span>
                                        <span class="badge fs-11 bg-danger-subtle text-danger" v-else>Non</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th><span class="fw-medium">Boite de vitesse</span></th>
                                    <td>
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="engine.data.dsg_available">Oui</span>
                                        <span class="badge fs-11 bg-danger-subtle text-danger" v-else>Non</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3 align-middle"><i class="mdi mdi-format-list-checks fs-22 me-2 align-middle"></i>Options disponibles</h5>
                    <ul class="list-group">
                        <li class="list-group-item" v-for="option in sortOptions(engine.data.options)">
                            <i class="mdi mdi-check-bold align-middle lh-1 me-2"></i> {{ option }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-xl-9" v-if="!loading">
            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs nav-tabs-custom card-header-tabs border-bottom-0 nav-secondary text-dark" role="tablist">
                        <li class="nav-item" @click.prevent="activeTab = 'stage1Tab'">
                            <a class="nav-link stage1" :class="{ 'active': activeTab === 'stage1Tab' }" data-bs-toggle="tab" href="#stage1Tab" role="tab">
                                Stage 1
                            </a>
                        </li>
                        <li class="nav-item" @click.prevent="activeTab = 'stage2Tab'">
                            <a class="nav-link stage2" :class="{ 'active': activeTab === 'stage2Tab' }" data-bs-toggle="tab" href="#stage2Tab" role="tab" v-if="engine.data.developed && engine.data.stage2_available">
                                Stage 2
                            </a>
                        </li>
                        <li class="nav-item" @click.prevent="activeTab = 'e85Tab'">
                            <a class="nav-link e85" :class="{ 'active': activeTab === 'e85Tab' }" data-bs-toggle="tab" href="#e85Tab" role="tab" v-if="engine.data.developed && engine.data.e85_available">
                                E85
                            </a>
                        </li>
                        <li class="nav-item" @click.prevent="activeTab = 'e85+Tab'">
                            <a class="nav-link e85plus" :class="{ 'active': activeTab === 'e85+Tab' }" data-bs-toggle="tab" href="#e85+Tab" role="tab" v-if="engine.data.developed && engine.data.e85plus_available">
                                E85+
                            </a>
                        </li>
                        <li class="nav-item" @click.prevent="activeTab = 'dsg'" v-if="engine.data.dsg_available">
                            <a class="nav-link" :class="{ 'active': activeTab === 'dsg' }" data-bs-toggle="tab" href="#dsg" role="tab">
                                Boite de vitesse
                            </a>
                        </li>
                        <li class="nav-item" @click.prevent="activeTab = 'quotes'">
                            <a class="nav-link" :class="{ 'active': activeTab === 'quotes' }" data-bs-toggle="tab" href="#quotes" role="tab">
                                Devis
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body p-4">
                    <div class="tab-content">
                        <!--Stage1-->
                        <div class="tab-pane" :class="{ 'active': activeTab === 'stage1Tab' }" id="stage1Tab" role="tabpanel">
                            <p class="text-body mb-3">
                                La reprogrammation moteur Stage 1 consiste à optimiser la gestion du moteur pour améliorer les performances de votre véhicule et diminuer la consommation de carburant . C'est l'option parfaite pour une meilleure expérience de conduite sans modifications mécaniques.
                            </p>
                            <div class="table-responsive" v-if="engine.data.developed">
                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col" class="col-6"></th>
                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td class="fw-bold">Origine</td>
                                        <td class="text-center text-body">{{ engine.data.horsepower }} ch</td>
                                        <td class="text-center text-body">{{ engine.data.torque }} Nm</td>
                                    </tr>
                                    <tr>
                                        <td class="fw-bold text-dark">Gain</td>
                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-1 fw-bold">+</span> {{ engine.data.stage1_horsepower_gain }} ch</td>
                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-1 fw-bold">+</span> {{ engine.data.stage1_torque_gain }}  Nm</td>
                                    </tr>
                                    <tr>
                                        <td class="fw-bold stage-1">Stage 1</td>
                                        <td class="text-center fw-bolder stage-1">{{ engine.data.stage1_horsepower_total }} ch</td>
                                        <td class="text-center fw-bolder stage-1">{{ engine.data.stage1_torque_total }} Nm</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p class="fs-15">
                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-1"></i> <span class=" fw-bold">{{ calculatePrice('stage1', engine.data.price) }} €</span> <span class="text-muted">TTC</span>
                                </p>
                            </div>
                            <div v-else>
                                <p class="fs-15">
                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-1"></i> <span class=" fw-bold">Bientôt disponible</span>
                                </p>
                            </div>
                        </div>
                        <!--Stage2-->
                        <div class="tab-pane" :class="{ 'active': activeTab === 'stage2Tab' }" id="stage2Tab" role="tabpanel" v-if="engine.data.developed && engine.data.stage2_available">
                            <p class="text-body mb-3">
                                Le Stage 2 dépasse la simple optimisation logicielle. Il peut être nécessaire d’installer des pièces performances telles qu’un échappement, une admission à haut débit et un échangeur gros volume. Cette préparation est idéale pour les conducteurs en quête d'une augmentation significative de puissance.
                            </p>
                            <div class="table-responsive">
                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col" class="col-6"></th>
                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td class="fw-bold">Origine</td>
                                        <td class="text-center text-body">{{ engine.data.horsepower }} ch</td>
                                        <td class="text-center text-body">{{ engine.data.torque }} Nm</td>
                                    </tr>
                                    <tr>
                                        <td class="fw-bold text-dark">Gain</td>
                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ engine.data.stage2_horsepower_gain }} ch</td>
                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ engine.data.stage2_torque_gain }}  Nm</td>
                                    </tr>
                                    <tr>
                                        <td class="fw-bold stage-2">Stage 2</td>
                                        <td class="text-center fw-bolder stage-2">{{ engine.data.stage2_horsepower_total }} ch</td>
                                        <td class="text-center fw-bolder stage-2">{{ engine.data.stage2_torque_total }} Nm</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p class="fs-15">
                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-2"></i> <span class=" fw-bold">{{ calculatePrice('stage2', engine.data.price) }} €</span> <span class="text-muted">TTC</span>
                                </p>
                            </div>
                        </div>
                        <!--E85-->
                        <div class="tab-pane" :class="{ 'active': activeTab === 'e85Tab' }" id="e85Tab" role="tabpanel" v-if="engine.data.developed && engine.data.e85_available">
                            <p class="text-body mb-3">
                                La conversion E85 Flex-Fuel offre à votre véhicule la flexibilité de rouler au Bioéthanol, à l'essence, ou à un mélange des deux, sans contrainte. Cette adaptation réduit de moitié le coût du carburant et diminue notablement les émissions de CO2, réduisant ainsi l'impact environnemental.
                            </p>
                            <div class="table-responsive">
                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col" class="col-6"></th>
                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td class="fw-bold">Origine</td>
                                        <td class="text-center text-body">{{ engine.data.horsepower }} ch</td>
                                        <td class="text-center text-body">{{ engine.data.torque }} Nm</td>
                                    </tr>
                                    <tr>
                                        <td class="fw-bold text-dark">Gain</td>
                                        <td class="text-center text-dark" style="white-space: nowrap;">-</td>
                                        <td class="text-center text-dark" style="white-space: nowrap;">-</td>
                                    </tr>
                                    <tr>
                                        <td class="fw-bold e-85">E85</td>
                                        <td class="text-center fw-bolder e-85">{{ engine.data.horsepower }} ch</td>
                                        <td class="text-center fw-bolder e-85">{{ engine.data.torque }} Nm</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p class="fs-15">
                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle e-85"></i> <span class=" fw-bold">{{ calculatePrice('e85', engine.data.price) }} €</span> <span class="text-muted">TTC</span>
                                </p>
                            </div>
                        </div>
                        <!--E85+-->
                        <div class="tab-pane" :class="{ 'active': activeTab === 'e85+Tab' }" id="e85+Tab" role="tabpanel" v-if="engine.data.developed && engine.data.e85plus_available">
                            <p class="text-body mb-3">
                                La conversion E85+ Flex-Fuel permet à votre véhicule de fonctionner avec du Bioéthanol, de l'essence, ou un mélange des deux carburants, tout en bénéficiant de l’augmentation de puissance d’un stage 1. En plus de réduire le coût du carburant de moitié, elle diminue aussi les émissions de CO2, tout en améliorant significativement les performances.
                            </p>
                            <div class="table-responsive">
                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col" class="col-6"></th>
                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td class="fw-bold">Origine</td>
                                        <td class="text-center text-body">{{ engine.data.horsepower }} ch</td>
                                        <td class="text-center text-body">{{ engine.data.torque }} Nm</td>
                                    </tr>
                                    <tr>
                                        <td class="fw-bold text-dark">Gain</td>
                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="e-85plus fw-bold">+</span> {{ engine.data.stage1_horsepower_gain }} ch</td>
                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="e-85plus fw-bold">+</span> {{ engine.data.stage1_torque_gain }}  Nm</td>
                                    </tr>
                                    <tr>
                                        <td class="fw-bold e-85plus">E85+</td>
                                        <td class="text-center fw-bolder e-85plus">{{ engine.data.stage1_horsepower_total }} ch</td>
                                        <td class="text-center fw-bolder e-85plus">{{ engine.data.stage1_torque_total }} Nm</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p class="fs-15">
                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle e-85plus"></i> <span class=" fw-bold">{{ calculatePrice('e85plus', engine.data.price) }} €</span> <span class="text-muted">TTC</span>
                                </p>
                            </div>
                        </div>
                        <!--DSG-->
                        <div class="tab-pane" :class="{ 'active': activeTab === 'dsg' }" id="dsg" role="tabpanel" v-if="engine.data.dsg_available">
                            <p class="text-body mb-3">
                                La reprogrammation des boîtes automatiques modernes optimise leur potentiel en réduisant le temps de passage des rapports, augmentant le régime et le couple maximaux et améliorant la réactivité.
                            </p>
                            <p class="fs-15">
                                <i class="mdi mdi-arrow-right-thin fs-20 align-middle"></i> <span class=" fw-bold">390 €</span> <span class="text-muted">TTC</span>
                            </p>
                        </div>
                        <!--Devis-->
                        <div class="tab-pane" :class="{ 'active': activeTab === 'dsg' }" id="quotes" role="tabpanel">
                            <p class="text-body mb-3">
                                Vous trouverez ci-dessous les devis qui ont déja été établis pour ce véhicule. <br>
                                <button type="button" class="mt-2 btn btn-sm btn-dark bg-gradient waves-effect waves-light" @click.prevent="showUploadAttachments = !showUploadAttachments" v-if="engine.can.update">{{ showUploadAttachments ? 'Cacher formulaire' : 'Ajouter devis' }}</button>
                            </p>
                            <loader v-if="loadingAttachments && showUploadAttachments"/>
                            <form v-on:submit.prevent="addAttachment" v-show="!loadingAttachments && showUploadAttachments">
                                <form-input-file-pond
                                    name="form.attachments"
                                    label="Devis"
                                    :label-hidden="false"
                                    :errors="errors"
                                    :required="false"
                                    :data="form.attachments"
                                    @update:field="form.attachments = $event"
                                    bs-class="col-md-12"
                                    :disabled="false"
                                    :multiple="true"
                                    :reset-file-list="resetFileList"
                                />
                                <div class="d-flex gap-2 justify-content-end mb-3">
                                    <button type="submit" class="btn btn-secondary" :disabled="!form.attachments.length > 0">Valider</button>
                                </div>
                            </form>

                            <div class="vstack gap-2" v-if="engine.data.attachments && engine.data.attachments.length > 0">

                                <div class="border rounded border-dashed p-2" v-for="(attachment, index) in engine.data.attachments" :key="index">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 me-3">
                                            <div class="avatar-sm">
                                                <div class="avatar-title bg-light text-secondary rounded fs-24 shadow">
                                                    <!--Icon depending mime type-->
                                                    <i class="ri-image-2-line" v-if="attachment.data.mime_type.includes('image')"></i>
                                                    <i class="ri-video-line" v-else-if="attachment.data.mime_type.includes('video')"></i>
                                                    <i class="ri-folder-music-line" v-else-if="attachment.data.mime_type.includes('audio')"></i>
                                                    <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('zip')"></i>
                                                    <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('pdf')"></i>
                                                    <i class="ri-file-text-line" v-else></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden">
                                            <h5 class="fs-13 mb-1"><a :href="attachment.data.path" target="_blank" class="text-body text-truncate d-block">{{ attachment.data.name }}</a></h5>
                                            <div>{{ attachment.data.size }}</div>
                                        </div>
                                        <div class="flex-shrink-0 ms-2">
                                            <div class="d-flex gap-1">
                                                <a href="#" class="btn btn-icon text-danger btn-sm fs-18 shadow-none" v-if="engine.can.delete" @click.prevent="deleteAttachment(attachment.data.id, index)">
                                                    <i class="ri-delete-bin-2-fill"></i>
                                                </a>
                                                <a :href="attachment.data.path" target="_blank" class="btn btn-icon text-muted btn-sm fs-18 shadow-none" download>
                                                    <i class="ri-download-2-line"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
