import { defineStore } from 'pinia'

export const useAuthUserStore = defineStore('AuthUserStore', {
    state: () => ({
        user: {},
    }),
    getters: {
        authUser: (state) => {
            return state.user
        },
    },
    actions: {
        fetchAuthUser() {
            axios.get('/api/users/auth')
                .then(response => {
                    this.user = response.data.data
                })
                .catch(errors => {
                    console.log('Unable to fetch user');
                })
        }
    },
    persist: true,
})
