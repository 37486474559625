<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {computed, inject, onBeforeMount, onMounted, reactive, ref} from "vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Loader from "../../Components/Loader.vue";
import FormTextFieldIcon from "../../Components/FormTextFieldIcon.vue";
import {useToast} from "vue-toast-notification";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const brandConfig = inject('brandConfig');
const router = useRouter();
const loading = ref(true)
const errors = ref(null)
const user = ref(null)
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
function getUser(userId) {
    loading.value = true;
    axios.get("/api/users/" + userId)
        .then(response => {
            user.value = response.data;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}

onBeforeMount(() => {
    getUser(router.currentRoute.value.params.id)
})

const formPassword = reactive({
    password: null,
    password_confirmation: null,
})

function updatePassword() {
    loading.value = true;
    axios.patch("/api/users/" + router.currentRoute.value.params.id + "/password", formPassword)
        .then(response => {
            $toast.success('Mot de passe modifié', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            formPassword.password = null;
            formPassword.password_confirmation = null;
            loading.value = false;
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}
const icon = ref('ri-eye-off-fill')
const inputTypePasswordForm = ref('password')
function showPassword() {
    if (inputTypePasswordForm.value === 'password') {
        inputTypePasswordForm.value = 'text'
        icon.value = 'ri-eye-fill'
    } else {
        inputTypePasswordForm.value = 'password'
        icon.value = 'ri-eye-off-fill'
    }
}

const activeTab = ref('centersTab')
</script>

<template>
    <div class="row">
        <div class="col-12" v-show="loading">
            <loader />
        </div>
        <div class="col-xxl-3" v-if="!loading">
            <div class="card">
                <div class="card-body p-4">
                    <div class="text-center">
                        <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                            <img :src="'/format-image/'+user.data.profile_picture+'?w=110&h=110&fm=webp&fit=crop'"  class="rounded-circle avatar-xl img-thumbnail user-profile-image  shadow" alt="user-profile-image">
                        </div>
                        <h5 class="fs-16 mb-1">{{ user.data.first_name + ' ' + user.data.last_name }}</h5>
                        <p :class="'badge fs-11 bg-'+user.data.role_badge_color+'-subtle  text-'+user.data.role_badge_color">{{ user.data.role_label_fr }}</p>
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item avatar-xs" title="Appeler">
                                <a :href="'tel:'+user.data.phone_number" class="avatar-title bg-success-subtle text-success fs-15 rounded">
                                    <i class="ri-phone-line"></i>
                                </a>
                            </li>
                            <li class="list-inline-item avatar-xs" title="Envoyer un email" v-if="user.data.email !== null">
                                <a :href="'mailto:'+user.data.email" class="avatar-title bg-warning-subtle text-warning fs-15 rounded">
                                    <i class="ri-mail-line"></i>
                                </a>
                            </li>
                            <li class="list-inline-item avatar-xs" title="Modifier" v-if="user.can.update">
                                <router-link :to="{ name: 'users.edit', params:{ id: user.data.id } }" class="avatar-title bg-secondary-subtle text-secondary fs-15 rounded" v-if="authUser.role === 'admin' || authUser.role === 'back_office'">
                                    <i class="ri-edit-2-fill"></i>
                                </router-link>
                                <router-link :to="{ name: 'users.profile' }" class="avatar-title bg-secondary-subtle text-secondary fs-15 rounded" v-else>
                                    <i class="ri-edit-2-fill"></i>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3">Info</h5>
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                            <tr>
                                <th class="ps-0" scope="row">Téléphone :</th>
                                <td class="text-body">
                                    <a :href="'tel:'+user.data.phone_number" class="text-secondary">{{ user.data.phone_number }}</a>
                                </td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">E-mail :</th>
                                <td class="text-body">
                                    <a :href="'mailto:'+user.data.email" class="text-secondary">{{ user.data.email }}</a>
                                </td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Équipe de direction :</th>
                                <td class="text-body">
                                    {{ user.data.management_team ? 'Oui' : 'Non' }}
                                </td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Date de naissance :</th>
                                <td class="text-body">{{ user.data.birth_date_fr }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Créé le</th>
                                <td class="text-body">{{ user.data.created_at_fr }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xxl-9" v-if="!loading">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3">A propos</h5>
                    <p v-html="user.data.bio === null ? 'Aucune description' : user.data.bio.replace(/\n/g, '<br />')"></p>
                    <div class="row">
                        <div class="col-6 col-md-4">
                            <div class="d-flex mt-2">
                                <div class="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div class="avatar-title bg-light rounded-circle fs-16 text-secondary shadow">
                                        <i class="ri-user-2-fill"></i>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="mb-1">Poste occupé :</p>
                                    <h6 class="text-truncate mb-0">{{ user.data.job_title === null ? '--' : user.data.job_title }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs nav-tabs-custom card-header-tabs border-bottom-0 nav-secondary text-dark" role="tablist">
                        <li class="nav-item" @click.prevent="activeTab = 'centersTab'">
                            <a class="nav-link" :class="{ 'active': activeTab === 'centersTab' }" data-bs-toggle="tab" href="#centersTab" role="tab">
                                <i class="fas fa-home"></i> {{ user.data.centers.length > 1 ? 'Centres' : 'Centre' }}
                            </a>
                        </li>
                        <li class="nav-item" @click.prevent="activeTab = 'changePassword'">
                            <a class="nav-link" :class="{ 'active': activeTab === 'changePassword' }" data-bs-toggle="tab" href="#changePassword" role="tab">
                                <i class="far fa-user"></i> Mot de passe
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body p-4">
                    <div class="tab-content">
                        <div class="tab-pane" :class="{ 'active': activeTab === 'centersTab' }" id="centersTab" role="tabpanel">
                            <ul class="list-group list-group-flush">

                                <router-link :to="{ name: 'centers.show', params:{ id: center.data.id } }" class="list-group-item" v-for="(center, index) in user.data.centers" :key="index">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0">
                                            <img :src="brandConfig.logoBlackSmall" alt="" class="avatar-xs">
                                        </div>
                                        <div class="flex-grow-1 ms-2 name">{{ center.data.name }}</div>
                                    </div>
                                </router-link>

                            </ul>
                        </div>

                        <div class="tab-pane" :class="{ 'active': activeTab === 'changePassword' }" id="changePassword" role="tabpanel">
                            <form v-on:submit.prevent="updatePassword">
                                <div class="row">
                                    <form-text-field-icon
                                        name="password"
                                        label="Nouveau mot de passe"
                                        :label-hidden="false"
                                        @update:field="formPassword.password = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formPassword.password"
                                        bs-class="col-lg-6"
                                        :type="inputTypePasswordForm"
                                        :icon-class="icon"
                                        icon-description="Cliquez pour afficher/masquer"
                                        @clic:icon="showPassword()"
                                        autocomplete="new-password"
                                        :tabindex="2"
                                    />

                                    <form-text-field-icon
                                        name="password_confirmation"
                                        label="Confirmer"
                                        :label-hidden="false"
                                        @update:field="formPassword.password_confirmation = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formPassword.password_confirmation"
                                        bs-class="col-lg-6"
                                        :type="inputTypePasswordForm"
                                        :icon-class="icon"
                                        icon-description="Cliquez pour afficher/masquer"
                                        @clic:icon="showPassword()"
                                        autocomplete="new-password"
                                        :tabindex="3"
                                    />

                                    <div class="col-lg-12">
                                        <div class="d-flex gap-2 justify-content-end">
                                            <button type="submit" class="btn btn-secondary">Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
