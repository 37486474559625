import SocialNetworkMediaEdit from "../Views/SocialNetworkMedia/SocialNetworkMediaEdit.vue";
import SocialNetworkMediaCreate from "../Views/SocialNetworkMedia/SocialNetworkMediaCreate.vue";
import SocialNetworkMediaShow from "../Views/SocialNetworkMedia/SocialNetworkMediaShow.vue";
import SocialNetworkMediaList from "../Views/SocialNetworkMedia/SocialNetworkMediaList.vue";

export const socialNetworkMediaRoutes = [
    {
        path: "/social-network-media/edit/:id",
        name: "social-network-media.edit",
        component: SocialNetworkMediaEdit,
        meta: {
            menu: {
                parent: 'social-network-media',
                child: 'social-network-media'
            },
            breadcrumb: {
                title: "Bibliothèque medias",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Toutes les médias",
                            link: {name: 'social-network-media.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/social-network-media/create",
        name: "social-network-media.create",
        component: SocialNetworkMediaCreate,
        meta: {
            menu: {
                parent: 'social-network-media',
                child: 'social-network-media'
            },
            breadcrumb: {
                title: "Bibliothèque medias",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Toutes les médias",
                            link: {name: 'social-network-media.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/social-network-media/:id",
        name: "social-network-media.show",
        component: SocialNetworkMediaShow,
        meta: {
            menu: {
                parent: 'social-network-media',
                child: 'social-network-media'
            },
            breadcrumb: {
                title: "Bibliothèque médias",
                nav: {
                    all: {
                        active: "Fiche du média",
                        inactive: [{
                            title: "Toutes les médias",
                            link: {name: 'social-network-media.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/social-network-media",
        name: "social-network-media.list",
        component: SocialNetworkMediaList,
        meta: {
            menu: {
                parent: 'social-network-media',
                child: 'social-network-media'
            },
            breadcrumb: {
                title: "Bibliothèque medias",
                nav: {
                    all: {
                        active: "Toutes les médias",
                        inactive: []
                    }
                }
            }
        },
    }
];
