import ClientList from "../Views/Client/ClientList.vue";
import ClientCreate from "../Views/Client/ClientCreate.vue";
import ClientEdit from "../Views/Client/ClientEdit.vue";
import ClientShow from "../Views/Client/ClientShow.vue";

export const clientRoutes = [
    {
        path: "/clients/edit/:id",
        name: "clients.edit",
        component: ClientEdit,
        meta: {
            menu: {
                parent: 'clients',
                child: null
            },
            breadcrumb: {
                title: "Clients",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Tous les clients",
                            link: {name: 'clients.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/clients/create",
        name: "clients.create",
        component: ClientCreate,
        meta: {
            menu: {
                parent: 'clients',
                child: null
            },
            breadcrumb: {
                title: "Clients",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Tous les clients",
                            link: {name: 'clients.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/clients/:id",
        name: "clients.show",
        component: ClientShow,
        meta: {
            menu: {
                parent: 'clients',
                child: null
            },
            breadcrumb: {
                title: "Clients",
                nav: {
                    all: {
                        active: "Fiche utilisateur",
                        inactive: [{
                            title: "Tous les clients",
                            link: {name: 'clients.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/clients",
        name: "clients.list",
        component: ClientList,
        meta: {
            menu: {
                parent: 'clients',
                child: null
            },
            breadcrumb: {
                title: "Clients",
                nav: {
                    all: {
                        active: "Tous les clients",
                        inactive: []
                    }
                }
            }
        },
    }
];
