import TicketList from "../Views/Ticket/TicketList.vue";
import TicketCreate from "../Views/Ticket/TicketCreate.vue";
import TicketEdit from "../Views/Ticket/TicketEdit.vue";
import TicketShow from "../Views/Ticket/TicketShow.vue";

export const ticketRoutes = [
    {
        path: "/tickets/edit/:id",
        name: "tickets.edit",
        component: TicketEdit,
        meta: {
            menu: {
                parent: 'tickets',
                child: 'tickets'
            },
            breadcrumb: {
                title: "Tickets",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Tous les tickets",
                            link: {name: 'tickets.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/tickets/create",
        name: "tickets.create",
        component: TicketCreate,
        meta: {
            menu: {
                parent: 'tickets',
                child: 'tickets'
            },
            breadcrumb: {
                title: "Tickets",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Tous les tickets",
                            link: {name: 'tickets.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/tickets/:id",
        name: "tickets.show",
        component: TicketShow,
        meta: {
            menu: {
                parent: 'tickets',
                child: 'tickets'
            },
            breadcrumb: {
                title: "Tickets",
                nav: {
                    all: {
                        active: "Détails ticket",
                        inactive: [{
                            title: "Tous les tickets",
                            link: {name: 'tickets.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/tickets",
        name: "tickets.list",
        component: TicketList,
        meta: {
            menu: {
                parent: 'tickets',
                child: 'tickets'
            },
            breadcrumb: {
                title: "Tickets",
                nav: {
                    all: {
                        active: "Tous les tickets",
                        inactive: []
                    }
                }
            }
        },
    }
];
