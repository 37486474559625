<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {computed, inject, onBeforeMount, onMounted, reactive, ref} from "vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import FormDatePicker from "../../Components/FormDatePicker.vue";
import FormInputFile from "../../Components/FormInputFile.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import FormTextFieldIcon from "../../Components/FormTextFieldIcon.vue";
import {useToast} from "vue-toast-notification";
import FormPhoneField from "../../Components/FormPhoneField.vue";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const brandConfig = inject('brandConfig');
const router = useRouter();
const loading = ref(true)
const errors = ref(null)
const isUploading = ref(false)
const percentage = ref(0)
const formPersonalInfo = reactive({
    first_name: null,
    last_name: null,
    email: null,
    birth_date: null,
    gender: null,
    phone_number: null,
    profile_picture: null,
    bio: null,
    job_title: null,
    email_exists: null,
    role: null,
})


function updatePersonalDetails() {

    if (formPersonalInfo.avatar !== null) {
        isUploading.value = true;
    }

    loading.value = true;
    let formData = new FormData();

    Object.entries(formPersonalInfo).forEach(([key, value]) => {
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    const config = {
        onUploadProgress: progressEvent => percentage.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    }
    formData.append('_method', 'PUT');
    axios.post("/api/users/" + userStore.authUser.id, formData, config)
        .then(response => {
            $toast.success('Données personnelles mises à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            // Dispatch event to update user in store
            userStore.fetchAuthUser()
            getUser(userStore.authUser.id)
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}

const user = ref(null)
const userStore = useAuthUserStore()
function getUser(userId) {
    loading.value = true;
    axios.get("/api/users/" + userId)
        .then(response => {
            Object.entries(response.data.data).forEach(([key, value]) => {
                if(formPersonalInfo.hasOwnProperty(key) && key !== 'profile_picture'){
                    formPersonalInfo[key] = value;
                }
            });
            user.value = response.data;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}

onBeforeMount(() => {
    getUser(userStore.authUser.id)
})

const formPassword = reactive({
    current_password: null,
    password: null,
    password_confirmation: null,
})

function updatePassword() {
    loading.value = true;
    axios.patch("/api/users/" + userStore.authUser.id + "/password", formPassword)
        .then(response => {
            $toast.success('Mot de passe modifié', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            formPassword.current_password = null;
            formPassword.password = null;
            formPassword.password_confirmation = null;
            loading.value = false;
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}
const icon = ref('ri-eye-off-fill')
const inputTypePasswordForm = ref('password')
function showPassword() {
    if (inputTypePasswordForm.value === 'password') {
        inputTypePasswordForm.value = 'text'
        icon.value = 'ri-eye-fill'
    } else {
        inputTypePasswordForm.value = 'password'
        icon.value = 'ri-eye-off-fill'
    }
}

const activeTab = ref('personalDetails')

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
</script>

<template>
    <div class="row">
        <div class="col-12" v-show="loading">
            <loader :is-uploading="isUploading" :percentage="percentage" />
        </div>
        <div class="col-xxl-3" v-if="!loading">
            <div class="card">
                <div class="card-body p-4">
                    <div class="text-center">
                        <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                            <img :src="'/format-image/'+user.data.profile_picture+'?w=110&h=110&fm=webp&fit=crop'"  class="rounded-circle avatar-xl img-thumbnail user-profile-image  shadow" alt="user-profile-image">
                        </div>
                        <h5 class="fs-16 mb-1">{{ user.data.first_name + ' ' + user.data.last_name }}</h5>
                        <span :class="'badge fs-11 bg-'+user.data.role_badge_color+'-subtle  text-'+user.data.role_badge_color">{{ user.data.role_label_fr }}</span>
                    </div>
                </div>
            </div>
            <!--end card-->
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3">Info</h5>
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                            <tr>
                                <th class="ps-0" scope="row">Téléphone :</th>
                                <td class="text-body">{{ user.data.phone_number }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">E-mail :</th>
                                <td class="text-body">{{ user.data.email }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Date de naissance :</th>
                                <td class="text-body">{{ user.data.birth_date_fr }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Poste occupé :</th>
                                <td class="text-body">{{ user.data.job_title }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Créé le</th>
                                <td class="text-body">{{ user.data.created_at_fr }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3">{{ user.data.centers.length > 1 ? 'Mes centres' : 'Mon centre' }}</h5>
                    <ul class="list-group list-group-flush">

                        <div class="list-group-item" v-for="(center, index) in user.data.centers" :key="index">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <img :src="brandConfig.logoBlackSmall" alt="" class="avatar-xs">
                                </div>
                                <div class="flex-grow-1 ms-2 name">{{ center.data.name }}</div>
                            </div>
                        </div>

                    </ul>
                </div>
            </div>
        </div>

        <div class="col-xxl-9" v-if="!loading">
            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs nav-tabs-custom card-header-tabs border-bottom-0 nav-secondary text-dark" role="tablist">
                        <li class="nav-item" @click.prevent="activeTab = 'personalDetails'">
                            <a class="nav-link" :class="{ 'active': activeTab === 'personalDetails' }" data-bs-toggle="tab" href="#personalDetails" role="tab">
                                <i class="fas fa-home"></i> Informations personnelles
                            </a>
                        </li>
                        <li class="nav-item" @click.prevent="activeTab = 'changePassword'">
                            <a class="nav-link" :class="{ 'active': activeTab === 'changePassword' }" data-bs-toggle="tab" href="#changePassword" role="tab">
                                <i class="far fa-user"></i> Mot de passe
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body p-4">
                    <div class="tab-content">
                        <div class="tab-pane" :class="{ 'active': activeTab === 'personalDetails' }" id="personalDetails" role="tabpanel">
                            <form v-on:submit.prevent="updatePersonalDetails">
                                <div class="row">
                                    <form-text-field
                                        name="first_name"
                                        label="Prénom"
                                        :label-hidden="false"
                                        @update:field="formPersonalInfo.first_name = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formPersonalInfo.first_name"
                                        bs-class="col-lg-4"
                                        :disabled="authUser.role !== 'admin'"
                                    />

                                    <form-text-field
                                        name="last_name"
                                        label="Nom"
                                        :label-hidden="false"
                                        @update:field="formPersonalInfo.last_name = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formPersonalInfo.last_name"
                                        bs-class="col-lg-4"
                                        :disabled="authUser.role !== 'admin'"
                                    />

                                    <form-text-field
                                        name="email"
                                        label="Email"
                                        :label-hidden="false"
                                        @update:field="formPersonalInfo.email = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formPersonalInfo.email"
                                        bs-class="col-lg-4"
                                        type="email"
                                        :disabled="authUser.role !== 'admin'"
                                    />
                                    <form-select-field
                                        name="gender"
                                        label="Civilité"
                                        :label-hidden="false"
                                        @update:field="formPersonalInfo.gender = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formPersonalInfo.gender"
                                        bs-class="col-lg-4"
                                        :disabled="authUser.role !== 'admin'"
                                        :liste-options="[
                                            { text: 'Monsieur', value: 'man' },
                                            { text: 'Madame', value: 'woman' },
                                            { text: 'Autre', value: 'other' },
                                        ]"
                                    />
                                    <form-date-picker
                                        name="birth_date"
                                        label="Date de naissance"
                                        :label-hidden="false"
                                        @update:field="formPersonalInfo.birth_date = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formPersonalInfo.birth_date"
                                        bs-class="col-lg-4"
                                        :disabled="authUser.role !== 'admin'"
                                    />

                                    <form-phone-field
                                        name="phone_number"
                                        label="Numéro de téléphone"
                                        :label-hidden="false"
                                        @update:field="formPersonalInfo.phone_number = $event"
                                        :errors="errors"
                                        :required="false"
                                        bs-class="col-lg-4"
                                        :data="formPersonalInfo.phone_number"
                                    />

                                    <form-text-field
                                        name="job_title"
                                        label="Poste occupé"
                                        :label-hidden="false"
                                        @update:field="formPersonalInfo.job_title = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formPersonalInfo.job_title"
                                        bs-class="col-lg-6"
                                        type="text"
                                        :disabled="authUser.role !== 'admin'"
                                    />
                                    <form-input-file
                                        name="profile_picture"
                                        label="Photo de profil"
                                        :label-hidden="false"
                                        :errors="errors"
                                        :required="false"
                                        :data="formPersonalInfo.profile_picture"
                                        @update:field="formPersonalInfo.profile_picture = $event"
                                        bs-class="col-lg-6"
                                        information-content="Taille max : 5Mo. Formats acceptés : .jpg, .jpeg, .png, .webp"
                                        :disabled="false"
                                        accepted=".jpg, .jpeg, .png, .webp"
                                    />
                                    <form-textarea
                                        name="bio"
                                        label="À propos de moi"
                                        :label-hidden="false"
                                        @update:field="formPersonalInfo.bio = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formPersonalInfo.bio"
                                        bs-class="col-lg-12"
                                        :row="3"
                                        :disabled="authUser.role !== 'admin'"
                                    />
                                    <div class="d-flex gap-2 justify-content-end">
                                        <button type="submit" class="btn btn-secondary">Valider</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="tab-pane" :class="{ 'active': activeTab === 'changePassword' }" id="changePassword" role="tabpanel">
                            <form v-on:submit.prevent="updatePassword">
                                <div class="row">
                                    <form-text-field-icon
                                        v-if="authUser.role !== 'admin'"
                                        name="current_password"
                                        label="Mot de passe actuel"
                                        :label-hidden="false"
                                        @update:field="formPassword.current_password = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formPassword.current_password"
                                        bs-class="col-lg-4"
                                        :type="inputTypePasswordForm"
                                        :icon-class="icon"
                                        icon-description="Cliquez pour afficher/masquer"
                                        @clic:icon="showPassword()"
                                        autocomplete="current-password"
                                        :tabindex="1"
                                    />

                                    <form-text-field-icon
                                        :bs-class="authUser.role !== 'admin' ? 'col-lg-4' : 'col-lg-6'"
                                        name="password"
                                        label="Nouveau mot de passe"
                                        :label-hidden="false"
                                        @update:field="formPassword.password = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formPassword.password"
                                        :type="inputTypePasswordForm"
                                        :icon-class="icon"
                                        icon-description="Cliquez pour afficher/masquer"
                                        @clic:icon="showPassword()"
                                        autocomplete="new-password"
                                        :tabindex="2"
                                    />

                                    <form-text-field-icon
                                        :bs-class="authUser.role !== 'admin' ? 'col-lg-4' : 'col-lg-6'"
                                        name="password_confirmation"
                                        label="Confirmer"
                                        :label-hidden="false"
                                        @update:field="formPassword.password_confirmation = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formPassword.password_confirmation"
                                        :type="inputTypePasswordForm"
                                        :icon-class="icon"
                                        icon-description="Cliquez pour afficher/masquer"
                                        @clic:icon="showPassword()"
                                        autocomplete="new-password"
                                        :tabindex="3"
                                    />

                                    <div class="col-lg-12">
                                        <div class="d-flex gap-2 justify-content-end">
                                            <button type="submit" class="btn btn-secondary">Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped>

</style>
