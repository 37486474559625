<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import {useRouter} from "vue-router";
import {computed, reactive, ref} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import Editor from '@tinymce/tinymce-vue'
import FormSelectCategory from "../../Components/FormSelectCategory.vue";
import FormInputFilePond from "../../Components/FormInputFilePond.vue";
import FormSelectUser from "../../Components/FormSelectUser.vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import FormSelectSearchUser from "../../Components/FormSelectSearchUser.vue";

const { httpErrorsHandler } = useHttpErrorsHandler();
const $toast = useToast();
const router = useRouter();
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const loading = ref(false)
const isUploading = ref(false)
const form = reactive( {
    title: null,
    description: null,
    category_id: true,
    assigned_to_id: null,
    attachments: []
})
const errors = ref(null)
const percentage = ref(0)
function createTicket() {

    let config = {}
    if(form.attachments.length > 0) {
        isUploading.value = true;
        config = {
            onUploadProgress: progressEvent => percentage.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        }
    }
    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (key === 'attachments') {
            return Array.from(value).forEach(attachment => {
                formData.append(`${key}[]`, attachment);
            });
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    axios.post("/api/tickets", formData, config)
        .then(response => {
            $toast.success('Ticket créé avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.push({name: 'tickets.show', params: {id: response.data.data.id}});
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}
const tinyMceLoading = ref(true);
function pasPostProcess(plugin, args) {
    args.node.innerHTML = args.node.innerHTML.replace(/style="color: #2d65cd;"/g, '');
    args.node.innerHTML = args.node.innerHTML.replace(/style="color: #212529;"/g, '');
    args.node.querySelectorAll('img').forEach(img => {
        img.classList.add('img-fluid');
    });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card" v-show="loading" >
                <div class="card-body">
                    <loader :is-uploading="isUploading" :percentage="percentage" />
                </div>
            </div>
            <form v-on:submit.prevent="createTicket" v-show="!loading" >
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-tooltip-plus-outline me-2 align-middle fs-4"></i>Créer un ticket</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-text-field
                                name="title"
                                label="Titre"
                                :label-hidden="false"
                                @update:field="form.title = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.title"
                                bs-class="col-md-12"
                            />

                            <form-select-category
                                :errors="errors"
                                label="Catégorie"
                                name="category_id"
                                :required="true"
                                :data="form.category_id"
                                @update:field="form.category_id = ($event !== null ? $event.id : null)"
                                :bs-class="authUser.role === 'admin' || authUser.role === 'back_office' ? 'col-md-6' : 'col-md-12'"
                                :category-pre-selected="null"
                                placeholder="Sélectionnez une catégorie"
                                category-type="App\Models\Ticket"
                            />

                            <form-select-search-user
                                v-if="authUser.role === 'admin' || authUser.role === 'back_office'"
                                :errors="errors"
                                label="Assigné à"
                                name="assigned_to_id"
                                :required="false"
                                :data="form.assigned_to_id"
                                @update:field="form.assigned_to_id = ($event !== null ? $event.id : null)"
                                bs-class="col-md-6"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                :user-pre-selected="null"
                                placeholder="Sélectionnez un utilisateur"
                            />

                            <div class="mb-3">
                                <label for="tinyEditor" class="form-label">Contenu <span class="text-danger"> *</span> </label>
                                <div class="text-center my-2" v-if="tinyMceLoading">
                                    <div class="spinner-grow text-dark" role="status">
                                    </div>
                                    <br>
                                </div>
                                <Editor
                                    id="tinyEditor"
                                    v-model="form.description"
                                    :init="{
                                        license_key: 'gpl',
                                        base_url: '/tinymce/js/tinymce',
                                        suffix: '.min',
                                        contextmenu: false,
                                        browser_spellcheck: true,
                                        language: 'fr_FR',
                                        menubar: false,
                                        content_style: 'body { font-family: \'Inter\', sans-serif; font-size: 13px; }',
                                        statusbar: false,
                                        plugins: ['lists', 'link', 'autoresize'],
                                        toolbar: 'bold italic underline | bullist numlist | link | forecolor | emoticons fullscreen',
                                        paste_data_images: false,
                                        paste_as_text: false,
                                        paste_webkit_styles: 'color font-weight strong',
                                        autoresize_bottom_margin: 0,
                                        min_height: 200,
                                        max_height: 600,
                                        init_instance_callback: function(editor) {
                                            tinyMceLoading = false;
                                        },
                                        paste_postprocess: pasPostProcess
                                    }"
                                />
                                <span class="form-text text-muted mt-0">Enter = nouveau paragraphe. Maj + Enter = retour à la ligne.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-attachment me-2 align-middle fs-4"></i>Fichiers joints</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <form-input-file-pond
                                name="form.attachments"
                                label="Fichiers joints"
                                :label-hidden="false"
                                :errors="errors"
                                :required="false"
                                :data="form.attachments"
                                @update:field="form.attachments = $event"
                                bs-class="col-md-12"
                                :disabled="false"
                                :multiple="true"
                            />

                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
