import CallList from "../Views/Call/CallList.vue";

export const callRoutes = [
    {
        path: "/calls",
        name: "calls.list",
        component: CallList,
        meta: {
            menu: {
                parent: 'calls',
                child: null
            },
            breadcrumb: {
                title: "Appels",
                nav: {
                    all: {
                        active: "Tous les appels",
                        inactive: null
                    }
                }
            }
        },
    }
];
