<script setup>

import Loader from "../../Components/Loader.vue";
import { onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import Modal from "../../Components/Modal.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import axios from "axios";

const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');
const sortingField = ref('due_date');
const sortingFieldsOrder = reactive({
    id: 'asc',
    due_date: 'desc'
});
const isInitialized = ref(false);
const loading = ref(true);
const invoicesZoho = ref([]);
const searchLoaded = ref(false);
const links = ref([]);
const meta = ref([]);
const query = ref('');
const modaleDeleteShown = ref(false);
const idToDelete = ref(null);
const indexToDelete = ref(null);
const errors = ref(null);
const filters = reactive( {
    zoho_customer_id: null,
    status: null,
})
const filtersApplied = ref(true);
const centers = ref([]);

watch(url, () => {
    localStorage.setItem('invoicesZohoListUrl', url.value);
    localStorage.setItem('invoicesZohoSortingField', sortingField.value);
    localStorage.setItem('invoicesZohoSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('invoicesZohoSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('invoicesZohoQuery', query.value);
    localStorage.setItem('invoicesZohoFiltersApplied', filtersApplied.value.toString());
});

onBeforeMount(() => {
    loadSettingsFromLocalStorage();
    loadData(url.value);
    getCenters();
});

onMounted(() => {
    isInitialized.value = true;
})

onBeforeUnmount(() => {
    if (modaleDeleteShown.value) {
        const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
        modaleDelete.hide()
    }
})


function loadSettingsFromLocalStorage() {
    const hasStoredSettings = localStorage.getItem('invoicesZohoListUrl') != null;
    if (hasStoredSettings) {
        loadStoredSettings();
    } else {
        setDefaultSettings();
    }
}

function loadStoredSettings() {
    url.value = localStorage.getItem('invoicesZohoListUrl');
    sortingField.value = localStorage.getItem('invoicesZohoSortingField');
    sortingFieldsOrder[sortingField.value] = localStorage.getItem('invoicesZohoSortingFieldsOrder');
    searchLoaded.value = localStorage.getItem('invoicesZohoSearchLoaded') === 'true';
    query.value = localStorage.getItem('invoicesZohoQuery') ?? '';
    filtersApplied.value = localStorage.getItem('invoicesZohoFiltersApplied') === 'true';

    const savedFilters = localStorage.getItem('invoicesZohoFilters');
    if (savedFilters) {
        Object.assign(filters, JSON.parse(savedFilters));
    }
}

function setDefaultSettings() {
    url.value = '/api/zoho-invoices?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
}

function loadData(urlToFetch) {
    invoicesZoho.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if (searchLoaded.value) {
                return performSearch(urlToFetch);
            }
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
function paginationAndDataMapping(response) {
    invoicesZoho.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
}

const search = async () => {
    if (query.value.length > 2) {
        performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('invoicesZohoQuery', query.value);
};

const debouncedSearch = _.debounce(search, 250);

function performSearch(urlPerformSearch = null) {
    invoicesZoho.value = [];
    loading.value = true;

    let searchUrl = '/api/search/zoho-invoices?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }

    let urlWithFilters = addFiltersToUrl(searchUrl);

    axios.get(urlWithFilters)
        .then(response => {
            paginationAndDataMapping(response.data);
            url.value = searchUrl;
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}

function clearSearch() {
    invoicesZoho.value = [];
    let urlWithFilters = addFiltersToUrl('/api/zoho-invoices?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]);
    loadData(urlWithFilters);
    searchLoaded.value = false;
}

function showModalDelete(id, index) {
    idToDelete.value = id;
    indexToDelete.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDelete'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function deleteClientRequest() {
    axios.delete('/api/zoho-invoices/' + idToDelete.value)
        .then(() => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function applyFilter(){

    let urlWithFilters = addFiltersToUrl(url.value);
    localStorage.setItem('invoicesZohoFilters', JSON.stringify(filters));
    localStorage.setItem('invoicesZohoFiltersApplied', filtersApplied.value.toString());

    loadData(urlWithFilters);
}

watch(
    () => [filters.zoho_customer_id, filters.status],
    () => {
        if (isInitialized.value) {
            filtersApplied.value = false;
        }
    },
    { deep: true }
);

function addFiltersToUrl(url) {
    let modifiedUrl = new URL(url, window.location.origin);

    Object.keys(filters).forEach(key => {
        const value = filters[key];
        if (value !== null && value !== '') {
            modifiedUrl.searchParams.set(key, value);
        } else {
            modifiedUrl.searchParams.delete(key);
        }
    });

    filtersApplied.value = true;

    return modifiedUrl.href;
}

function getCenters(){
    centers.value.push({
        text: 'Tous les centres',
        value: null
    })
    axios.get('/api/centers?sortingField=name&sortingOrder=asc&all==true')
        .then(response => {
            response.data.data.forEach(center => {
                if (center.data.zoho_customer_id !== null){
                    centers.value.push({
                        text: center.data.name,
                        value: center.data.zoho_customer_id
                    })
                }
            })
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <form>
                        <div class="row g-3">
                            <div class="col-xxl-5 col-sm-12">
                                <div class="search-box">
                                    <input type="text" class="form-control search" placeholder="Rechercher..."  v-model="query" @input="debouncedSearch" id="searchClientRequests">
                                    <i class="ri-search-line search-icon"></i>
                                </div>
                            </div>

                            <form-select-field
                                name="filters.status"
                                label="Status"
                                :label-hidden="true"
                                @update:field="filters.status = $event"
                                :errors="errors"
                                :required="true"
                                :data="filters.status"
                                bs-class="col-xxl-3 col-sm-4"
                                :disabled="false"
                                additional-class="bg-light border-light"
                                placeholder="Tous les statuts"
                                :liste-options="[
                                    { text: 'Annulée', value: 'void' },
                                    { text: 'En retard', value: 'overdue' },
                                    { text: 'Envoyée', value: 'sent' },
                                    { text: 'Non payée', value: 'unpaid' },
                                    { text: 'Partiellement payée', value: 'partially_paid' },
                                    { text: 'Payée', value: 'paid' },
                                    { text: 'Vue', value: 'viewed' },
                                    { text: 'Tous les statuts', value: null },
                                ]"
                                margin="mb-0"
                            />

                            <form-select-field
                                name="filters.zoho_customer_id"
                                label="Centre"
                                :label-hidden="true"
                                @update:field="filters.zoho_customer_id = $event"
                                :errors="errors"
                                :required="true"
                                :data="filters.zoho_customer_id"
                                bs-class="col-xxl-3 col-sm-4"
                                :disabled="false"
                                additional-class="bg-light border-light"
                                placeholder="Tous les centres"
                                :liste-options="centers"
                                margin="mb-0"
                            />

                            <div class="col-xxl-1 col-sm-4">
                                <button type="button" class="btn w-100" @click.prevent="applyFilter" :class="filtersApplied ? 'btn-dark' : 'btn-warning'">
                                    <i class="ri-equalizer-fill me-1 align-bottom"></i>
                                    Filtrer
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                    <tr>
                                        <th class="col-1 fw-bold" scope="col">Numéro facture</th>
                                        <th class="col-2" scope="col">Centre</th>
                                        <th class="col-1" scope="col">Émis le</th>
                                        <th class="col-1" scope="col">Échéance</th>
                                        <th class="col-1" scope="col">Statut</th>
                                        <th class="col-1" scope="col">Montant dû</th>
                                        <th class="col-1 text-center" scope="col">Détails</th>
                                        <th class="col-1 text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(invoice, index) in invoicesZoho" :key="index">
                                    <td class="fw-medium text-dark">{{ invoice.data.invoice_number }}</td>
                                    <td>{{ invoice.data.center.data.name }}</td>
                                    <td>{{ invoice.data.date_fr }}</td>
                                    <td>{{ invoice.data.due_date_fr }}</td>
                                    <td>
                                        <span :class="'badge fs-11 bg-'+invoice.data.status_badge_color+'-subtle  text-'+invoice.data.status_badge_color">{{ invoice.data.status_fr }}</span>
                                    </td>
                                    <td>{{ invoice.data.total }}</td>
                                    <td class="text-center">
                                        <a :href="invoice.data.zoho_invoice_url" target="_blank" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</a>
                                    </td>
                                    <td class="text-center">
                                        <!--Delete-->
                                        <a href="#" class="text-danger" title="Supprimer la facture" @click.prevent="showModalDelete(invoice.data.id, index)" v-if="invoice.can.delete">
                                            <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-delete-bin-3-fill fs-17 px-1 text-muted" v-else></i>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="invoicesZoho.length === 0 && !loading">
                                    <td colspan="10" v-if="searchLoaded">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                        <p class="mt-2 h5">Aucun résultat</p>
                                        <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                    </td>
                                    <td colspan="10" v-else>Aucune facture...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="10">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                        ></pagination>
                    </div>

                    <modal id="modalDelete" title="Supprimer la facture ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteClientRequest">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer une facture.</p>
                        </template>
                    </modal>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
