<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import {useToast} from "vue-toast-notification";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import axios from "axios";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');
const sortingField = ref('created_at');
const sortingFieldsOrder = reactive({
    invoice_number: 'asc',
    created_at: 'asc',
});
watch(url, () => {
    localStorage.setItem('invoicesListUrl', url.value);
    localStorage.setItem('invoicesSortingField', sortingField.value);
    localStorage.setItem('invoicesSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('invoicesSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('invoicesQuery', query.value);
    localStorage.setItem('invoicesFiltersApplied', filtersApplied.value.toString());
});
const isInitialized = ref(false);

onBeforeMount(() => {
    loadSettingsFromLocalStorage();
    loadData(url.value);
});

function loadSettingsFromLocalStorage() {
    const hasStoredSettings = localStorage.getItem('invoicesListUrl') != null;
    if (hasStoredSettings) {
        loadStoredSettings();
    } else {
        setDefaultSettings();
    }
}

function loadStoredSettings() {
    url.value = localStorage.getItem('invoicesListUrl');
    sortingField.value = localStorage.getItem('invoicesSortingField');
    sortingFieldsOrder[sortingField.value] = localStorage.getItem('invoicesSortingFieldsOrder');
    searchLoaded.value = localStorage.getItem('invoicesSearchLoaded') === 'true';
    query.value = localStorage.getItem('invoicesQuery') ?? '';
    filtersApplied.value = localStorage.getItem('invoicesFiltersApplied') === 'true';

    const savedFilters = localStorage.getItem('invoicesFilters');
    if (savedFilters) {
        Object.assign(filters, JSON.parse(savedFilters));
    }
}

function setDefaultSettings() {
    url.value = '/api/invoices?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
}

onMounted(() => {
    isInitialized.value = true;
})

//Get data
const loading = ref(true);
const invoices = ref([]);
function loadData(urlToFetch) {
    invoices.value = [];
    loading.value = true;
    if (searchLoaded.value) {
        return performSearch(urlToFetch);
    }
    axios.get(urlToFetch)
        .then(response => {
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const searchLoaded = ref(false);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response) {
    invoices.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
}

//Ordering table
function changeOrdering(sortingValue) {
    sortingField.value = sortingValue;
    sortingFieldsOrder[sortingField.value] = sortingFieldsOrder[sortingField.value] === 'asc' ? 'desc' : 'asc';

    url.value = searchLoaded.value === true
        ? '/api/search/invoices?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        : '/api/invoices?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    loadData(url.value);
}
function activeSorting(sortingValue){
    return sortingField.value === sortingValue ? 'text-secondary' : 'text-dark';
}

//Searching
const query = ref('');
const search = _.debounce(async () => {
    if (query.value.length > 2) {
        await performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('invoicesQuery', query.value);
}, 250);
const performSearch = async (urlPerformSearch = null) => {
    invoices.value = [];
    loading.value = true;
    let searchUrl = '/api/search/invoices?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }
    let newUrl = new URL(searchUrl, window.location.origin);
    // Update filters
    filters.center_id
        ? newUrl.searchParams.set('center_id', filters.center_id)
        : newUrl.searchParams.delete('center_id');

    filters.status
        ? newUrl.searchParams.set('status', filters.status)
        : newUrl.searchParams.delete('status');

    axios.get(newUrl.href)
        .then(response => {
            paginationAndDataMapping(response.data);
            url.value = searchUrl
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
};
const clearSearch = () => {
    invoices.value = [];
    let newUrl = '/api/invoices?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    if (filters.center_id) {
        newUrl += '&center_id=' + filters.center_id;
    }
    if (filters.status) {
        newUrl += '&status=' + filters.status;
    }

    loadData(newUrl);
    searchLoaded.value = false;
};

const modaleDeleteShown = ref(false);
const idToDelete = ref(null);
const indexToDelete = ref(null);
function showModalDelete(id, index) {
    idToDelete.value = id;
    indexToDelete.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDelete'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function deleteInvoice() {
    axios.delete('/api/invoices/' + idToDelete.value)
        .then(() => {
            loadData(url.value);
            $toast.success('Facture supprimée', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    if (modaleDeleteShown.value) {
        const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
        modaleDelete.hide()
    }
})

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const errors = ref(null);
const filters = reactive( {
    center_id: null,
    status: null,
})
const filtersApplied = ref(true);

function applyFilter(){

    let newUrl = new URL(url.value, window.location.origin);

    filters.center_id
        ? newUrl.searchParams.set('center_id', filters.center_id)
        : newUrl.searchParams.delete('center_id');

    filters.status
        ? newUrl.searchParams.set('status', filters.status)
        : newUrl.searchParams.delete('status');

    filtersApplied.value = true;

    localStorage.setItem('invoicesFilters', JSON.stringify(filters));
    localStorage.setItem('invoicesFiltersApplied', filtersApplied.value.toString());

    loadData(newUrl.href);
}

watch(
    () => [filters.center_id, filters.status],
    () => {
        if (isInitialized.value) {
            filtersApplied.value = false;
        }
    },
    { deep: true }
);

const centers = ref([]);
onBeforeMount(() => {
    getCenters();
})
function getCenters(){
    centers.value.push({
        text: 'Tous les centres',
        value: null
    })
    axios.get('/api/centers?sortingField=name&sortingOrder=asc&all==true')
        .then(response => {
            response.data.data.forEach(center => {
                centers.value.push({
                    text: center.data.name,
                    value: center.data.id
                })
            })
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header border border-dashed border-top-0 border-start-0 border-end-0">
                    <div class="d-flex align-items-center">
                        <h5 class="card-title mb-0 flex-grow-1">Toutes les factures</h5>
                        <router-link :to="{ name:'invoices.create' }" class="btn btn-secondary" v-if="authUser.can && authUser.can.create_invoices">
                            <i class="ri-add-fill me-1 align-bottom hide-on-mobile"></i> Ajouter
                        </router-link>
                    </div>
                </div>
                <!--Filters-->
                <div  class="card-body">
                    <div class="row g-3">
                        <div class="col-xxl-5">
                            <div class="search-box">
                                <input type="text" class="form-control search" placeholder="Rechercher..."  v-model="query" @input="search" id="searchInvoices">
                                <i class="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        <form-select-field
                            name="filters.status"
                            label="Statut"
                            :label-hidden="true"
                            @update:field="filters.status = $event"
                            :errors="errors"
                            :required="true"
                            :data="filters.status"
                            bs-class="col-xxl-3 col-sm-5"
                            :disabled="false"
                            additional-class="bg-light border-light"
                            placeholder="Tous les statuts"
                            :liste-options="[
                                { text: 'Statuts ouverts (Brouillon, En attente de paiement...)', value: 'all_open' },
                                { text: 'Statuts fermés (Payé, Annulé...)', value: 'all_closed' },
                                { text: 'Tous les statuts', value: null },
                                { text: '---', value: null },
                                { text: 'Brouillon', value: 'draft' },
                                { text: 'En attente de paiement', value: 'waiting_for_payment' },
                                { text: 'Payé', value: 'paid' },
                                { text: 'Annulé', value: 'canceled' }
                            ]"
                            margin="mb-0"
                        />

                        <form-select-field
                            name="filters.center_id"
                            label="Sexe"
                            :label-hidden="true"
                            @update:field="filters.center_id = $event"
                            :errors="errors"
                            :required="true"
                            :data="filters.center_id"
                            bs-class="col-xxl-3 col-sm-5"
                            :disabled="false"
                            additional-class="bg-light border-light"
                            placeholder="Tous les centres"
                            :liste-options="centers"
                            margin="mb-0"
                        />

                        <div class="col-xxl-1 col-sm-2">
                            <button type="button" class="btn w-100" @click.prevent="applyFilter" :class="filtersApplied ? 'btn-dark' : 'btn-warning'">
                                <i class="ri-equalizer-fill me-1 align-bottom hide-on-mobile"></i>
                                Filtrer
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                    <tr>
                                        <th class="col-1 sort">
                                            <a href="#" @click.prevent="changeOrdering('invoice_number')" :class="activeSorting('invoice_number')" class="fw-bold pe-4">Numéro facture</a>
                                        </th>
                                        <th class="col-2" scope="col">Nom client</th>
                                        <th class="col-1" scope="col">Centre</th>
                                        <th class="col-1" scope="col">Status</th>
                                        <th class="col-1" scope="col">Date facturation</th>
                                        <th class="col-1" scope="col">Date échéance</th>
                                        <th class="col-1" scope="col">Total TTC</th>
                                        <th class="col-1 sort">
                                            <a href="#" @click.prevent="changeOrdering('created_at')" :class="activeSorting('created_at')" class="pe-4">Date création</a>
                                        </th>
                                        <th class="col-1 text-center" scope="col">Détails</th>
                                        <th class="col-1 text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(invoice, index) in invoices" :key="index">
                                    <td class="fw-medium text-dark">{{ invoice.data.invoice_number }}</td>
                                    <td>{{ invoice.data.client.data.last_name }} {{ invoice.data.client.data.first_name }}</td>
                                    <td>{{ invoice.data.center.data.name }}</td>
                                    <td>
                                        <span :class="'badge fs-11 bg-'+invoice.data.status_badge_color+'-subtle  text-'+invoice.data.status_badge_color">{{ invoice.data.status_label_fr }}</span>
                                    </td>
                                    <td>{{ invoice.data.invoice_date_fr }}</td>
                                    <td>{{ invoice.data.due_date_fr }}</td>
                                    <td>{{ invoice.data.total_price_with_tax }}</td>

                                    <td>{{ invoice.data.created_at_fr }}</td>
                                    <td class="text-center">
                                        <router-link :to="{ name: 'invoices.show', params:{ id: invoice.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</router-link>
                                    </td>
                                    <td class="text-center">
                                        <!--UPDATE-->
                                        <router-link :to="{ name: 'invoices.edit', params:{ id: invoice.data.id } }" class="text-info" title="Modifier la facture" v-if="invoice.can.update">
                                            <i class="ri-pencil-fill fs-17 px-1"></i>
                                        </router-link>
                                        <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                        <!--Delete-->
                                        <a href="#" class="text-danger" title="Supprimer la facture" @click.prevent="showModalDelete(invoice.data.id, index)" v-if="invoice.can.delete">
                                            <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-delete-bin-3-fill fs-17 px-1 text-muted" v-else></i>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="invoices.length === 0 && !loading">
                                    <td colspan="10" v-if="searchLoaded">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                        <p class="mt-2 h5">Aucun résultat</p>
                                        <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                    </td>
                                    <td colspan="10" v-else>Aucune facture...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="10">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                        ></pagination>
                    </div>

                    <modal id="modalDelete" title="Supprimer la facture ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteInvoice">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer une facture. Continuer ?</p>
                        </template>
                    </modal>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
