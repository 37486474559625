<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import {useRouter} from "vue-router";
import {reactive, ref} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import FormSwitch from "../../Components/FormSwitch.vue";
import FormDatePicker from "../../Components/FormDatePicker.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import FormSelectCenter from "../../Components/FormSelectCenter.vue";
import FormTextarea from "../../Components/FormTextarea.vue";

const { httpErrorsHandler } = useHttpErrorsHandler();
const $toast = useToast();
const router = useRouter();

const loading = ref(false)
const form = reactive( {
    name: null,
    categorizable_type: 'App\\Models\\News',
})
const errors = ref(null)
function createNewsCategory() {
    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    axios.post("/api/categories", formData)
        .then(response => {
            $toast.success('Catégorie ajoutée avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.back();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <!--Loader-->
            <div class="card" v-show="loading">
                <div class="card-body">
                    <loader/>
                </div>
            </div>
            <!--Form-->
            <form v-on:submit.prevent="createNewsCategory" v-show="!loading">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-bookmark-plus-outline me-2 align-middle fs-4"></i>Créer une catégorie</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-text-field
                                name="name"
                                label="Nom de la catégorie"
                                :label-hidden="false"
                                @update:field="form.name = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.name"
                                bs-class="col-md-12"
                            />

                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
