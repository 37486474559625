<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import {useToast} from "vue-toast-notification";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
import axios from "axios";

const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const url = ref('');
const sortingField = ref('created_at');
const sortingFieldsOrder = reactive({
    created_at: 'asc',
    name: 'desc'
});
const isInitialized = ref(false);
const loading = ref(true);
const products = ref([]);
const searchLoaded = ref(false);
const links = ref([]);
const meta = ref([]);
const query = ref('');
const idToDelete = ref(null);
const indexToDelete = ref(null);
const productToShow = ref(null);

watch(url, () => {
    localStorage.setItem('productsListUrl', url.value);
    localStorage.setItem('productsSortingField', sortingField.value);
    localStorage.setItem('productsSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('productsSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('productsQuery', query.value);
    localStorage.setItem('productsShowArchived', showArchived.value.toString());
});

onBeforeMount(() => {
    loadSettingsFromLocalStorage();
    loadData(url.value);
});

onMounted(() => {
    isInitialized.value = true;
})

onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})


function loadSettingsFromLocalStorage() {
    const hasStoredSettings = localStorage.getItem('productsListUrl') != null;
    if (hasStoredSettings) {
        loadStoredSettings();
    } else {
        setDefaultSettings();
    }
}

function loadStoredSettings() {
    url.value = localStorage.getItem('productsListUrl');
    sortingField.value = localStorage.getItem('productsSortingField');
    sortingFieldsOrder[sortingField.value] = localStorage.getItem('productsSortingFieldsOrder');
    searchLoaded.value = localStorage.getItem('productsSearchLoaded') === 'true';
    showArchived.value = localStorage.getItem('productsShowArchived') === 'true';
    query.value = localStorage.getItem('productsQuery') ?? '';
}

function setDefaultSettings() {
    url.value = '/api/products?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
}

function loadData(urlToFetch) {
    products.value = [];
    loading.value = true;
    if (searchLoaded.value) {
        return performSearch(urlToFetch);
    }
    axios.get(urlToFetch)
        .then(response => {
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
function paginationAndDataMapping(response, mustSave = true) {
    products.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
}

const search = async () => {
    if (query.value.length > 2) {
        performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('productsQuery', query.value);
};

const debouncedSearch = _.debounce(search, 250);

function performSearch(urlPerformSearch = null) {
    products.value = [];
    loading.value = true;

    let searchUrl = '/api/search/products?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }
    searchUrl = addArchivedToUrl(searchUrl);
    axios.get(searchUrl)
        .then(response => {
            paginationAndDataMapping(response.data);
            url.value = searchUrl;
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}

function clearSearch() {
    loading.value = true;
    searchLoaded.value = false;
    products.value = [];
    const urlToFetch = addArchivedToUrl('/api/products?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]);
    loadData(urlToFetch)
}

function showModalArchive(id, index) {
    idToDelete.value = id;
    indexToDelete.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalArchive'))
    modaleDelete.show()
}
function archiveProduct() {
    axios.delete('/api/products/' + idToDelete.value)
        .then(() => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalArchive'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function changeOrdering(sortingValue) {
    sortingField.value = sortingValue;
    sortingFieldsOrder[sortingField.value] = sortingFieldsOrder[sortingField.value] === 'asc' ? 'desc' : 'asc';
    if (searchLoaded.value) {
        performSearch();
    } else {
        const urlToFetch = addArchivedToUrl('/api/products?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value])
        loadData(addArchivedToUrl(urlToFetch))
    }
}
function activeSorting(sortingValue){
    return sortingField.value === sortingValue ? 'text-secondary' : 'text-dark';
}

function showModalMessage(message) {
    productToShow.value = message;
    const modaleProductShow = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalShowProduct'))
    modaleProductShow.show()
}

const showArchived = ref(false);
function getArchived(){
    showArchived.value = true;
    if (searchLoaded.value) {
        performSearch();
    } else {
        const urlToFetch = addArchivedToUrl('/api/products?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value])
        loadData(addArchivedToUrl(urlToFetch))
    }
}

function hideArchived(){
    showArchived.value = false;
    if (searchLoaded.value) {
        performSearch();
    } else {
        const urlToFetch = addArchivedToUrl('/api/products?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value])
        loadData(addArchivedToUrl(urlToFetch))
    }
}

function restore(id){
    axios.post('/api/products/' + id + '/restore')
        .then(() => {
            loadData(url.value);
            $toast.success('Produit restauré', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function addArchivedToUrl(url) {
    let modifiedUrl = new URL(url, window.location.origin);

    if (showArchived.value) {
        modifiedUrl.searchParams.set('archived', '1');
    } else {
        modifiedUrl.searchParams.delete('archived');
    }

    return modifiedUrl.href;
}

</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <div class="row g-3">
                        <div class="col-md-4">
                            <div class="search-box">
                                <input type="text" class="form-control search" placeholder="Rechercher..."  v-model="query" @input="debouncedSearch" id="searchClientRequests">
                                <i class="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        <div class="col-md-auto ms-auto">
                            <button type="button" class="btn btn-dark waves-light me-2" @click="getArchived" v-if="!showArchived">
                                <i class="ri-archive-fill me-1 align-bottom"></i> Archives
                            </button>
                            <button type="button" class="btn btn-success waves-light me-2" @click="hideArchived" v-else>
                                <i class="mdi mdi-package-variant fs-14 me-1"></i> Produits
                            </button>
                            <router-link :to="{ name:'products.create' }" class="btn btn-secondary"  v-if="authUser.can && authUser.can.create_products">
                                <i class="ri-add-fill me-1 align-bottom"></i> Ajouter
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                <tr>
                                    <th class="col-1 fw-bold" scope="col">Code Produit</th>
                                    <th class="col-1">Type</th>
                                    <th class="col-6 sort">
                                        <a href="#" @click.prevent="changeOrdering('name')" :class="activeSorting('name')" class="pe-4">Nom</a>
                                    </th>
                                    <th class="col-1 text-end">Prix HT</th>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('created_at')" :class="activeSorting('created_at')">Date création</a>
                                    </th>
                                    <th class="col-1 text-center" scope="col">Détails</th>
                                    <th class="col-1 text-center" scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(product, index) in products" :key="index">
                                    <td class="fw-medium text-dark">{{ product.data.product_code }}</td>
                                    <td>
                                        <span :class="'badge fs-11 bg-'+product.data.type_badge_color+'-subtle  text-'+product.data.type_badge_color">{{ product.data.type_label_fr }}</span>
                                    </td>
                                    <td>{{ product.data.name }}</td>
                                    <td class="text-end">{{ product.data.price_excluding_tax_formatted }}</td>
                                    <td>{{ product.data.created_at_fr }}</td>
                                    <td class="text-center">
                                        <a href="#" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm" @click.prevent="showModalMessage(product)">Accéder</a>
                                    </td>
                                    <td class="text-center">
                                        <!--Update-->
                                        <router-link :to="{ name: 'products.edit', params:{ id: product.data.id } }" class="text-info" title="Modifier le produit" v-if="product.can.update">
                                            <i class="ri-pencil-fill fs-17 px-1"></i>
                                        </router-link>
                                        <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                        <!--ARCHIVE-->
                                        <a href="#" class="text-danger" title="Archiver le produit"  data-bs-toggle="modal" data-bs-target="#modalArchive" @click.prevent="showModalArchive(product.data.id, index)" v-if="!showArchived && product.can.delete">
                                            <i class="ri-inbox-archive-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-inbox-archive-fill fs-17 px-1 text-muted" v-if="!showArchived && !product.can.delete"></i>
                                        <!--RESTORE-->
                                        <a href="#" class="text-success" title="Restaurer le produit" @click.prevent="restore(product.data.id)" v-if="showArchived && product.can.restore">
                                            <i class="ri-inbox-unarchive-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-inbox-unarchive-fill fs-17 px-1 text-muted"  v-if="showArchived && !product.can.restore"></i>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="products.length === 0 && !loading">
                                    <td colspan="7" v-if="searchLoaded">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                        <p class="mt-2 h5">Aucun résultat</p>
                                        <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                    </td>
                                    <td colspan="7" v-else>Aucun produit...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="7">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                        ></pagination>
                    </div>
                    <!--Modal delete-->
                    <modal id="modalArchive" title="Archiver le produit ?" btn-class="btn-danger" btn-text="Archiver" @click:button="archiveProduct">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point d'archiver un produit. Continuer ?</p>
                        </template>
                    </modal>
                    <!--Model show product-->
                    <div class="modal fade zoomIn" id="modalShowProduct" tabindex="-1" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content" v-if="productToShow !== null">
                                <div class="modal-header">
                                    <h5 class="modal-title">Détails du produit</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body p-4">
                                    <p><span class="fw-bold">Nom :</span> {{productToShow.data.name}}</p>
                                    <p><span class="fw-bold">Prix HT :</span> {{productToShow.data.price_excluding_tax_formatted }}</p>
                                    <div>
                                        <span class="fw-bold mb-3">Description :</span><br>
                                        <span  v-html="productToShow.data.description !== null ? productToShow.data.description.replace(/\n/g, '<br />') : null"></span>
                                    </div>
                                    <div class="text-end mb-0">
                                        <button class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" id="deleteRecord-close" data-bs-dismiss="modal">Fermer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
