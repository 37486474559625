<template>
    <div class="form-group mb-3" :class="bsClass">
        <label :for="name" class="form-label" v-if="!labelHidden">
            {{ label }}
            <span class="text-danger" v-if="required"> *</span>
        </label>
        <flat-pickr
            v-model="value"
            :class="errorClassObject()"
            @update:modelValue="updateField(value)"
            :name="name"
            :placeholder="placeholder"
            :config="config"
            :id="name"
            :disabled="disabled"
        ></flat-pickr>
        <span class="invalid-feedback" v-html="errorMessage()"></span>
        <span class="form-text text-muted" v-if="informationContent !== null">
            {{ informationContent }}
        </span>
    </div>
</template>

<script>
import  flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {French} from 'flatpickr/dist/l10n/fr.js';
export default {
    name: "FormDatePicker",
    components: {
        flatPickr
    },
    props: {
        name : {
            type: String,
            required: true
        },
        label : {
            type: String,
            required: true
        },
        labelHidden : {
            type: Boolean,
            required: false,
            default: false
        },
        required : {
            type: Boolean,
            required: false,
            default: false
        },
        informationContent : {
            type: String,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        },
        data: {
            required: false,
            default: null
        },
        disabled : {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            value: null,
            config: {
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                disableMobile: true,
                locale: French,
            },
        }
    },methods: {
        updateField: function() {
            this.clearErrors(this.name);
            this.$emit("update:field", this.value);
        },
        errorMessage: function() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        },
        clearErrors: function() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject: function() {
            return this.hasError ? 'form-control flatpickr-input is-invalid' : 'form-control flatpickr-input';
        }
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            );
        }
    },
    watch: {
        data: {
            immediate: true,
            handler(newVal, oldVal) {
                this.value = newVal;
            },
        }
    }
}
</script>

<style scoped>

</style>
