import InvoiceList from "../Views/InvoiceZoho/InvoiceZohoList.vue";

export const invoiceZohoRoutes = [
    {
        path: "/invoices-zoho",
        name: "invoices-zoho.list",
        component: InvoiceList,
        meta: {
            menu: {
                parent: 'invoices-zoho',
                child: null
            },
            breadcrumb: {
                title: "Factures Zoho",
                nav: {
                    all: {
                        active: "Toutes les factures zoho",
                        inactive: null
                    }
                }
            }
        },
    }
];
