import ClientRequestEdit from "../Views/ClientRequest/ClientRequestEdit.vue";
import ClientRequestCreate from "../Views/ClientRequest/ClientRequestCreate.vue";
import ClientRequestShow from "../Views/ClientRequest/ClientRequestShow.vue";
import ClientRequestList from "../Views/ClientRequest/ClientRequestList.vue";
import ClientRequestOtherList from "../Views/ClientRequest/ClientRequestOtherList.vue";
import {useAuthUserStore} from "../Store/AuthUserStore.js";
import {computed} from "vue";

export const clientRequestRoutes = [
    {
        path: "/client-requests/edit/:id",
        name: "client-requests.edit",
        component: ClientRequestEdit,
        meta: {
            menu: {
                parent: 'client-requests',
                child: null
            },
            breadcrumb: {
                title: "Demandes clients",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Toutes les demandes",
                            link: {name: 'client-requests.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/client-requests/create",
        name: "client-requests.create",
        component: ClientRequestCreate,
        meta: {
            menu: {
                parent: 'client-requests',
                child: null
            },
            breadcrumb: {
                title: "Demandes clients",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Toutes les demandes",
                            link: {name: 'client-requests.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/client-requests/:id",
        name: "client-requests.show",
        component: ClientRequestShow,
        meta: {
            menu: {
                parent: 'client-requests',
                child: null
            },
            breadcrumb: {
                title: "Demandes clients",
                nav: {
                    all: {
                        active: "Détails de la demande",
                        inactive: [{
                            title: "Toutes les demandes",
                            link: {name: 'client-requests.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/client-requests",
        name: "client-requests.list",
        component: ClientRequestList,
        meta: {
            menu: {
                parent: 'client-requests',
                child: null
            },
            breadcrumb: {
                title: "Demandes clients",
                nav: {
                    all: {
                        active: "Toutes les demandes",
                        inactive: null
                    }
                }
            }
        },
    },
    {
        path: "/client-request-others/:id",
        name: "client-request-others.show",
        component: ClientRequestShow,
        meta: {
            menu: {
                parent: 'client-request-others',
                child: null
            },
            breadcrumb: {
                title: "Autres demandes",
                nav: {
                    all: {
                        active: "Détails de la demande",
                        inactive: [{
                            title: "Toutes les autres demandes",
                            link: {name: 'client-request-others.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/client-request-others",
        name: "client-request-others.list",
        component: ClientRequestOtherList,
        beforeEnter: (to, from, next) => {
            const authUserStore = useAuthUserStore();
            const authUser = computed(() => authUserStore.authUser);
            if(authUser.value.role !== 'admin' && authUser.value.role !== 'back_office' ) {
                next('/unauthorized');
            } else {
                next();
            }
        },
        meta: {
            menu: {
                parent: 'client-request-others',
                child: null
            },
            breadcrumb: {
                title: "Autres demandes",
                nav: {
                    all: {
                        active: "Toutes les autres demandes",
                        inactive: null
                    }
                }
            }
        },
    }
];
