<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, onBeforeMount, onBeforeUnmount, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import {useToast} from "vue-toast-notification";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');
const sortingField = ref('created_at');
const sortingFieldsOrder = reactive({
    id: 'asc',
    last_name: 'asc',
    role: 'asc',
    last_login: 'asc',
    created_at: 'asc',
});
watch(url, (newValue) => {
    localStorage.setItem('usersListUrl', url.value);
    localStorage.setItem('usersSortingField', sortingField.value);
    localStorage.setItem('usersSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('usersSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('usersQuery', query.value);
    localStorage.setItem('usersShowArchived', showArchived.value.toString());
});

onBeforeMount(() => {
    if (localStorage.getItem('usersListUrl')) {
        url.value = localStorage.getItem('usersListUrl');
        sortingField.value = localStorage.getItem('usersSortingField');
        sortingFieldsOrder[sortingField.value] = localStorage.getItem('usersSortingFieldsOrder');
        searchLoaded.value = localStorage.getItem('usersSearchLoaded')  === 'true';
        showArchived.value = localStorage.getItem('usersShowArchived')  === 'true';
        query.value = localStorage.getItem('usersQuery');
    }else{
        url.value = '/api/users?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    }
    loadData(url.value);
})

//Get data
const loading = ref(true);
const users = ref([]);
function loadData(urlToFetch) {
    users.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if (searchLoaded.value) {
                return performSearch(urlToFetch);
            }
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const searchLoaded = ref(false);
const savedResponse = ref(null);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response, mustSave = true) {
    users.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
    if (mustSave === true && searchLoaded.value === false) {
        savedResponse.value = response;
    }
}

//Ordering table
function changeOrdering(sortingValue) {
    sortingField.value = sortingValue;
    sortingFieldsOrder[sortingField.value] = sortingFieldsOrder[sortingField.value] === 'asc' ? 'desc' : 'asc';

    url.value = searchLoaded.value === true
        ? '/api/search/users?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        : '/api/users?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    if (showArchived.value)
        url.value += '&archived=true';
    loadData(url.value);
}
function activeSorting(sortingValue){
    return sortingField.value === sortingValue ? 'text-secondary' : 'text-dark';
}

//Searching
const query = ref('');
const search = _.debounce(async () => {
    if (query.value.length > 2) {
        await performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('usersQuery', query.value);
}, 250);
const performSearch = async (urlPerformSearch = null) => {
    users.value = [];
    loading.value = true;
    let searchUrl = showArchived.value
        ? '/api/search/users?archived=true&query=' + query.value + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        : '/api/search/users?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }
    axios.get(searchUrl)
        .then(response => {
            paginationAndDataMapping(response.data, false);
            url.value = searchUrl
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
};
const clearSearch = () => {
    if (savedResponse.value === null) {
        users.value = [];
        url.value = showArchived.value
            ? '/api/users?archived=true&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
            : '/api/users?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        loadData(url.value);
    } else {
        url.value = '/api/users?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value] + '&page=' + savedResponse.value.meta.current_page;
        paginationAndDataMapping(savedResponse.value);
    }
    searchLoaded.value = false;
};

const modaleDeleteShown = ref(false);
const idToArchive = ref(null);
const indexToArchive = ref(null);
function showModalArchive(id, index) {
    idToArchive.value = id;
    indexToArchive.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalArchive'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function archive() {
    axios.delete('/api/users/' + idToArchive.value)
        .then(response => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalArchive'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

const showArchived = ref(false);
function getArchived(){
    loading.value = true;
    showArchived.value = true;
    url.value = '/api/users?archived=true&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    loadData(url.value);
}

function hideArchived(){
    showArchived.value = false;
    url.value = '/api/users?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    loadData(url.value);
}

function restore(id){
    axios.post('/api/users/' + id + '/restore')
        .then(response => {
            loadData(url.value);
            $toast.success('Utilisateur restauré', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card" id="contactList">
                <div class="card-header">
                    <div class="row g-3">
                        <div class="col-md-4">
                            <div class="search-box search-box-list">
                                <input type="text" class="form-control search" placeholder="Rechercher..." v-model="query" @input="search" id="searchUsers">
                                <i class="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        <div class="col-md-auto ms-auto">
                            <button type="button" class="btn btn-dark waves-light me-2" @click="getArchived" v-if="!showArchived">
                                <i class="ri-archive-fill me-1 align-bottom"></i> Archives
                            </button>
                            <button type="button" class="btn btn-success waves-light me-2" @click="hideArchived" v-else>
                                <i class="ri-contacts-fill me-1 align-bottom"></i> Utilisateurs
                            </button>
                            <router-link :to="{ name:'users.create' }" class="btn btn-secondary"  v-if="authUser.can && authUser.can.create_user">
                                <i class="ri-add-fill align-bottom me-1"></i> Ajouter
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                <tr>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('id')" :class="activeSorting('id')" class="fw-bold pe-4">#ID</a>
                                    </th>
                                    <th class="col-2 sort">
                                        <a href="#" @click.prevent="changeOrdering('last_name')" :class="activeSorting('last_name')" class="pe-4">Nom</a>
                                    </th>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('role')" :class="activeSorting('role')" class="pe-4">Role</a>
                                    </th>
                                    <th class="col-2" scope="col">Email</th>
                                    <th class="col-2" scope="col">Téléphone</th>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('created_at')" :class="activeSorting('created_at')" class="pe-4">Date création</a>
                                    </th>
                                    <th class="col-1" scope="col">Dernière activité</th>
                                    <th class="col-1 text-center" scope="col">Détails</th>
                                    <th class="col-1 text-center" scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(user, index) in users" :key="index">
                                    <td class="fw-medium text-dark">#{{ user.data.id }}</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0"><img :src="'/format-image/'+user.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" alt="" class="avatar-xs rounded-circle"></div>
                                            <div class="flex-grow-1 ms-2 name">{{ user.data.last_name }} {{ user.data.first_name }}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <span :class="'badge fs-11 bg-'+user.data.role_badge_color+'-subtle  text-'+user.data.role_badge_color">{{ user.data.role_label_fr }}</span>
                                    </td>
                                    <td>{{ user.data.email }}</td>
                                    <td>{{ user.data.phone_number }}</td>
                                    <td>{{ user.data.created_at_fr }}</td>
                                    <td>{{ user.data.last_activity_fr }}</td>
                                    <td class="text-center">
                                        <router-link :to="{ name: 'users.show', params:{ id: user.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</router-link>
                                    </td>
                                    <td class="text-center">
                                        <!--UPDATE-->
                                        <router-link :to="{ name: 'users.edit', params:{ id: user.data.id } }" class="text-info" title="Modifier l'utilisateur" v-if="user.can.update">
                                            <i class="ri-pencil-fill fs-17 px-1"></i>
                                        </router-link>
                                        <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                        <!--ARCHIVE-->
                                        <a href="#" class="text-danger" title="Archiver l'utilisateur"  data-bs-toggle="modal" data-bs-target="#modalArchive" @click.prevent="showModalArchive(user.data.id, index)" v-if="!showArchived && user.can.delete">
                                            <i class="ri-inbox-archive-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-inbox-archive-fill fs-17 px-1 text-muted" v-if="!showArchived && !user.can.delete"></i>
                                        <!--RESTORE-->
                                        <a href="#" class="text-success" title="Restaurer l'utilisateur" @click.prevent="restore(user.data.id)" v-if="showArchived && user.can.restore">
                                            <i class="ri-inbox-unarchive-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-inbox-unarchive-fill fs-17 px-1 text-muted"  v-if="showArchived && !user.can.restore"></i>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="users.length === 0 && !loading">
                                    <td colspan="9" v-if="searchLoaded">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                        <p class="mt-2 h5">Aucun résultat</p>
                                        <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                    </td>
                                    <td colspan="9" v-else>Aucun utilisateur...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="9">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                            btn-text="Archiver"
                        ></pagination>
                    </div>

                    <modal id="modalArchive" title="Archiver l'utilisateur ?" btn-class="btn-danger" btn-text="Archiver" @click:button="archive">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point d'archiver un utilisateur. Continuer ?</p>
                        </template>
                    </modal>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
