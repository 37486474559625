<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import {useToast} from "vue-toast-notification";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');
const sortingField = ref('start_time');
const sortingFieldsOrder = reactive({
    start_time: 'desc'
});
watch(url, (newValue) => {
    localStorage.setItem('callsListUrl', url.value);
    localStorage.setItem('callsSortingField', sortingField.value);
    localStorage.setItem('callsSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('callsSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('callsQuery', query.value);
    localStorage.setItem('callsFiltersApplied', filtersApplied.value.toString());
});
const isInitialized = ref(false);

onBeforeMount(() => {
    loadSettingsFromLocalStorage();
    loadData(url.value);
});

function loadSettingsFromLocalStorage() {
    const hasStoredSettings = localStorage.getItem('callsListUrl') != null;
    if (hasStoredSettings) {
        loadStoredSettings();
    } else {
        setDefaultSettings();
    }
}

function loadStoredSettings() {
    url.value = localStorage.getItem('callsListUrl');
    sortingField.value = localStorage.getItem('callsSortingField');
    sortingFieldsOrder[sortingField.value] = localStorage.getItem('callsSortingFieldsOrder');
    searchLoaded.value = localStorage.getItem('callsSearchLoaded') === 'true';
    query.value = localStorage.getItem('callsQuery') ?? '';
    filtersApplied.value = localStorage.getItem('callsFiltersApplied') === 'true';

    const savedFilters = localStorage.getItem('callsFilters');
    if (savedFilters) {
        Object.assign(filters, JSON.parse(savedFilters));
    }
}

function setDefaultSettings() {
    url.value = '/api/calls?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
}

onMounted(() => {
    isInitialized.value = true;
})

//Get data
const loading = ref(true);
const calls = ref([]);
function loadData(urlToFetch) {
    calls.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if (searchLoaded.value) {
                return performSearch(urlToFetch);
            }
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const searchLoaded = ref(false);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response, mustSave = true) {
    calls.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
}

//Searching
const query = ref('');
const search = _.debounce(async () => {
    if (query.value.length > 2) {
        await performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('callsQuery', query.value);
}, 250);
const performSearch = async (urlPerformSearch = null) => {
    calls.value = [];
    loading.value = true;
    let searchUrl = '/api/search/calls?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }
    let newUrl = new URL(searchUrl, window.location.origin);
    // Update filters
    filters.center_id
        ? newUrl.searchParams.set('center_id', filters.center_id)
        : newUrl.searchParams.delete('center_id');

    axios.get(newUrl.href)
        .then(response => {
            paginationAndDataMapping(response.data, false);
            url.value = searchUrl
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
};
const clearSearch = () => {
    calls.value = [];
    let newUrl = '/api/calls?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    if (filters.center_id) {
        newUrl += '&center_id=' + filters.center_id;
    }

    loadData(newUrl);
    searchLoaded.value = false;
};

const modaleDeleteShown = ref(false);
const idToDelete = ref(null);
const indexToDelete = ref(null);
function showModalDelete(id, index) {
    idToDelete.value = id;
    indexToDelete.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDelete'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function deleteCall() {
    axios.delete('/api/calls/' + idToDelete.value)
        .then(response => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    if (modaleDeleteShown.value) {
        const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
        modaleDelete.hide()
    }
})

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const errors = ref(null);
const filters = reactive( {
    center_id: null
})
const filtersApplied = ref(true);

function applyFilter(){

    let newUrl = new URL(url.value, window.location.origin);

    filters.center_id
        ? newUrl.searchParams.set('center_id', filters.center_id)
        : newUrl.searchParams.delete('center_id');

    filtersApplied.value = true;

    localStorage.setItem('callsFilters', JSON.stringify(filters));
    localStorage.setItem('callsFiltersApplied', filtersApplied.value.toString());

    loadData(newUrl.href);
}

watch(
    () => [filters.center_id],
    (newValues, oldValues) => {
        if (isInitialized.value) {
            filtersApplied.value = false;
        }
    },
    { deep: true }
);

function defineCallTypeBadge(call) {
    if (call.data.direction === 'out') {
        return 'out';
    }
    if (call.data.direction === 'in' && call.data.last_state === 'missed') {
        return 'missed';
    }
    if (call.data.direction === 'in' && (call.data.last_state === 'voicemail' || call.data.voicemail_link !== null)) {
        return 'voicemail';
    }
    if (call.data.direction === 'in' && (call.data.last_state === 'hangup' || call.data.last_state === 'answered' || call.data.last_state === 'ringing')) {
        return 'in';
    }
}
let players = [];
watch(loading, async (newVal) => {
    if (loading.value === false) {
        await nextTick();
        players.forEach(player => {
            if (player) {
                player.destroy();
            }
        });
        players = Array.from(document.querySelectorAll('.players')).map(p => new Plyr(p,{
            controls: ['play', 'progress'],
            volume: 1,
            displayDuration: false,
        }));
    }
});

onUnmounted(() => {
    players.forEach(player => {
        if (player) {
            player.destroy();
        }
    });
});

const centers = ref([]);
onBeforeMount(() => {
    getCenters();
})
function getCenters(){
    centers.value.push({
        text: 'Tous les centres',
        value: null
    })
    axios.get('/api/centers?sortingField=name&sortingOrder=asc&all==true')
        .then(response => {
            response.data.data.forEach(center => {
                centers.value.push({
                    text: center.data.name,
                    value: center.data.id
                })
            })
        })
        .catch(error => {
            httpErrorsHandler(error);
        });


}

onBeforeMount(() => {
    subscribeToCallChannel();
});
function subscribeToCallChannel() {
    if (authUser.value.role === "admin"){
        Echo.channel('calls_all')
            .listen('CallCreatedEvent', (event) => {
                //check if filter is applied
                if (filters.center_id !== null && filters.center_id !== event.call.data.center_id) {
                    return;
                }
                calls.value.unshift(event.call);
                calls.value.pop();
            })
            .listen('CallUpdatedEvent', (event) => {
                const index = calls.value.findIndex(call => call.data.id === event.call.data.id)
                if (index !== -1) {
                    calls.value[index] = event.call
                }
            });
    }else{
        authUser.value.centers.forEach(center => {
            Echo.channel('calls_center_' + center.id)
                .listen('CallCreatedEvent', (event) => {
                    //check if filter is applied
                    if (filters.center_id !== null && filters.center_id !== event.call.data.center_id) {
                        return;
                    }
                    calls.value.unshift(event.call);
                    calls.value.pop();
                })
                .listen('CallUpdatedEvent', (event) => {
                    const index = calls.value.findIndex(call => call.data.id === event.call.data.id)
                    if (index !== -1) {
                        calls.value[index] = event.call
                    }
                });
        });
    }
}

onUnmounted(() => {
    if (authUser.value.role === "admin"){
        Echo.leaveChannel('calls_all');
    }else{
        authUser.value.centers.forEach(center => {
            Echo.leaveChannel('calls_center_' + center.id);
        });
    }
});

const callToDisplay = ref(null);
function displayCallDetails(call){
    callToDisplay.value = call;
    const modalCallDetails = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalCallDetails'))
    modalCallDetails.show()
}

onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})
</script>

<template>
    <div class="row">
        <div class="col-lg-12 px-0 px-md-2">
            <div class="card">
                <div class="card-header">
                    <form>
                        <div class="row g-3">
                            <div class="col-xxl-5 col-sm-12">
                                <div class="search-box">
                                    <input type="text" class="form-control search" placeholder="Rechercher..."  v-model="query" @input="search" id="searchClientRequests">
                                    <i class="ri-search-line search-icon"></i>
                                </div>
                            </div>

                            <form-select-field
                                name="filters.center_id"
                                label="Sexe"
                                :label-hidden="true"
                                @update:field="filters.center_id = $event"
                                :errors="errors"
                                :required="true"
                                :data="filters.center_id"
                                bs-class="col-xxl-6 col-sm-8"
                                :disabled="false"
                                additional-class="bg-light border-light"
                                placeholder="Tous les centres"
                                :liste-options="centers"
                                margin="mb-0"
                            />

                            <div class="col-xxl-1 col-sm-4">
                                <button type="button" class="btn w-100" @click.prevent="applyFilter" :class="filtersApplied ? 'btn-dark' : 'btn-warning'">
                                    <i class="ri-equalizer-fill me-1 align-bottom"></i>
                                    Filtrer
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                    <tr>
                                        <th class="col-1 text-center" scope="col">Type</th>
                                        <th class="col-1" scope="col">Numéro</th>
                                        <th class="col-1" scope="col">Client</th>
                                        <th class="col-1" scope="col">Centre</th>
                                        <th class="col-1" scope="col">Date</th>
                                        <th class="col-1" scope="col">Durée</th>
                                        <th class="col-2 ps-4" scope="col">Enregistrement</th>
                                        <th class="col-1 text-center" scope="col">Détails</th>
                                        <th class="col-1 text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(call, index) in calls" :key="index">
                                    <td class="text-center">
                                        <span class="badge fs-11 bg-danger-subtle text-danger" v-if="defineCallTypeBadge(call) === 'missed'">Manqué <span v-if="call.data.missed_call_count > 0"> ({{ call.data.missed_call_count }})</span></span>
                                        <span class="badge fs-11 bg-warning-subtle text-warning" v-if="defineCallTypeBadge(call) === 'voicemail'">Messagerie</span>
                                        <span class="badge fs-11 bg-secondary-subtle text-secondary" v-if="defineCallTypeBadge(call) === 'out'">Sortant</span>
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="defineCallTypeBadge(call) === 'in'">Entrant</span>
                                    </td>
                                    <td>
                                        <i class="mdi mdi-phone-outgoing me-2 fs-20 align-middle text-secondary" v-if="defineCallTypeBadge(call) === 'out'"></i>
                                        <i class="mdi mdi-phone-incoming me-2 fs-20 align-middle text-success" v-if="defineCallTypeBadge(call) === 'in'"></i>
                                        <i class="mdi mdi-phone-missed me-2 fs-20 align-middle text-danger" v-if="defineCallTypeBadge(call) === 'missed'"></i>
                                        <i class="mdi mdi-voicemail me-2 fs-20 align-middle text-warning" v-if="defineCallTypeBadge(call) === 'voicemail'"></i>
                                        <span class="fw-semibold text-dark">{{ call.data.client.data.phone_number }}</span>
                                    </td>
                                    <td>
                                        <router-link :to="{ name: 'clients.show', params: { id: call.data.client.data.id }}" class="mr-1 align-middle text-secondary" title="Afficher la fiche client">
                                            {{ call.data.client.data.last_name }} {{ call.data.client.data.first_name }}
                                        </router-link>
                                        <router-link :to="{ name: 'clients.edit', params:{ id: call.data.client.data.id } }" class="text-info align-middle ms-1" title="Modifier le client" v-if="call.data.client.can.update">
                                            <i class="ri-pencil-fill fs-17 px-1 align-middle"></i>
                                        </router-link>
                                    </td>
                                    <td>{{ call.data.center.data.name }}</td>
                                    <td>{{ call.data.start_time_fr }}</td>
                                    <td>{{ call.data.total_duration }}</td>
                                    <td class="py-0">
                                        <audio class="players" v-if="call.data.record_link !== null" preload="none">
                                            <source :src="call.data.record_link" type="audio/mp3">
                                            Your browser does not support the audio element.
                                        </audio>
                                        <audio class="players" v-if="call.data.voicemail_link !== null">
                                            <source :src="call.data.voicemail_link" type="audio/mp3">
                                            Your browser does not support the audio element.
                                        </audio>
                                    </td>
                                    <td class="text-center">
                                        <button type="button" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm" @click.prevent="displayCallDetails(call)">Détails</button>
                                    </td>
                                    <td class="text-center">
                                        <!--Call-->
                                        <a :href="'tel:' + call.data.client.data.phone_number.replace(/\s+/g, '')" class="text-info" title="Appeler">
                                            <i class="ri-phone-fill fs-17 px-1"></i>
                                        </a>
                                        <!--Delete-->
                                        <a href="#" class="text-danger" title="Supprimer la demande" @click.prevent="showModalDelete(call.data.id, index)" v-if="call.can.delete">
                                            <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="calls.length === 0 && !loading">
                                    <td colspan="10" v-if="searchLoaded">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                        <p class="mt-2 h5">Aucun résultat</p>
                                        <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                    </td>
                                    <td colspan="10" v-else>Aucun appel...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="10">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                        ></pagination>
                    </div>

                    <modal id="modalDelete" title="Supprimer la demande client ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteCall">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer un appel.</p>
                        </template>
                    </modal>

                    <!--Modal calls details-->
                    <div class="modal fade zoomIn" id="modalCallDetails" tabindex="-1" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content" v-if="callToDisplay !== null">
                                <div class="modal-header">
                                    <h5 class="modal-title">Appel {{ callToDisplay.data.direction === 'in' ? 'du' : 'vers le' }} <span class="text-muted fw-normal">{{ callToDisplay.data.client.data.phone_number }}</span></h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="table-responsive">
                                        <table class="table table-nowrap mb-3">
                                            <tbody>
                                            <tr>
                                                <th scope="row" class="col-4">Type</th>
                                                <td>
                                                    <span class="badge fs-11 bg-danger-subtle text-danger" v-if="defineCallTypeBadge(callToDisplay) === 'missed'">Appel manqué <span v-if="callToDisplay.data.missed_call_count > 0"> ({{ callToDisplay.data.missed_call_count }})</span></span>
                                                    <span class="badge fs-11 bg-warning-subtle text-warning" v-if="defineCallTypeBadge(callToDisplay) === 'voicemail'">Messagerie</span>
                                                    <span class="badge fs-11 bg-secondary-subtle text-secondary" v-if="defineCallTypeBadge(callToDisplay) === 'out'">Appel sortant</span>
                                                    <span class="badge fs-11 bg-success-subtle text-success" v-if="defineCallTypeBadge(callToDisplay) === 'in'">Appel entrant</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" class="col-4">Centre</th>
                                                <td>{{ callToDisplay.data.center.data.name }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" class="col-4">Client</th>
                                                <td class="align-middle">
                                                    <router-link :to="{ name: 'clients.show', params: { id: callToDisplay.data.client.data.id }}" class="mr-1 align-middle text-secondary" title="Afficher la fiche client">
                                                        {{ callToDisplay.data.client.data.last_name }} {{ callToDisplay.data.client.data.first_name }}
                                                    </router-link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!--In-->
                                    <div class="profile-timeline" v-if="defineCallTypeBadge(callToDisplay) === 'voicemail'">
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item border-0">
                                                <div class="accordion-item border-0" v-for="(missedCall, index) in callToDisplay.data.missed_call_time" :key="index">
                                                    <div class="accordion-header" id="headingOne">
                                                        <a class="accordion-button p-2 shadow-none">
                                                            <div class="d-flex align-items-center">
                                                                <div class="flex-shrink-0 avatar-xs">
                                                                    <div class="avatar-title rounded-circle shadow" :class="(index + 1) < callToDisplay.data.missed_call_time.length ? 'bg-danger' : 'bg-warning'">
                                                                        <i class="mdi mdi-phone-missed-outline"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="flex-grow-1 ms-3">
                                                                    <h6 class="fs-15 mb-0 fw-semibold">Appel manqué - <span class="fw-normal text-muted">{{ missedCall }}</span></h6>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div id="collapseOne" class="accordion-collapse collapse show">
                                                    <div class="accordion-body ms-2 ps-5 pt-0">
                                                        <p class="mb-0 pt-3 align-middle"><i class="mdi mdi-voicemail me-2 align-middle fs-16"></i>Nouveau message vocal</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-dark rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-off"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel terminé - <span class="fw-normal text-muted">{{ callToDisplay.data.end_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Missed-->
                                    <div class="profile-timeline" v-if="defineCallTypeBadge(callToDisplay) === 'missed'">
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item border-0" v-for="(missedCall, index) in callToDisplay.data.missed_call_time" :key="index">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-danger rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-missed-outline"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel manqué - <span class="fw-normal text-muted">{{ missedCall }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Out-->
                                    <div class="profile-timeline" v-if="defineCallTypeBadge(callToDisplay) === 'out'">
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-secondary rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-outgoing"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel sortant - <span class="fw-normal text-muted">{{ callToDisplay.data.start_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id="collapseOne" class="accordion-collapse collapse show">
                                                    <div class="accordion-body ms-2 ps-5 pt-0">
                                                        <p class="mb-0 pt-3 align-middle"><i class="bx bx-time-five me-1 align-middle fs-16"></i>Durée : {{ callToDisplay.data.total_duration }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-dark rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-off"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel terminé - <span class="fw-normal text-muted">{{ callToDisplay.data.end_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--In-->
                                    <div class="profile-timeline" v-if="defineCallTypeBadge(callToDisplay) === 'in'">
                                        <div class="accordion accordion-flush" id="accordionFlushExample">

                                            <!-- Call hanged up -->
                                            <div class="accordion-item border-0" v-if="callToDisplay.data.missed_call_count === 0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-success rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-incoming"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel entrant - <span class="fw-normal text-muted">{{ callToDisplay.data.start_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id="collapseOne" class="accordion-collapse collapse show">
                                                    <div class="accordion-body ms-2 ps-5 pt-0">
                                                        <p class="mb-0 pt-3 align-middle"><i class="bx bx-time-five me-1 align-middle fs-16"></i>Durée : {{ callToDisplay.data.total_duration }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Missed call -->
                                            <div class="accordion-item border-0" v-for="(missedCall, index) in callToDisplay.data.missed_call_time" :key="index" v-else>
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title rounded-circle shadow bg-danger" >
                                                                    <i class="mdi mdi-phone-missed-outline"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel manqué - <span class="fw-normal text-muted">{{ missedCall }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <!--Call answered-->
                                            <div class="accordion-item border-0" v-if="callToDisplay.data.answered_time_fr !== null && callToDisplay.data.missed_call_count > 0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-success rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-incoming"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel entrant - <span class="fw-normal text-muted">{{ callToDisplay.data.answered_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id="collapseOne" class="accordion-collapse collapse show">
                                                    <div class="accordion-body ms-2 ps-5 pt-0">
                                                        <p class="mb-0 pt-3 align-middle"><i class="bx bx-time-five me-1 align-middle fs-16"></i>Durée : {{ callToDisplay.data.total_duration }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--Call ended-->
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-dark rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-off"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel terminé - <span class="fw-normal text-muted">{{ callToDisplay.data.end_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-soft-dark" data-bs-dismiss="modal">Fermer</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
