<script>
export default {
    name: "Breadcrumb"
}
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0">{{ $route.meta.breadcrumb.title }}</h4>

                <div class="page-title-right">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item" v-for="(item, index) in $route.meta.breadcrumb.nav.all.inactive" :key="index">
                            <router-link :to="item.link" class="text-secondary">{{ item.title }}</router-link>
                        </li>
                        <li class="breadcrumb-item active">{{ $route.meta.breadcrumb.nav.all.active }}</li>
                    </ol>
                </div>

            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
