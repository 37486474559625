import {createRouter, createWebHistory } from "vue-router";
import NotFound from "../Components/NotFound.vue";
import Unauthorized from "../Components/Unauthorized.vue";
import {userRoutes} from "./userRoutes.js";
import {dashboardRoutes} from "./dashboardRoutes.js";
import {centerRoutes} from "./centerRoutes.js";
import {clientRoutes} from "./clientRoutes.js";
import {newsRoutes} from "./newsRoutes.js";
import {newsCategoryRoutes} from "./newsCategoryRoutes.js";
import {clientRequestRoutes} from "./clientRequestRoutes.js";
import {callRoutes} from "./callRoutes.js";
import {surveyRoutes} from "./surveyRoutes.js";
import {ticketCategoryRoutes} from "./ticketCategoryRoutes.js";
import {ticketRoutes} from "./ticketRoutes.js";
import {socialNetworkMediaRoutes} from "./socialNetworkMediaRoutes.js";
import {eventsRoutes} from "./eventsRoutes.js";
import {chiptuningRoutes} from "./chiptuningRoutes.js";
import {invoiceZohoRoutes} from "./invoiceZohoRoutes.js";
import {helpArticleRoutes} from "./helpArticleRoutes.js";
import {helpArticleCategoryRoutes} from "./helpArticleCategoryRoutes.js";
import {defaultMessageRoutes} from "./defaultMessageRoutes.js";
import {informationRequestRoutes} from "./informationRequestRoutes.js";
import {productRoutes} from "./productRoutes.js";
import {quoteRoutes} from "./quoteRoutes.js";
import {invoiceRoutes} from "./invoiceRoutes.js";

const routes = [
    ...userRoutes,
    ...dashboardRoutes,
    ...centerRoutes,
    ...clientRoutes,
    ...newsRoutes,
    ...newsCategoryRoutes,
    ...clientRequestRoutes,
    ...callRoutes,
    ...surveyRoutes,
    ...ticketCategoryRoutes,
    ...ticketRoutes,
    ...socialNetworkMediaRoutes,
    ...eventsRoutes,
    ...chiptuningRoutes,
    ...invoiceZohoRoutes,
    ...helpArticleRoutes,
    ...helpArticleCategoryRoutes,
    ...defaultMessageRoutes,
    ...informationRequestRoutes,
    ...productRoutes,
    ...quoteRoutes,
    ...invoiceRoutes,
    {
        path: "/unauthorized",
        component: Unauthorized,
        name: 'unauthorized',
        meta: {
            menu: {
                parent: null,
                subParent: null
            },
            requiresAuth: true,
            breadcrumb: {
                title: "Non autorisé",
                nav: {
                    all: {
                        active: "Erreur 403",
                        inactive: []
                    }
                }
            }
        }
    },
    {
        path: "/:pathMatch(.*)*",
        component: NotFound,
        name: 'not-found',
        meta: {
            menu: {
                parent: null,
                subParent: null
            },
            breadcrumb: {
                title: "Page introuvable",
                nav: {
                    all: {
                        active: "Erreur 404",
                        inactive: []
                    }
                }
            }
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
