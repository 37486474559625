<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {computed, inject, onBeforeMount, onBeforeUnmount, reactive, ref} from "vue";
import Loader from "../../Components/Loader.vue";
import {useToast} from "vue-toast-notification";
import Chat from "../../Components/Chat.vue";
import FormSelectSearchUser from "../../Components/FormSelectSearchUser.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
const $toast = useToast();
const brandConfig = inject('brandConfig');
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();
const loading = ref(true)
const errors = ref(null)
const informationRequest = ref(null)
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
function getInformationRequest(informationRequestId) {
    loading.value = true;
    axios.get("/api/information-requests/" + informationRequestId)
        .then(response => {
            informationRequest.value = response.data;
            internalNotes.value = response.data.data.notes;
            loading.value = false;
            if (response.data.data.user_id !== authUser.value.id && response.data.data.assigned_to_id === null && (authUser.value.role === 'back_office' || authUser.value.role === 'admin')) {
                assignRequestToUser(authUser.value.id)
            }
            resetNotificationNumber();
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onBeforeMount(() => {
    getInformationRequest(router.currentRoute.value.params.id)
})

function changeStatus(status) {
    if (status === informationRequest.value.data.status) {
        return;
    }
    axios.patch("/api/information-requests/" + informationRequest.value.data.id + "/status", { status: status })
        .then(response => {
            informationRequest.value.data.status = response.data.data.status;
            informationRequest.value.data.status_label_fr = response.data.data.status_label_fr;
            informationRequest.value.data.status_badge_color = response.data.data.status_badge_color;
            $toast.success('Statut mis à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function resetNotificationNumber() {
    axios.patch("/api/information-requests/" + router.currentRoute.value.params.id + "/reset-notification-number")
        .then()
        .catch(error => {
            httpErrorsHandler(error);
        });
}

//Hide modal if leave page
onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

const informationRequestStatuses = reactive([
    { value: 'pending', label: 'À traiter', badgeColor: 'danger' },
    { value: 'in_progress', label: 'En cours', badgeColor: 'secondary' },
    { value: 'closed', label: 'Fermé', badgeColor: 'dark' },
]);

function showModalInternalNotes() {
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalInternalNotes'))
    modal.show();

}
const internalNotes = ref(null)
function updateInternalNotes() {
    axios.patch("/api/information-requests/" + informationRequest.value.data.id + "/notes", { notes: internalNotes.value })
        .then(response => {
            informationRequest.value.data.notes = response.data.data.notes;
            $toast.success('Note interne mise à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalInternalNotes'))
            modal.hide();
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const form = {
    assigned_to_id: null,
}
function assignRequestToUser(userId) {
    axios.patch("/api/information-requests/" + informationRequest.value.data.id + '/assigned-to', { assigned_to_id: userId })
        .then(response => {
            informationRequest.value.data.assigned_to = response.data.data.assigned_to;
            informationRequest.value.data.assigned_to_id = response.data.data.assigned_to_id;
            $toast.success('Le ticket a été assigné avec succès.', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
function assignRequestToUserWithModal() {
    if (form.assigned_to_id === null){
        $toast.info('Vous devez sélectionner un utilisateur pour l\'assigner au informationRequest.', {
            position: 'top',
            duration: 2000,
            dismissible: true,
            pauseOnHover: true,
        });
        return;
    }
    if (form.assigned_to_id === informationRequest.value.data.assigned_to_id){
        $toast.info('L\'utilisateur sélectionné est déjà assigné au informationRequest.', {
            position: 'top',
            duration: 2000,
            dismissible: true,
            pauseOnHover: true,
        });
        return;
    }
    assignRequestToUser(form.assigned_to_id)
    // Close modal
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalAssignUser'))
    modaleDelete.hide()
}
</script>

<template>
    <div class="row">
        <div class="col-12" v-show="loading">
            <loader />
        </div>
        <div class="col-xl-6" v-if="!loading">
            <div class="card border">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1">
                        <i class="mdi me-2 align-middle fs-4 mdi-book-information-variant"></i>
                        Demande d'information
                    </h4>
                    <div class="avatar-xs me-1">
                        <a href="#" class="avatar-title bg-warning-subtle text-warning fs-15 rounded" title="Notes" @click.prevent="showModalInternalNotes">
                            <i class="ri-sticky-note-fill"></i>
                        </a>
                    </div>
                    <div class="avatar-xs">
                        <router-link :to="{ name: 'information-requests.edit', params:{ id: informationRequest.data.id } }" class="avatar-title bg-secondary-subtle text-secondary fs-15 rounded" title="Modifier">
                            <i class="ri-edit-2-fill"></i>
                        </router-link>
                    </div>

                </div>
                <div class="card-body pb-0">
                    <div class="table-responsive">
                        <table class="table table-md table-nowrap mb-3">
                            <tbody>
                            <tr>
                                <th scope="row" class="col-5 align-middle">Assigné à</th>
                                <td class="align-middle">
                                    <div class="d-flex align-items-center" v-if="informationRequest.data.assigned_to_id !== null">
                                        <div class="flex-shrink-0"><img :src="'/format-image/'+informationRequest.data.assigned_to.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" alt="" class="avatar-xs rounded-circle"></div>
                                        <div class="flex-grow-1 ms-2 name">
                                            {{ informationRequest.data.assigned_to.data.last_name }} {{ informationRequest.data.assigned_to.data.first_name }}
                                            <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" data-bs-toggle="modal" data-bs-target="#modalAssignUser" v-if="informationRequest.can.update_details"></a>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center" v-else>
                                        --
                                        <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" data-bs-toggle="modal" data-bs-target="#modalAssignUser" v-if="informationRequest.can.update_details"></a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5 border-top-0">ID</th>
                                <td class="border-top-0 text-body fw-semibold">#{{ informationRequest.data.id }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Statut</th>
                                <td>
                                    <span :class="'fs-11 badge bg-'+informationRequest.data.status_badge_color+'-subtle  text-'+informationRequest.data.status_badge_color">{{ informationRequest.data.status_label_fr }}</span>
                                    <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" id="statusDropdown" data-bs-toggle="dropdown" v-if="informationRequest.can.update"></a>
                                    <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                        <li v-for="(status, key) in informationRequestStatuses" :key="key">
                                            <a class="dropdown-item" href="#" @click.prevent="changeStatus(status.value)">
                                                <span :class="`fs-11 badge bg-${status.badgeColor}-subtle text-${status.badgeColor}`">{{ status.label }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr v-if="informationRequest.data.vehicle_type_label_fr !== null || informationRequest.data.car_power_type_label_fr !== null">
                                <th scope="row" class="col-5">Type véhicule</th>
                                <td>{{ informationRequest.data.vehicle_type_label_fr }} - {{ informationRequest.data.car_power_type_label_fr }}</td>
                            </tr>
                            <tr v-if="informationRequest.data.car_brand !== null || informationRequest.data.car_model !== null">
                                <th scope="row" class="col-5">Marque - Modèle</th>
                                <td>{{ informationRequest.data.car_brand }} - {{ informationRequest.data.car_model }}</td>
                            </tr>
                            <tr v-if="informationRequest.data.car_version !== null || informationRequest.data.car_engine !== null">
                                <th scope="row" class="col-5 align-middle">Version - Moteur</th>
                                <td>
                                    <span class="align-middle">{{ informationRequest.data.car_version }} - {{ informationRequest.data.car_engine }}</span>
                                    <router-link :to="{ name: 'chiptuning.show', params:{ id: informationRequest.data.engine.data.id } }" target="_blank" class="ms-2 btn btn-sm btn-dark bg-gradient bg-gradient waves-effect waves-light btn-sm" v-if="informationRequest.data.engine !== null">
                                        Fiche moteur
                                    </router-link>
                                </td>
                            </tr>
                            <tr v-if="informationRequest.data.vin !== null">
                                <th scope="row" class="col-5">VIN</th>
                                <td>{{ informationRequest.data.vin }}</td>
                            </tr>
                            <tr v-if="informationRequest.data.license_plate !== null">
                                <th scope="row" class="col-5">Immatriculation</th>
                                <td>{{ informationRequest.data.license_plate }}</td>
                            </tr>
                            <tr v-if="informationRequest.data.vehicle_year !== null">
                                <th scope="row" class="col-5">Année du véhicule</th>
                                <td>{{ informationRequest.data.vehicle_year }}</td>
                            </tr>
                            <tr v-if="informationRequest.data.mileage !== null">
                                <th scope="row" class="col-5">Kilométrage</th>
                                <td>{{ informationRequest.data.mileage_formatted }} km</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5 border-bottom-0">Date création</th>
                                <td class="border-bottom-0">{{ informationRequest.data.created_at_fr }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-body border-top border-dashed border-end-0 border-start-0 border-bottom-0">
                    <!--Description-->
                    <div class="d-flex mb-0">
                        <div class="flex-shrink-0">
                            <img :src="'/format-image/'+informationRequest.data.user.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" class="avatar-xs rounded-circle shadow" />
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h5 class="fs-13">
                                {{ informationRequest.data.user.data.first_name }} {{ informationRequest.data.user.data.last_name }}
                            </h5>
                            <p class="text-body mb-0" v-html="informationRequest.data.description"></p>
                        </div>
                    </div>
                    <!--Notes-->
                    <div class="alert alert-warning alert-label-icon rounded-label shadow" role="alert" v-if="informationRequest.data.notes !== null">
                        <a href="#" class="ri-sticky-note-fill label-icon" @click.prevent="showModalInternalNotes"></a>
                        <strong>Notes : </strong><br>
                        <p v-html="informationRequest.data.notes.replace(/\n/g, '<br />')" class="mb-0"></p>
                    </div>
                </div>
            </div>
            <!-- Modal notes -->
            <div id="modalInternalNotes" class="modal fade" tabindex="-1" aria-labelledby="modalInternalNotes" aria-hidden="true" style="display: none;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalInternalNotes">Notes internes</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div class="modal-body">
                            <form-textarea
                                name="notes"
                                label="Notes internes"
                                :label-hidden="false"
                                @update:field="internalNotes = $event"
                                :errors="errors"
                                :required="false"
                                :data="internalNotes"
                                bs-class="col-xl-12"
                                :row="10"
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                            <button type="button" class="btn btn-secondary" @click.prevent="updateInternalNotes">Valider</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal assign user -->
            <div id="modalAssignUser" class="modal fade" tabindex="-1" aria-labelledby="modalAssignUserLabel" aria-hidden="true" style="display: none;">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalAssignUserLabel">Affecter un utilisateur</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div class="modal-body">
                            <form-select-search-user
                                :errors="errors"
                                label="Assigné à"
                                name="assigned_to_id"
                                :required="true"
                                :data="form.assigned_to_id"
                                @update:field="form.assigned_to_id = ($event !== null ? $event.id : null)"
                                bs-class="col-md-12"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                :user-pre-selected="null"
                                placeholder="Sélectionnez un utilisateur"
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                            <button type="button" class="btn btn-secondary" @click.prevent="assignRequestToUserWithModal">Valider</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6" v-if="!loading">
            <Chat
                :messageable-id="informationRequest.data.id"
                messageable-type="App\Models\InformationRequest"
                :show-is-internal="false"
                :show-default-messages="false"
            />
        </div>
    </div>
</template>

<style scoped>
</style>
