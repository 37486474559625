import InvoiceList from "../Views/Invoice/InvoiceList.vue";
import InvoiceCreate from "../Views/Invoice/InvoiceCreate.vue";
import InvoiceEdit from "../Views/Invoice/InvoiceEdit.vue";
import InvoiceShow from "../Views/Invoice/InvoiceShow.vue";

export const invoiceRoutes = [
    {
        path: "/invoices/edit/:id",
        name: "invoices.edit",
        component: InvoiceEdit,
        meta: {
            menu: {
                parent: 'invoices',
                child: null
            },
            breadcrumb: {
                title: "Factures clients",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Toutes les factures",
                            link: {name: 'invoices.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/invoices/create",
        name: "invoices.create",
        component: InvoiceCreate,
        meta: {
            menu: {
                parent: 'invoices',
                child: null
            },
            breadcrumb: {
                title: "Factures clients",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Toutes les factures",
                            link: {name: 'invoices.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/invoices/:id",
        name: "invoices.show",
        component: InvoiceShow,
        meta: {
            menu: {
                parent: 'invoices',
                child: null
            },
            breadcrumb: {
                title: "Factures clients",
                nav: {
                    all: {
                        active: "Détails du devis",
                        inactive: [{
                            title: "Toutes les factures",
                            link: {name: 'invoices.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/invoices",
        name: "invoices.list",
        component: InvoiceList,
        meta: {
            menu: {
                parent: 'invoices',
                child: null
            },
            breadcrumb: {
                title: "Factures clients",
                nav: {
                    all: {
                        active: "Toutes les factures",
                        inactive: null
                    }
                }
            }
        },
    }
];
