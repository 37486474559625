import TicketsCategoryEdit from "../Views/TicketsCategory/TicketsCategoryEdit.vue";
import TicketsCategoryCreate from "../Views/TicketsCategory/TicketsCategoryCreate.vue";
import TicketsCategoryList from "../Views/TicketsCategory/TicketsCategoryList.vue";

export const ticketCategoryRoutes = [
    {
        path: "/tickets-categories/edit/:id",
        name: "tickets-categories.edit",
        component: TicketsCategoryEdit,
        meta: {
            menu: {
                parent: 'tickets',
                child: 'tickets-categories'
            },
            breadcrumb: {
                title: "Catégories de ticket",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Toutes les catégories",
                            link: {name: 'tickets-categories.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/tickets-categories/create",
        name: "tickets-categories.create",
        component: TicketsCategoryCreate,
        meta: {
            menu: {
                parent: 'tickets',
                child: 'tickets-categories'
            },
            breadcrumb: {
                title: "Catégories de ticket",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Toutes les catégories",
                            link: {name: 'tickets-categories.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/tickets-categories",
        name: "tickets-categories.list",
        component: TicketsCategoryList,
        meta: {
            menu: {
                parent: 'tickets',
                child: 'tickets-categories'
            },
            breadcrumb: {
                title: "Catégories de ticket",
                nav: {
                    all: {
                        active: "Toutes les catégories",
                        inactive: []
                    }
                }
            }
        },
    }
];
