import NewsList from "../Views/News/NewsList.vue";
import NewsCreate from "../Views/News/NewsCreate.vue";
import NewsEdit from "../Views/News/NewsEdit.vue";
import NewsShow from "../Views/News/NewsShow.vue";
import {useAuthUserStore} from "../Store/AuthUserStore.js";
import {computed} from "vue";

export const newsRoutes = [
    {
        path: "/news/edit/:id",
        name: "news.edit",
        component: NewsEdit,
        beforeEnter: (to, from, next) => {
            const authUserStore = useAuthUserStore();
            const authUser = computed(() => authUserStore.authUser);
            if(authUser.value.role !== 'admin' && authUser.value.role !== 'back_office' ) {
                next('/unauthorized');
            } else {
                next();
            }
        },
        meta: {
            menu: {
                parent: 'news',
                child: 'news'
            },
            breadcrumb: {
                title: "Actualités",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Toutes les actualités",
                            link: {name: 'news.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/news/create",
        name: "news.create",
        component: NewsCreate,
        beforeEnter: (to, from, next) => {
            const authUserStore = useAuthUserStore();
            const authUser = computed(() => authUserStore.authUser);
            if(authUser.value.role !== 'admin' && authUser.value.role !== 'back_office' ) {
                next('/unauthorized');
            } else {
                next();
            }
        },
        meta: {
            menu: {
                parent: 'news',
                child: 'news'
            },
            breadcrumb: {
                title: "Actualités",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Toutes les actualités",
                            link: {name: 'news.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/news/:id",
        name: "news.show",
        component: NewsShow,
        meta: {
            menu: {
                parent: 'news',
                child: 'news'
            },
            breadcrumb: {
                title: "Actualités",
                nav: {
                    all: {
                        active: "Fiche utilisateur",
                        inactive: [{
                            title: "Toutes les actualités",
                            link: {name: 'news.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/news",
        name: "news.list",
        component: NewsList,
        meta: {
            menu: {
                parent: 'news',
                child: 'news'
            },
            breadcrumb: {
                title: "Actualités",
                nav: {
                    all: {
                        active: "Toutes les actualités",
                        inactive: []
                    }
                }
            }
        },
    }
];
