import NewsCategoryList from "../Views/NewsCategory/NewsCategoryList.vue";
import NewsCategoryCreate from "../Views/NewsCategory/NewsCategoryCreate.vue";
import NewsCategoryEdit from "../Views/NewsCategory/NewsCategoryEdit.vue";

export const newsCategoryRoutes = [
    {
        path: "/news-categories/edit/:id",
        name: "news-categories.edit",
        component: NewsCategoryEdit,
        meta: {
            menu: {
                parent: 'news',
                child: 'news-categories'
            },
            breadcrumb: {
                title: "Catégories d'actualités",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Toutes les catégories",
                            link: {name: 'news-categories.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/news-categories/create",
        name: "news-categories.create",
        component: NewsCategoryCreate,
        meta: {
            menu: {
                parent: 'news',
                child: 'news-categories'
            },
            breadcrumb: {
                title: "Catégories d'actualités",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Toutes les catégories",
                            link: {name: 'news-categories.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/news-categories",
        name: "news-categories.list",
        component: NewsCategoryList,
        meta: {
            menu: {
                parent: 'news',
                child: 'news-categories'
            },
            breadcrumb: {
                title: "Catégories d'actualités",
                nav: {
                    all: {
                        active: "Toutes les catégories",
                        inactive: []
                    }
                }
            }
        },
    }
];
