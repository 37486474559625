import { useRouter } from 'vue-router';
import {useToast} from "vue-toast-notification";

export function useHttpErrorsHandler() {

    const router = useRouter();
    const $toast = useToast();

    function httpErrorsHandler(errors) {

        if (!errors.response) {

            $toast.error('Une erreur inattendue est survenue', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            return;
        }

        const status = errors.response.status;
        const message = errors.response.data && errors.response.data.message
            ? errors.response.data.message
            : 'Erreur inconnue';

        switch (status) {
            case 401:
                $toast.error('Vous êtes déconnecté', {
                    position: 'top',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                });
                window.location.replace('/login');
                break;
            case 403:
                $toast.error('Vous n\'êtes pas autorisé à effectuer cette action', {
                    position: 'top',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                });
                router.replace({ path: '/unauthorized' })
                break;
            case 404:
                $toast.error('Ressource non trouvée', {
                    position: 'top',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                });
                router.replace({ path: '/not-found' })
                break;
            default:
                $toast.error(message, {
                    position: 'top',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                });
        }
    }

    return {
        httpErrorsHandler,
    };
}
