import ChiptuningList from "../Views/Chiptuning/ChiptuningList.vue";
import ChiptuningShow from "../Views/Chiptuning/ChiptuningShow.vue";

export const chiptuningRoutes = [
    {
        path: "/chiptuning/:id",
        name: "chiptuning.show",
        component: ChiptuningShow,
        meta: {
            menu: {
                parent: 'chiptuning',
                child: null
            },
            breadcrumb: {
                title: "Reprogrammation",
                nav: {
                    all: {
                        active: "Fiche moteur",
                        inactive: [{
                            title: "Toutes les préparations",
                            link: {name: 'chiptuning.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/chiptuning",
        name: "chiptuning.list",
        component: ChiptuningList,
        meta: {
            menu: {
                parent: 'chiptuning',
                child: null
            },
            breadcrumb: {
                title: "Reprogrammation",
                nav: {
                    all: {
                        active: "Toutes les préparations",
                        inactive: []
                    }
                }
            }
        },
    }
];
