<template>
    <div class="mb-3" :class="bsClass" >
        <label :for="name" class="form-label">
            {{ label }}
            <span class="text-danger" v-if="required"> *</span>
        </label>
        <input
            :id="name"
            class="form-control"
            autocomplete="off"
            type="number"
            :class="errorClassObject()"
            :placeholder="placeholder"
            :name="name"
            :required="required"
            @input="updateField()"
            v-model="value"
            :disabled="disabled"
            :min="min"
            :max="max"
            :step="step"
        />
        <span class="invalid-feedback" v-html="errorMessage()"></span>
        <span class="form-text text-muted" v-if="informationContent !== null">
            {{ informationContent }}
        </span>
    </div>
</template>

<script>
export default {
    props : {
        name : {
            type: String,
            required: true
        },
        label : {
            type: String,
            required: true
        },
        required : {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        informationContent : {
            type: String,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        disabled : {
            type: Boolean,
            required: false,
            default: false
        },
        data: {
            required: false,
            default: null
        },
        min: {
            type: Number,
            required: false,
            default: null
        },
        max: {
            type: Number,
            required: false,
            default: null
        },
        step: {
            type: Number,
            required: false,
            default: null
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        }
    },
    data() {
        return {
            value: null
        };
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            );
        }
    },
    methods: {
        updateField: function() {
            this.clearErrors(this.name);
            this.$emit("update:field", this.value);
        },
        errorMessage: function() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        },
        clearErrors: function() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject: function() {
            return {
                "is-invalid": this.hasError
            };
        }
    },
    watch: {
        data: {
            immediate: true,
            handler(newVal, oldVal) {
                this.value = newVal;
            },
        }
    }
};
</script>
