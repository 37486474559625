<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import {useRouter} from "vue-router";
import {reactive, ref} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import FormInputFile from "../../Components/FormInputFile.vue";
import FormSelectCenters from "../../Components/FormSelectCenters.vue";
import Editor from '@tinymce/tinymce-vue'
import FormSelectCategory from "../../Components/FormSelectCategory.vue";
import FormInputFilePond from "../../Components/FormInputFilePond.vue";

const { httpErrorsHandler } = useHttpErrorsHandler();
const $toast = useToast();
const router = useRouter();

const loading = ref(false)
const isUploading = ref(true)
const form = reactive( {
    title: null,
    image: null,
    content: null,
    category_id: true,
    attachments: []
})
const errors = ref(null)
const percentage = ref(0)
function createHelpArticle() {

    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (key === 'attachments') {
            //FileList is not iterable, so we convert it to an array
            return Array.from(value).forEach(attachment => {
                formData.append(`${key}[]`, attachment);
            });
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    const config = {
        onUploadProgress: progressEvent => percentage.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    }

    axios.post("/api/help-articles", formData, config)
        .then(response => {
            $toast.success('Fiche d\'aide créée avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.back();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}
const uploadImageHandler = (blobInfo, progress) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', '/api/upload-image');  // Modifiez ici pour utiliser votre endpoint d'API

    xhr.upload.onprogress = (e) => {
        progress(e.loaded / e.total * 100);  // Mettre à jour la progression pour l'UI
    };

    xhr.onload = () => {
        if (xhr.status === 403) {
            reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
            return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
            reject('HTTP Error: ' + xhr.status);
            return;
        }

        const json = JSON.parse(xhr.responseText);
        if (!json || typeof json.location !== 'string') {
            reject('Invalid JSON: ' + xhr.responseText);
            return;
        }

        resolve(json.location);  // Utiliser l'URL de l'image renvoyée par le serveur
    };

    xhr.onerror = () => {
        reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };

    const formData = new FormData();
    formData.append('image', blobInfo.blob(), blobInfo.filename());
    formData.append('path', 'news');  // Vous pouvez ajuster ce paramètre si nécessaire

    // Ajouter le token CSRF
    const token = document.head.querySelector('meta[name="csrf-token"]').content;
    xhr.setRequestHeader('X-CSRF-Token', token);

    xhr.send(formData);
});
const tinyMceLoading = ref(true);
function pasPostProcess(plugin, args) {
    args.node.innerHTML = args.node.innerHTML.replace(/style="color: #2d65cd;"/g, '');
    args.node.innerHTML = args.node.innerHTML.replace(/style="color: #212529;"/g, '');
    args.node.querySelectorAll('img').forEach(img => {
        img.classList.add('img-fluid');
    });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card" v-show="loading" >
                <div class="card-body">
                    <loader :is-uploading="isUploading" :percentage="percentage" />
                </div>
            </div>
            <form v-on:submit.prevent="createHelpArticle" v-show="!loading" >
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-help-circle-outline me-2 align-middle fs-4"></i>Créer une fiche d'aide</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-text-field
                                name="title"
                                label="Titre"
                                :label-hidden="false"
                                @update:field="form.title = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.title"
                                bs-class="col-md-12"
                            />

                            <form-select-category
                                :errors="errors"
                                label="Catégorie"
                                name="category_id"
                                :required="true"
                                :data="form.category_id"
                                @update:field="form.category_id = ($event !== null ? $event.id : null)"
                                bs-class="col-md-12"
                                :category-pre-selected="null"
                                placeholder="Sélectionnez une catégorie"
                                category-type="App\Models\HelpArticle"
                            />

                            <form-input-file
                                name="image"
                                label="Image d'illustration"
                                :label-hidden="false"
                                :errors="errors"
                                :required="true"
                                :data="form.image"
                                @update:field="form.image = $event"
                                bs-class="col-md-12"
                                information-content="Dimensions : 800px par 470px. Taille max : 5Mo. Formats acceptés : .jpg, .jpeg, .png, .webp"
                                :disabled="false"
                                accepted=".jpg, .jpeg, .png, .webp"
                            />

                            <div class="mb-3">
                                <label for="tinyEditor" class="form-label">Contenu <span class="text-danger"> *</span> </label>
                                <div class="text-center my-2" v-if="tinyMceLoading">
                                    <div class="spinner-grow text-dark" role="status">
                                    </div>
                                    <br>
                                </div>
                                <Editor
                                    id="tinyEditor"
                                    v-model="form.content"
                                    :init="{
                                        license_key: 'gpl',
                                        base_url: '/tinymce/js/tinymce',
                                        suffix: '.min',
                                        language: 'fr_FR',
                                        content_style: 'body { font-family: \'Inter\', sans-serif; font-size: 14px; }',
                                        menubar : false,
                                        statusbar: false,
                                        plugins: ['lists', 'link', 'fullscreen', 'image', 'media', 'autoresize'],
                                        toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | link | fullscreen | image | media',
                                        images_upload_handler: uploadImageHandler,
                                        paste_data_images: true,
                                        paste_as_text: false,
                                        paste_webkit_styles: 'color font-weight strong',
                                        images_upload_credentials: true,
                                        images_upload_base_path: null,
                                        min_height: 200,
                                        max_height: 600,
                                        relative_urls: false,
                                        image_class_list: [
                                            {title: 'Image responsive', value: 'img-fluid img-thumbnail'},
                                        ],
                                        init_instance_callback: function(editor) {
                                            tinyMceLoading = false;
                                        },
                                        paste_postprocess: pasPostProcess
                                    }"
                                />
                                <span class="form-text text-muted mt-0">Enter = nouveau paragraphe. Maj + Enter = retour à la ligne.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-attachment me-2 align-middle fs-4"></i>Fichiers joints</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <form-input-file-pond
                                name="form.attachments"
                                label="Fichiers joints"
                                :label-hidden="false"
                                :errors="errors"
                                :required="false"
                                :data="form.attachments"
                                @update:field="form.attachments = $event"
                                bs-class="col-md-12"
                                :disabled="false"
                                :multiple="true"
                            />

                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
