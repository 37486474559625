import UserList from "../Views/User/UserList.vue";
import UserCreate from "../Views/User/UserCreate.vue";
import UserEdit from "../Views/User/UserEdit.vue";
import UserProfile from "../Views/User/UserProfile.vue";
import UserShow from "../Views/User/UserShow.vue";
import ManagementTeamList from "../Views/User/ManagementTeamList.vue";
import {useAuthUserStore} from "../Store/AuthUserStore.js";
import {computed} from "vue";

export const userRoutes = [
    {
        path: "/management-team",
        name: "users.management-team",
        component: ManagementTeamList,
        meta: {
            menu: {
                parent: 'management-team',
                child: null
            },
            breadcrumb: {
                title: "Équipe de direction",
                nav: {
                    all: {
                        active: "Équipe de direction",
                        inactive: []
                    }
                }
            }
        },
    },
    {
        path: "/profile",
        name: "users.profile",
        component: UserProfile,
        meta: {
            menu: {
                parent: 'profile',
                child: null
            },
            breadcrumb: {
                title: "Mon profil",
                nav: {
                    all: {
                        active: "Mon profil",
                        inactive: []
                    }
                }
            }
        },
    },
    {
        path: "/users/edit/:id",
        name: "users.edit",
        component: UserEdit,
        beforeEnter: (to, from, next) => {
            const authUserStore = useAuthUserStore();
            const authUser = computed(() => authUserStore.authUser);
            if(authUser.value.role !== 'admin' && authUser.value.role !== 'back_office' ) {
                next('/unauthorized');
            } else {
                next();
            }
        },
        meta: {
            menu: {
                parent: 'users',
                child: null
            },
            breadcrumb: {
                title: "Utilisateurs",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Tous les utilisateurs",
                            link: {name: 'users.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/users/create",
        name: "users.create",
        component: UserCreate,
        beforeEnter: (to, from, next) => {
            const authUserStore = useAuthUserStore();
            const authUser = computed(() => authUserStore.authUser);
            if(authUser.value.role !== 'admin' && authUser.value.role !== 'back_office' ) {
                next('/unauthorized');
            } else {
                next();
            }
        },
        meta: {
            menu: {
                parent: 'users',
                child: null
            },
            breadcrumb: {
                title: "Utilisateurs",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Tous les utilisateurs",
                            link: {name: 'users.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/users/:id",
        name: "users.show",
        component: UserShow,
        meta: {
            menu: {
                parent: 'users',
                child: null
            },
            breadcrumb: {
                title: "Utilisateurs",
                nav: {
                    all: {
                        active: "Fiche utilisateur",
                        inactive: [{
                            title: "Tous les utilisateurs",
                            link: {name: 'users.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/users",
        name: "users.list",
        component: UserList,
        beforeEnter: (to, from, next) => {
            const authUserStore = useAuthUserStore();
            const authUser = computed(() => authUserStore.authUser);
            if(authUser.value.role === 'staff' || authUser.value.role === 'external' ) {
                next('/unauthorized');
            } else {
                next();
            }
        },
        meta: {
            menu: {
                parent: 'users',
                child: null
            },
            breadcrumb: {
                title: "Utilisateurs",
                nav: {
                    all: {
                        active: "Tous les utilisateurs",
                        inactive: []
                    }
                }
            }
        },
    }
];
