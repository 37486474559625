<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {onBeforeMount, reactive, ref, watch} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import {useToast} from "vue-toast-notification";
import FormSelectField from "../../Components/FormSelectField.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import FormNumericField from "../../Components/FormNumericField.vue";
import axios from "axios";

const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();

const loading = ref(false)
const form = reactive( {
    product_code: null,
    name: null,
    description: null,
    type: 'service',
    price_excluding_tax: 0,
    currency: 'EUR',
})
const priceWithTax = ref(0)
const vatRate = ref(20)
const errors = ref(null)
function updateProduct() {

    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    formData.append('_method', 'PUT');
    axios.post("/api/products/" + router.currentRoute.value.params.id, formData)
        .then(() => {
            $toast.success('Produit/Service modifié avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.back();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}

function roundToTwo(num) {
    return Math.round(num * 100) / 100;
}
function getProduct() {
    loading.value = true;
    axios.get("/api/products/" + router.currentRoute.value.params.id)
        .then(response => {
            Object.entries(response.data.data).forEach(([key, value]) => {
                if(form.hasOwnProperty(key)){
                    form[key] = value;
                }
            });
            priceWithTax.value = roundToTwo(response.data.data.price_excluding_tax * (1 + vatRate.value / 100));
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
onBeforeMount(() => {
    getProduct()
})

watch([priceWithTax, vatRate], ([priceWithTax, vatRate]) => {
    form.price_excluding_tax = (priceWithTax / (1 + vatRate / 100)).toFixed(2);
});
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <!--Loader-->
            <div class="card" v-show="loading">
                <div class="card-body">
                    <loader/>
                </div>
            </div>
            <!--Form-->
            <form v-on:submit.prevent="updateProduct" v-show="!loading">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-bookmark-plus-outline me-2 align-middle fs-4"></i>Modifier un message prédéfini</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-text-field
                                name="product_code"
                                label="Code produit"
                                :label-hidden="false"
                                @update:field="form.product_code = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.product_code"
                                bs-class="col-xl-4"
                            />

                            <form-select-field
                                name="type"
                                label="Type"
                                :label-hidden="false"
                                @update:field="form.type = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.type"
                                bs-class="col-xl-2"
                                :disabled="false"
                                :liste-options="[
                                    { text: 'Service', value: 'service' },
                                    { text: 'Produit', value: 'product' },
                                ]"
                            />

                            <form-text-field
                                name="name"
                                label="Nom du produit/service"
                                :label-hidden="false"
                                @update:field="form.name = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.name"
                                bs-class="col-xl-6"
                            />

                            <form-numeric-field
                                name="priceWithTax"
                                label="Prix TTC"
                                :label-hidden="false"
                                @update:field="priceWithTax = $event"
                                :errors="errors"
                                :required="false"
                                :data="priceWithTax"
                                bs-class="col-xl-3"
                                :min="0"
                                :step="0.01"
                                information-content="Aide au calcul du prix HT"
                            />

                            <form-numeric-field
                                name="vatRate"
                                label="Taux TVA"
                                :label-hidden="false"
                                @update:field="vatRate = $event"
                                :errors="errors"
                                :required="false"
                                :data="vatRate"
                                bs-class="col-xl-3"
                                :min="0"
                                :step="0.01"
                                information-content="Aide au calcul du prix HT"
                            />

                            <form-numeric-field
                                name="price_excluding_tax"
                                label="Prix HT"
                                :label-hidden="false"
                                @update:field="form.price_excluding_tax = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.price_excluding_tax"
                                bs-class="col-xl-3"
                                :min="0"
                                :step="0.01"
                            />

                            <form-select-field
                                name="currency"
                                label="Devise"
                                :label-hidden="false"
                                @update:field="form.currency = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.currency"
                                bs-class="col-xl-3"
                                :disabled="false"
                                :liste-options="[
                                    { text: 'EUR', value: 'EUR' },
                                ]"
                            />

                            <form-textarea
                                name="description"
                                label="Description du produit/service"
                                :label-hidden="false"
                                @update:field="form.description = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.description"
                                bs-class="col-xl-12"
                                :row="2"
                                information-content="La description apparaitra sur les factures et devis."
                            />
                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
