<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, onBeforeMount, onBeforeUnmount, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import {useToast} from "vue-toast-notification";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref(null);
const sortingField = ref('created_at');
const sortingFieldsOrder = reactive({
    created_at: 'asc',
});
watch(url, (newValue) => {
    localStorage.setItem('surveysListUrl', url.value);
    localStorage.setItem('surveysSortingField', sortingField.value);
    localStorage.setItem('surveysSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
});

onBeforeMount(() => {
    if (localStorage.getItem('surveysListUrl')) {
        url.value = localStorage.getItem('surveysListUrl');
        sortingField.value = localStorage.getItem('surveysSortingField');
        sortingFieldsOrder[sortingField.value] = localStorage.getItem('surveysSortingFieldsOrder');
    }else{
        url.value = '/api/surveys?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    }
    loadData(url.value);
})

//Get data
const loading = ref(true);
const surveys = ref([]);
function loadData(urlToFetch) {
    surveys.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const savedResponse = ref(null);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response, mustSave = true) {
    surveys.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
    if (mustSave === true) {
        savedResponse.value = response;
    }
}

//Ordering table
function changeOrdering(sortingValue) {
    sortingField.value = sortingValue;
    sortingFieldsOrder[sortingField.value] = sortingFieldsOrder[sortingField.value] === 'asc' ? 'desc' : 'asc';

    url.value = '/api/surveys?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    loadData(url.value);
}
function activeSorting(sortingValue){
    return sortingField.value === sortingValue ? 'text-secondary' : 'text-dark';
}

const modaleDeleteShown = ref(false);
const idToArchive = ref(null);
const indexToArchive = ref(null);
function showModalDelete(id, index) {
    idToArchive.value = id;
    indexToArchive.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalArchive'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function deleteSurvey() {
    axios.delete('/api/surveys/' + idToArchive.value)
        .then(response => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalArchive'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-auto ms-auto" v-if="authUser.can && authUser.can.create_survey">
                            <router-link :to="{ name:'surveys.create' }" class="btn btn-secondary">
                                <i class="ri-add-fill align-bottom"></i> Ajouter
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                <tr>
                                    <th class="col-1">
                                        <span class="fw-bold pe-4">#ID</span>
                                    </th>
                                    <th class="col-5" scope="col">Titre</th>
                                    <th class="col-1 text-center" scope="col">Public</th>
                                    <th class="col-1" scope="col">Date fin</th>
                                    <th class="col-1 text-center" scope="col">Échéance</th>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('created_at')" :class="activeSorting('created_at')" class="pe-4">Date création</a>
                                    </th>
                                    <th class="col-1 text-center" scope="col">Lien</th>
                                    <th class="col-1 text-center" scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(survey, index) in surveys" :key="index">
                                    <td class="fw-medium text-dark">#{{ survey.data.id }}</td>
                                    <td>{{ survey.data.title }}</td>
                                    <td class="text-center">
                                        <span class="badge fs-11 bg-info-subtle text-info" v-if="survey.data.is_public">Public</span>
                                        <span class="badge fs-11 bg-secondary-subtle text-secondary" v-else>Personnalisé</span>
                                    </td>
                                    <td>{{ survey.data.end_date_fr }}</td>
                                    <td class="text-center">
                                        <span class="badge fs-11 bg-secondary-subtle text-secondary" v-if="survey.data.dead_line === 0">Aujourd'hui</span>
                                        <span class="badge fs-11 bg-dark-subtle text-dark" v-if="survey.data.dead_line < 0">Passée</span>
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="survey.data.dead_line > 0">{{ survey.data.dead_line }} jours</span>
                                    </td>
                                    <td>{{ survey.data.created_at_fr }}</td>
                                    <td class="text-center">
                                        <a :href="survey.data.google_form_link" target="_blank" class="btn btn-sm btn-secondary btn-label waves-effect waves-light" :class="survey.data.dead_line < 0 ? 'disabled' : ''">
                                            <div class="d-flex">
                                                <div class="flex-shrink-0">
                                                    <i class="ri-external-link-fill label-icon align-middle fs-16 me-2"></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    Accéder
                                                </div>
                                            </div>
                                        </a>
                                    </td>
                                    <td class="text-center">
                                        <!--UPDATE-->
                                        <router-link :to="{ name: 'surveys.edit', params:{ id: survey.data.id } }" class="text-info" title="Modifier le sondage" v-if="survey.can.update">
                                            <i class="ri-pencil-fill fs-17 px-1"></i>
                                        </router-link>
                                        <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                        <!--Delete-->
                                        <a href="#" class="text-danger" title="Supprimer la demande" @click.prevent="showModalDelete(survey.data.id, index)" v-if="survey.can.delete">
                                            <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-delete-bin-3-fill fs-17 px-1 text-muted" v-else></i>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="surveys.length === 0 && !loading">
                                    <td colspan="9">Aucun sondage...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="9">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                        ></pagination>
                    </div>

                    <modal id="modalArchive" title="Supprimer le sondage ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteSurvey">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer un sondage.</p>
                        </template>
                    </modal>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
