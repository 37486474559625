<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {onBeforeMount, reactive, ref} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import {useToast} from "vue-toast-notification";
import FormSelectCenter from "../../Components/FormSelectCenter.vue";
import Editor from "@tinymce/tinymce-vue";

const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();

const loading = ref(false)
const form = reactive( {
    title: null,
    body: null,
    center_id: null,
})
const errors = ref(null)
const centersPreSelected = ref([])
function updateDefaultMessage() {

    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    formData.append('_method', 'PUT');
    axios.post("/api/default-messages/" + router.currentRoute.value.params.id, formData)
        .then(response => {
            $toast.success('Message prédéfini modifié avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.back();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}

function getDefaultMessage() {
    loading.value = true;
    axios.get("/api/default-messages/" + router.currentRoute.value.params.id)
        .then(response => {
            Object.entries(response.data.data).forEach(([key, value]) => {
                if (key === 'center') {
                    return centersPreSelected.value = value;
                }
                if(form.hasOwnProperty(key)){
                    form[key] = value;
                }
            });
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
onBeforeMount(() => {
    getDefaultMessage()
})
const tinyMceLoading = ref(true);
function pasPostProcess(plugin, args) {
    args.node.innerHTML = args.node.innerHTML.replace(/style="color: #2d65cd;"/g, '');
    args.node.innerHTML = args.node.innerHTML.replace(/style="color: #212529;"/g, '');
    args.node.querySelectorAll('img').forEach(img => {
        img.classList.add('img-fluid');
    });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <!--Loader-->
            <div class="card" v-show="loading">
                <div class="card-body">
                    <loader/>
                </div>
            </div>
            <!--Form-->
            <form v-on:submit.prevent="updateDefaultMessage" v-show="!loading">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-bookmark-plus-outline me-2 align-middle fs-4"></i>Modifier un message prédéfini</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-text-field
                                name="title"
                                label="Titre"
                                :label-hidden="false"
                                @update:field="form.title = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.title"
                                bs-class="col-md-6"
                            />

                            <form-select-center
                                :errors="errors"
                                label="Centre"
                                name="center_id"
                                :required="false"
                                :data="form.center_id"
                                @update:field="form.center_id = ($event !== null ? $event.id : null)"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                :centers-pre-selected="null"
                                placeholder="Sélectionnez un centre"
                                information-content="Si aucun centre n'est sélectionné, le message prédéfini sera disponible pour tous les centres."
                                :center-pre-selected="centersPreSelected"
                            />

                            <div class="mb-3">
                                <label for="tinyEditor" class="form-label">Contenu du message <span class="text-danger"> *</span> </label>
                                <div class="text-center my-2" v-if="tinyMceLoading">
                                    <div class="spinner-grow text-dark" role="status">
                                    </div>
                                    <br>
                                </div>
                                <Editor
                                    id="tinyEditor"
                                    v-model="form.body"
                                    :init="{
                                        license_key: 'gpl',
                                        base_url: '/tinymce/js/tinymce',
                                        suffix: '.min',
                                        contextmenu: false,
                                        browser_spellcheck: true,
                                        language: 'fr_FR',
                                        menubar: false,
                                        content_style: 'body { font-family: \'Inter\', sans-serif; font-size: 13px; }',
                                        statusbar: false,
                                        plugins: ['lists', 'link', 'autoresize'],
                                        toolbar: 'bold italic underline | bullist numlist | link | forecolor | emoticons fullscreen',
                                        paste_data_images: false,
                                        paste_as_text: false,
                                        paste_webkit_styles: 'color font-weight strong',
                                        autoresize_bottom_margin: 0,
                                        min_height: 100,
                                        max_height: 600,
                                        init_instance_callback: function(editor) {
                                            tinyMceLoading = false;
                                        },
                                        paste_postprocess: pasPostProcess
                                    }"
                                />
                                <span class="form-text text-muted mt-0">Enter = nouveau paragraphe. Maj + Enter = retour à la ligne.</span>
                            </div>

                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
