<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import {useRouter} from "vue-router";
import {reactive, ref} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import FormSwitch from "../../Components/FormSwitch.vue";
import FormDatePicker from "../../Components/FormDatePicker.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import FormInputFile from "../../Components/FormInputFile.vue";
import FormSelectCenters from "../../Components/FormSelectCenters.vue";
import FormPhoneField from "../../Components/FormPhoneField.vue";

const { httpErrorsHandler } = useHttpErrorsHandler();
const $toast = useToast();
const router = useRouter();

const loading = ref(false)
const isUploading = ref(false)
const form = reactive( {
    first_name: null,
    last_name: null,
    email: null,
    email_exists: true,
    birth_date: null,
    gender: null,
    phone_number: null,
    role: null,
    profile_picture: null,
    bio: null,
    job_title: null,
    centers: [],
    management_team: false,
})
const errors = ref(null)
const percentage = ref(0)
function createUser() {

    if (form.profile_picture !== null) {
        isUploading.value = true;
    }

    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (key === 'centers') {
            return value.forEach((center) => {
                formData.append(`${key}[]`, center.id);
            });
        }
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    const config = {
        onUploadProgress: progressEvent => percentage.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    }

    axios.post("/api/users", formData, config)
        .then(response => {
            $toast.success('Utilisateur ajouté avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.back();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <form v-on:submit.prevent="createUser">
                <div class="card">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-account-plus-outline me-2 align-middle fs-4"></i>Créer un utilisateur</h4>
                </div>
                <div class="card-body">
                    <loader v-if="loading" :is-uploading="isUploading" :percentage="percentage" />
                    <div class="row" v-if="!loading">

						<form-text-field
                            name="first_name"
                            label="Prénom"
                            :label-hidden="false"
                            @update:field="form.first_name = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.first_name"
                            bs-class="col-md-4"
						/>

						<form-text-field
                            name="last_name"
                            label="Nom"
                            :label-hidden="false"
                            @update:field="form.last_name = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.last_name"
                            bs-class="col-md-4"
						/>

                        <form-text-field
                            name="email"
                            label="Email"
                            :label-hidden="false"
                            @update:field="form.email = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.email"
                            bs-class="col-md-4"
                            type="email"
                        />

                        <form-switch
                            name="email_exists"
                            label="L'email existe ?"
                            :label-hidden="false"
                            @update:field="form.email_exists = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.email_exists"
                            bs-class="col-md-12"
                            :disabled="false"
                            information-content="Cette information est utile pour l'envoi d'email."
                        />

                        <form-switch
                            name="management_team"
                            label="Équipe de direction"
                            :label-hidden="false"
                            @update:field="form.management_team = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.management_team"
                            bs-class="col-md-12"
                            :disabled="false"
                            information-content="L'utilisateur sera affiché dans la section 'Équipe de direction'."
                        />

                        <form-select-field
                            name="role"
                            label="Role"
                            :label-hidden="false"
                            @update:field="form.role = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.role"
                            bs-class="col-md-6"
                            :disabled="false"
                            :liste-options="[
                                { text: 'Administrateur', value: 'admin' },
                                { text: 'Back Office', value: 'back_office' },
                                { text: 'Externe', value: 'external' },
                                { text: 'Manager', value: 'manager' },
                                { text: 'Membre de l\'équipe', value: 'staff' },
                            ]"
                        />

                        <form-select-field
                            name="gender"
                            label="Civilité"
                            :label-hidden="false"
                            @update:field="form.gender = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.gender"
                            bs-class="col-md-6"
                            :disabled="false"
                            :liste-options="[
                                { text: 'Monsieur', value: 'man' },
                                { text: 'Madame', value: 'woman' },
                                { text: 'Autre', value: 'other' },
                            ]"
                        />

                        <form-date-picker
                            name="birth_date"
                            label="Date de naissance"
                            :label-hidden="false"
                            @update:field="form.birth_date = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.birth_date"
                            bs-class="col-md-6"
                            :disabled="false"
                        />

                        <form-phone-field
                            name="phone_number"
                            label="Numéro de téléphone"
                            :label-hidden="false"
                            @update:field="form.phone_number = $event"
                            :errors="errors"
                            :required="form.management_team"
                            bs-class="col-md-6"
                            :data="form.phone_number"
                        />

                        <form-text-field
                            name="job_title"
                            label="Poste occupé"
                            :label-hidden="false"
                            @update:field="form.job_title = $event"
                            :errors="errors"
                            :required="form.management_team"
                            :data="form.job_title"
                            bs-class="col-md-6"
                            type="text"
                        />

                        <form-input-file
                            name="profile_picture"
                            label="Photo de profil"
                            :label-hidden="false"
                            :errors="errors"
                            :required="false"
                            :data="form.profile_picture"
                            @update:field="form.profile_picture = $event"
                            bs-class="col-md-6"
                            information-content="Taille max : 5Mo. Formats acceptés : .jpg, .jpeg, .png, .webp"
                            :disabled="false"
                            accepted=".jpg, .jpeg, .png, .webp"
                        />

                        <form-select-centers
                            :errors="errors"
                            label="Centres"
                            name="centers"
                            :required="true"
                            :data="form.centers"
                            @update:field="form.centers = $event"
                            bs-class="col-md-12"
                            :disabled="false"
                            :taggable="false"
                            :label-hidden="false"
                            :centers-pre-selected="null"
                            placeholder="Sélectionnez un ou plusieurs centres"
                        />
                        <div class="d-flex gap-2 justify-content-end">
                            <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                            <button type="submit" class="btn btn-secondary">Valider</button>
                        </div>
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
