<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, onBeforeMount, ref} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import {useToast} from "vue-toast-notification";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();


onBeforeMount(() => {
    getManagementTeam();
})

const loading = ref(true);
const users = ref([]);
function getManagementTeam() {
    users.value = [];
    loading.value = true;
    axios.get('/api/users', { params : {
            management_team: true,
            sortingField : 'first_name',
            sortingOrder : 'asc',
        }})
        .then(response => {
            users.value = response.data.data;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
</script>

<template>
    <div class="row">
        <div class="col-12" v-show="loading">
            <loader />
        </div>
        <div class="col-xxl-4 col-sm-6 project-card" v-for="(user, index) in users" :key="index">
            <div class="card card-height-100">
                <div class="card-body">
                    <div class="d-flex flex-column h-100">
                        <div class="d-flex mb-3 mt-2">
                            <div class="flex-shrink-0 me-3">
                                <img class="rounded avatar-md shadow border" :src="'/format-image/'+user.data.profile_picture+'?w=110&h=110&fm=webp&fit=crop'" data-holder-rendered="true" :alt="'Avatar' + user.data.last_name">
                            </div>
                            <div class="flex-grow-1 d-flex flex-column justify-content-center">
                                <h5 class="mb-1 fs-15 text-body">{{ user.data.first_name }} {{ user.data.last_name }}</h5>
                                <p class="text-muted text-truncate-two-lines mb-1">{{ user.data.job_title }}</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-center mt-2 mb-0">
                            <p class="text-muted mb-0 me-2">
                                <i class="ri-mail-send-line fs-15 me-1 align-middle"></i>Email :
                                <span href="tel:XXX" class="text-body">{{ user.data.email }}</span>
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2 mb-0">
                            <p class="text-muted mb-0 me-2">
                                <i class="ri-phone-line fs-15 me-1 align-middle"></i>Téléphone :
                                <span href="tel:XXX" class="text-body">{{ user.data.phone_number }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- end card body -->
                <div class="card-footer bg-transparent border-top-dashed py-2">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <a :href="'tel:' + user.data.phone_number" class="btn btn-sm btn-soft-info waves-effect waves-light shadow-none">
                                <i class="ri-phone-line me-1 align-bottom"></i>Appeler
                            </a>
                        </div>
                        <div class="flex-shrink-0">
                            <a :href="'mailto:' + user.data.email" class="btn btn-sm btn-soft-dark waves-effect waves-light shadow-none">
                                <i class="ri-mail-send-line me-1 align-bottom"></i>Envoyer email
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
